import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Last28Days = () => {
  let access_token = localStorage.getItem("access_token");
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}analytics/device-data/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        const last28DaysData = groupDataByLast28Days(response.data);
        const chartData = calculateTotalViewsPerDay(last28DaysData);

        // Set the chartData state
        setChartData(chartData);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchData();
  }, []);

  const groupDataByLast28Days = (data) => {
    const currentTime = new Date();
    const last28Days = new Date();
    last28Days.setDate(currentTime.getDate() - 28); // For last 28 days

    return data.filter((item) => {
      const timestamp = new Date(item.timestamp);
      return timestamp >= last28Days && timestamp <= currentTime;
    });
  };

  const calculateTotalViewsPerDay = (data) => {
    const chartData = [];

    for (let i = 0; i < 28; i++) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - i);

      const Views = data.filter((item) => {
        const timestamp = new Date(item.timestamp);
        return (
          timestamp.getDate() === currentDate.getDate() &&
          timestamp.getMonth() === currentDate.getMonth() &&
          timestamp.getFullYear() === currentDate.getFullYear()
        );
      }).length;

      chartData.push({
        date: currentDate,
        Views: Views,
      });
    }

    return chartData.reverse();
  };

  return (
    <div className="-ml-9 md:p-5 p-1 py-2 h-[220px] sm:h-[300px] lg:h-[350px]">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={chartData}>
          <XAxis
            dataKey="date"
            interval={4}
            tickFormatter={(date) =>
              new Date(date).toLocaleDateString("en-US", {
                month: "short", // Display abbreviated month name
                day: "numeric",
              })
            }
          />
          <YAxis />
          <Tooltip />
          <Legend wrapperStyle={{ left: 30 }} />
          <Area
            type="monotone"
            dataKey="Views"
            stroke="#a0e030"
            fill="#a0e030"
            stackId="stack"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Last28Days;
