import React, { useState, useEffect } from "react";
import WorldMap from "react-svg-worldmap";
import axios from "axios";
import WorldUserTable from "./WorldUserTable";

const SvgWorldMap = () => {
  const [deviceData, setDeviceData] = useState([]);
  let access_token = localStorage.getItem("access_token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://127.0.0.1:8000/api/analytics/device-data/",
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        const ipAddresses = response.data.map(
          (item) => item.ip_address.ip_address
        );
        console.log("Ip Addresses", ipAddresses);

        // Create an array of promises for geolocation requests
        const geolocationPromises = ipAddresses.map(async (ipAddress) => {
          try {
            if (ipAddress === "127.0.0.1") {
              return { country: "in", value: 1 };
            } else {
              const geolocationResponse = await axios.get(
                `https://ipinfo.io/${ipAddress}/json?token=473f02d6f3a03c`
              );
              if (
                geolocationResponse.data &&
                geolocationResponse.data.country
              ) {
                return {
                  country: geolocationResponse.data.country.toLowerCase(),
                  value: 1,
                };
              } else {
                console.error(
                  "Geolocation data is missing or incomplete:",
                  geolocationResponse.data
                );
                return null;
              }
            }
          } catch (geolocationError) {
            console.error("Error fetching geolocation data:", geolocationError);
            return null;
          }
        });

        // Wait for all geolocation requests to complete
        const geolocationData = await Promise.all(geolocationPromises);

        // Filter out any null values (errors or missing data)
        const filteredGeolocationData = geolocationData.filter(
          (data) => data !== null
        );

        // Calculate the country counts
        const countryCounts = {};
        filteredGeolocationData.forEach((data) => {
          if (countryCounts[data.country]) {
            countryCounts[data.country]++;
          } else {
            countryCounts[data.country] = 1;
          }
        });

        // Convert the countryCounts object to an array for the WorldMap component
        const countryData = Object.keys(countryCounts).map((country) => ({
          country: country,
          value: countryCounts[country],
        }));

        setDeviceData(countryData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {/* small size  */}
      <div className="sm:hidden ">
        <WorldMap
          color="blue"
          valueSuffix="people"
          size="md"
          data={deviceData}
        />
        <span>
          <span>List Of Countries</span>
          <WorldUserTable deviceData={deviceData} />
        </span>
      </div>
      {/* medium size  */}
      <div className="hidden lg:hidden md:flex justify-between px-5 rounded-lg">
        <WorldMap
          color="blue"
          valueSuffix="people"
          size="lg"
          data={deviceData}
        />
        <WorldUserTable deviceData={deviceData} />
      </div>
      {/* large screen size  */}
      <div className="hidden rounded lg:flex justify-between gap-5 p-5 px-40">
        <WorldMap
          color="blue"
          valueSuffix="people"
          size="xl"
          data={deviceData}
        />
        <WorldUserTable deviceData={deviceData} />
      </div>
    </div>
  );
};

export default SvgWorldMap;
