import React, { useState, useEffect } from "react";
import { BsFillTelephoneFill, BsShareFill, BsWhatsapp } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { BiInfinite } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";
import QRCode from "qrcode";
import { Helmet } from "react-helmet";
import { TbArrowsExchange2 } from "react-icons/tb";
import { GetToken } from "../../services/localstorage";
import { ChromePicker } from "react-color";
import { TbWorldWww } from "react-icons/tb";
// import { ToastContainer,toast } from 'react-toastify'

const Template3 = ({ data }) => {
  const [primary, setPrimary] = useState("#202835");
  const [secondary, setSecondary] = useState("#F68B21");
  const [isopen, setIsopen] = useState(false);
  const [isopenPri, setIsopenPri] = useState(false);
  const { access_token } = GetToken();
  const [id, setId] = useState(null);
  const navigate = useNavigate();

  const handleColorPrimary = (selectedColor) => {
    setPrimary(selectedColor.hex);
    console.log(primary);
  };

  const handleColorSecondary = (selectedColor) => {
    setSecondary(selectedColor.hex);
    console.log(secondary);
  };

  const params = useParams();

  const [qrcode, setQr] = useState("");

  const url =
    process.env.REACT_APP_PUBLIC_URL + "card-customise/" + `?id=${params.link}`;
  //  console.log("--------",data[0].card_link)
  const config = {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  };

  const id1 = async () => {
    const newId = data[0].id;
    setId(newId);
    console.log("-----------------------", id);
  };
  useEffect(() => {
    id1();
  }, [data]);

  const handleClick = (e) => {
    e.preventDefault();
    const actualdata = {
      card: id,
      card_color_primary: primary,
      card_color_sec: secondary,
      template_code: params.id,
    };
    axios.post(url, actualdata, config).then((response) => {
      console.log("posted data", actualdata);
      toast.success("Yay! Card created!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate(`/shared-card/${params.link}`);
      }, 3000);
    });
  };

  useEffect(() => {
    QRCode.toDataURL(
      process.env.REACT_APP_PUBLIC_URL + "view-card/" + params.id
    ).then((data) => {
      setQr(data);
    });
  }, []);

  return (
    <div class="flex flex-col max-w-sm  h-auto md:flex-row justify-center mx-auto gap-5">
      <div class="text-center">
        <div class="flex-col max-w-sm md:w-[300px] rounded-lg mt-5 mx-auto border shadow-lg mb-3  md:mt-[200px]">
          <h1 class="text-center text-xl font-medium text-gray-700 p-2">
            Customize Theme For Your Business Card
          </h1>
          <div className="flex justify-center items-center gap-2">
            <button
              class="border p-2  shadow-lg rounded-md font-medium"
              style={{ backgroundColor: secondary, color: "white" }}
              onClick={() => setIsopen(!isopen)}
            >
              Primary Color
            </button>
            <br />

            <button
              class="border p-2 mt-3 mb-2 rounded-md shadow-lg font-medium"
              style={{ backgroundColor: primary, color: "white" }}
              onClick={() => setIsopenPri(!isopenPri)}
            >
              Secondary Color
            </button>
          </div>
          {isopen ? (
            <span
              onMouseLeave={() => setIsopen(!isopen)}
              className="absolute z-50 -translate-x-44"
            >
              <ChromePicker color={secondary} onChange={handleColorSecondary} />
            </span>
          ) : (
            ""
          )}
          {isopenPri ? (
            <span
              onMouseLeave={() => setIsopenPri(!isopenPri)}
              className="absolute z-50 -translate-x-44"
            >
              <ChromePicker color={primary} onChange={handleColorPrimary} />
            </span>
          ) : (
            ""
          )}

          <form onSubmit={(e) => handleClick(e)}>
            <button class="text-white bg-blue-500 h-10 mt-3 px-5 hover:bg-[#13CAFD]  shadow-lg rounded text-lg font-medium mx-auto mb-3">
              Save
            </button>
          </form>
        </div>
      </div>

      <div>
        {data.map((item, index) => (
          <div key={index}>
            <Helmet>
              <title class="capi">
                {item.first_name + " " + item.last_name}-Profile
              </title>
              <meta property="og:image" content={item.profile_img} />
              <meta
                property="og:title"
                content="create a bussiness digital card"
              />
              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content={
                  process.env.REACT_APP_PUBLIC_URL + "view-card/" + params.id
                }
              />
            </Helmet>
            <h1 className="text-center text-xl sm:hidden font-semibold ">
              <u>Live Card Preview</u>
            </h1>
            <div class="container flex mb-5 justify-center h-auto p-2">
              <a
                href
                class="block max-w-lg p-6  w-full  border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                style={{ backgroundColor: primary }}
              >
                <div
                  class=" w-[200px] h-[220px] flex flex-col mx-auto mt-[-24px]"
                  style={{ backgroundColor: secondary }}
                >
                  <img
                    src="https://img.freepik.com/free-photo/indoor-shot-beautiful-happy-african-american-woman-smiling-cheerfully-keeping-her-arms-folded-relaxing-indoors-after-morning-lectures-university_273609-1270.jpg"
                    class="w-40 h-40 rounded-full object-cover mx-auto mt-2"
                    alt="img"
                  />

                  <p
                    class="text-xl text-white font-medium capitalize text-center
"
                  >
                    {item.first_name + " " + item.last_name}
                  </p>
                  <div class="bg-white w-[150px] h-0.5 mt-1  mx-auto"></div>
                </div>

                <div>
                  <p class="text-md font-normal mt-1 text-white uppercase text-center">
                    {item.profession}
                  </p>
                </div>

                <div class="flex flex-col mt-2 ">
                  <div class="mt-3">
                    <BsFillTelephoneFill
                      color={secondary}
                      size={20}
                      class="mx-auto"
                    />
                    <p class="text-sm text-center text-white mt-1">
                      {item.mobile_number}
                    </p>

                    <div
                      class=" w-[150px] h-0.5 mt-1  mx-auto"
                      style={{ backgroundColor: secondary }}
                    ></div>
                  </div>

                  <div class="mt-3">
                    <GrMail color={secondary} size={20} class="mx-auto" />

                    <p class="text-sm text-center text-white mt-1">
                      {item.email}m
                    </p>

                    <div
                      class="  w-[150px] h-0.5 mt-1  mx-auto"
                      style={{ backgroundColor: secondary }}
                    ></div>
                  </div>

                  <div class="mt-3">
                    <TbWorldWww color={secondary} size={20} class="mx-auto" />
                    <a
                      href={item.website_link}
                      target="_blank"
                      rel=" noreferrer"
                    >
                      <p class="text-sm text-center text-white mt-1">
                        {item.website_link.replace(/^(https?:\/\/)/, "")}
                      </p>
                    </a>

                    <div
                      class="  w-[150px] h-0.5 mt-1  mx-auto"
                      style={{ backgroundColor: secondary }}
                    ></div>
                  </div>
                </div>

                <div class="mt-5 flex  items-center flex-col">
                  <img
                    src={qrcode}
                    class="shadow-lg w-[220px] rounded-lg h-[220px]"
                    alt="img"
                  />

                  <div class="flex flex-col text-center">
                    <a href={qrcode} class="mx-6 mt-3 ">
                      <span class="text-md font-bold text-white hover:font-medium ">
                        Download
                      </span>
                    </a>
                    <div class="bg-white w-[150px] h-0.5 mt-1  mx-auto"></div>
                  </div>
                </div>

                <div class="flex flex-col  mt-5 mx-auto">
                  <div class="flex justify-center  gap-2 ">
                    <ul>
                      <li class="flex gap-2">
                        <TbArrowsExchange2 size={25} color="white" />
                        <span class="text-white">Exchange Contacts</span>
                      </li>
                      <li class="flex gap-4 mt-2">
                        <BsShareFill size={18} color="white" />
                        <span class="text-white">Share Details</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class=" flex h-10 justify-center rounded-md mt-4 w-full "
                  style={{ backgroundColor: secondary }}
                >
                  <button class=" text-xl text-white font-medium">
                    Add To Contacts
                  </button>
                </div>
                <div class="mt-5">
                  <p class="text-center text-sm text-gray-400">
                    {item.address}
                  </p>
                </div>
              </a>
            </div>
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Template3;
