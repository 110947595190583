import React, { useState, useEffect } from "react";
import ss1 from "../tempImages/temp1.png";
import ss2 from "../tempImages/s2.png";
import ss3 from "../tempImages/s4.png";
import ss4 from "../tempImages/s5.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";

const ChooseTemplate = () => {
  const [cardData, setCardData] = useState([]);
  const params = useParams();
  console.log("id=", params.id);
  const navigate = useNavigate();
  const [isloading, setloading] = useState(true);

  const url = process.env.REACT_APP_PUBLIC_URL + "personal-detail/";
  let access_token = localStorage.getItem("access_token");
  const config = {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  };

  useEffect(() => {
    async function getData() {
      try {
        let mydata = await axios.get(url, config);
        setCardData(mydata.data[0]);

        setloading(false);
      } catch (error) {
        setloading(false);
      }
    }
    getData();
  }, []);

  const temp = [
    {
      src: ss1,
    },
    {
      src: ss2,
    },
    {
      src: ss3,
    },
    {
      src: ss4,
    },
  ];

  const handleClick = (i, l) => {
    navigate("/design/" + i + "/" + l);
  };

  return (
    <>
      <h1 class="text-xl sm:text-3xl py-3 px-2 font-semibold text-center text-gray-600">
        Choose Template Design For Your Business Card
      </h1>

      <div class="grid grid-cols-2 sm:gap-2 md:grid-cols-3 md:flex container mx-auto gap-4 p-2 justify-center">
        {temp.map((temp, index) => {
          return (
            <div key={index} className="">
              <img
                src={temp.src}
                alt="template design img"
                class="hover:scale-105"
                onClick={() => handleClick(index, params.id)}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ChooseTemplate;
