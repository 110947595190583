import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { GetToken } from "../services/localstorage";
import { ToastContainer,toast } from "react-toastify";
import axios from "axios";
import AvatarEditor from "react-avatar-editor";
import { Link, useNavigate } from "react-router-dom";

import Modal from "react-modal";
import './Card/Modal.css'
import { RxCrossCircled, RxRotateCounterClockwise } from "react-icons/rx";
import { TbRotateDot, TbRotate, TbRotateClockwise } from "react-icons/tb";
import {BsFillCheckCircleFill} from 'react-icons/bs'

Modal.setAppElement("#root");

const EditProfile = () => {
  const base_url = process.env.REACT_APP_PUBLIC_URL;
  // State to hold the user data
  const [user, setUser] = useState(null);
  const [updatedUser, setUpdatedUser] = useState({});
  const navigate = useNavigate();
  

  useEffect(() => {
    // Function to fetch user data using the JWT token
    const fetchUserData = async () => {
      const token = GetToken();
      if (token["access_token"] !== null) {
        try {
          // Make a request to your Django API to get the user data
          const response = await axios.get(base_url + "get-user-profile/", {
            headers: {
              Authorization: `Bearer ${token["access_token"]}`,
            },
          });
          console.log(response.data);
          setUser(response.data);
          updatedUser.first_name = response.data["first_name"];
          updatedUser.last_name = response.data["last_name"];
          updatedUser.mobile_number = response.data["mobile_number"];
        } catch (error) {
          // Handle error
          console.error("Error fetching user data: ", error);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleSaveClick = async () => {
    try {
      const token = GetToken();
      let data;
  
      // Check if an image needs to be updated
      if(updatedUser.profile_img) {
        const formData = new FormData();
        formData.append('profile_img', updatedUser.profile_img);
        formData.append('first_name', updatedUser.first_name);
        formData.append('last_name', updatedUser.last_name);
        formData.append('mobile_number', updatedUser.mobile_number);
        data = formData;
      } else {
        data = updatedUser;
      }
  
      const response = await axios.put(base_url + "get-user-profile/", data, {
        headers: {
          Authorization: `Bearer ${token["access_token"]}`,
          ...(updatedUser.profile_img ? { 'Content-Type': 'multipart/form-data' } : {}),
        },
      });
  
      if (response.status === 200) {
        // User profile updated successfully
        setUser(updatedUser);
        console.log("updated",updatedUser);
        toast.success("Profile Updated Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigate(`/`);
        }, 2000);
      } else {
        // Handle other error cases
        console.error("Error updating user profile");
      }
    } catch (error) {
      // Handle error
      console.error("Error updating user profile: ", error);
    }
  };
  



  const [invalidImage, setinvalidImage] = useState(null);
  const [invalidBgImage, setinvalidBgImage] = useState(null);
  let reader = new FileReader();

  const [scale, setScale] = useState(1);
  const [editor, setEditor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userInfo, setuserInfo] = useState({
    file: [],
    filepreview: null,
  });
  const [rotate, setRotate] = useState(0); // Initialize rotation angle

  const handleProfileChange = (event) => {
    const imageFile = event.target.files[0];
    const imageFilename = event.target.files.name;

    if (!imageFile) {
      toast.warning("Please select an image first.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setinvalidImage("Please select a valid image (JPG, JPEG, PNG).");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      setuserInfo({
        ...userInfo,
        filepreview: e.target.result,
      });

      setinvalidImage(null);
    };

    reader.readAsDataURL(imageFile);
  };

  const handleRotate = () => {
    // Increment the rotation angle by 90 degrees (you can adjust this value)
    setRotate((prevRotate) => prevRotate + 90);
  };

  const handleCrop = () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      canvas.toBlob(
        (blob) => {
          const file = new File([blob], "profile.jpg", {
            type: "image/jpeg",
            lastModified: Date.now(),
          });
          setuserInfo({
            ...userInfo,
            file: file,
          });
          
          // Update the 'updatedUser' state with the new image
          setUpdatedUser({
            ...updatedUser,
            profile_img: file,
          });
  
        },
        "image/jpeg",
        1
      );
    }
    setIsModalOpen(false); // Close the modal after cropping
  };
  




  return (
    <div>
      <Navbar />
      <div className="m-10 px-2 py-2 mx-auto max-w-3xl">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Edit Profile Details</h2>
          {user && (
            <form>
              <div class="grid md:grid-cols-2 mt-10 md:gap-6">
            <div class="relative z-0 w-full mb-6 group">
              <input
                type="text"
                id="first_name"
                name="first_name"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-900 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                defaultValue={user.first_name}
                    onChange={(e) =>
                      setUpdatedUser({
                        ...updatedUser,
                        first_name: e.target.value,
                      })
                    }
              />

              <label
                htmlFor="floating_first_name"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                First name
              </label>
            </div>

            <div class="relative z-0 w-full mb-6 group">
              <input
                type="text"
                id="last_name"
                    name="last_name"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-900 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                defaultValue={user.last_name}
                onChange={(e) =>
                  setUpdatedUser({
                    ...updatedUser,
                    last_name: e.target.value,
                  })
                }
                
              />

              <label
                htmlFor="floating_last_name"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Last name
              </label>
            </div>
          </div>  
          


          <div class="grid md:grid-cols-2  md:gap-6">
            <div class="relative z-0 w-full mb-6 group">
              <input
                type="text"
                id="mobile_number"
                name="mobile_number"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                defaultValue={user.mobile_number}
                onChange={(e) =>
                  setUpdatedUser({
                    ...updatedUser,
                    mobile_number: e.target.value,
                  })
                }
              />

              <label
                htmlFor="floating_first_name"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Mobile Number
              </label>
            </div>
            <div>
              <div className="relative z-0 w-full group">
                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75  origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 md:text-lg">
                  Upload New Profile Image
                </label>

                <input
                  name="profile"
                  className="block w-full mt-5 px-2 pt-1 pb-1 text-xs text-gray-900 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                  id="small_size"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleProfileChange}
                  onClick={() => setIsModalOpen(true)}
                  // defaultValue={user.profile_img}
                />


                {user.profile_img!=null ?<>
                  <span className="flex gap-2 px-2 py-1">
                  <span className="text-[11px]">Current Picture: </span>
                  <img
                    src={user.profile_img}
                    className="h-10 w-10 rounded"
                    alt="Updating..."
                  />
                </span>
                
                </>:""}
            
              </div>

              {invalidImage && (
                <div className="text-red-500">{invalidImage}</div>
              )}

              <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Image Editor"
                className="fixed inset-0 flex items-center justify-center"
              >
                <div className="bg-white p-4 rounded-lg shadow-md max-w-md w-full">
                  <AvatarEditor
                    ref={(editor) => setEditor(editor)}
                    image={userInfo.filepreview}
                    width={250}
                    height={250}
                    border={50}
                    borderRadius={1000}
                    color={[1, 1, 1, 0.6]}
                    scale={scale}
                    rotate={rotate}
                    className="mx-auto"
                  />

                  <div className="mt-4">
                    <input
                      type="range"
                      min="1"
                      max="2"
                      step="0.01"
                      value={scale}
                      onChange={(e) => setScale(parseFloat(e.target.value))}
                      className="w-full"
                    />
                  </div>

                  <div className="mt-4 flex gap-3 justify-center">
                    <BsFillCheckCircleFill
                      color="green"
                      onClick={handleCrop}
                      size={27}
                    />
                    <TbRotateClockwise
                      color="white"
                      size={27}
                      onClick={handleRotate}
                      className="bg-gray-500 p-0.5 rounded-full"
                    />

                    <RxCrossCircled
                      color="white"
                      size={27}
                      className="bg-red-600 p-0.5 rounded-full"
                      onClick={() => setIsModalOpen(false)}
                    />
                  </div>
                </div>
              </Modal>
            </div>
          </div>

{/* 
          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-6 group">
              <input
                type="email"
                name="email"
                id="floating_email"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-900 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                defaultValue={user.email}
                readonly="true"
              />

              <label
                htmlFor="floating_email"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Email address
              </label>
            </div>
          
          </div> */}



      

              {/* <div className="mb-2">
                <label htmlFor="mobile_number" className="font-medium">
                  Mobile Number:
                </label>
                <input
                  type="text"
                  id="mobile_number"
                  name="mobile_number"
                  className="border rounded-lg px-3 py-2 w-full mt-1 mb-2"
                  defaultValue={user.mobile_number}
                  onChange={(e) =>
                    setUpdatedUser({
                      ...updatedUser,
                      mobile_number: e.target.value,
                    })
                  }
                />
              </div> */}

              <div className="mb-4">
                <button
                  type="button"
                  onClick={handleSaveClick}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                >
                  Save
                </button>
              </div>
            </form>
          )}
          <ToastContainer />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EditProfile;
