/* eslint-disable eqeqeq */
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  AiOutlineIdcard,
  AiOutlineUser,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { LiaUsersSolid } from "react-icons/lia";
import { TbUserDollar } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import UserChartThisWeek from "./charts/UserChartThisWeek";
import ActiveUser from "./charts/ActiveUsers";
import UserTable from "./charts/NewUserTable";

const Cards = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [FreeUser, setFreeUser] = useState();
  const [totalCardsCreated, setTotalCardsCreated] = useState();
  const [CardsView, setCardsView] = useState();
  let access_token = localStorage.getItem("access_token");

  // Getting All user data from backend
  const userData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      // user data
      setUser(response.data);

      const TotalFalseValues = response.data.filter(
        (item) => item.is_superuser == false
      ).length;
      setFreeUser(TotalFalseValues);

      // total cards data
      const cardLengthsArray = response.data.map((item) => item.cards.length);
      const sum = cardLengthsArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      setTotalCardsCreated(sum);

      // Calculate total cards view
      const cardViewCountsArray = response.data.flatMap((item) =>
        item.cards.map((card) => card.view_count)
      );
      const totalView = cardViewCountsArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      setCardsView(totalView);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    userData();
  }, []);

  // total cards VIEW data
  const [totalHitCount, setTotalHitCount] = useState(0);
  const [uniqueVisitors, setUniqueVisitors] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}analytics/device-data/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        setTotalHitCount(response.data.length);
        setLoading(false);

        const uniqueVisitorSet = new Set();
        response.data.forEach((item) => {
          const visitorKey = `${item.ip_address.ip_address}-${item.browser}`;
          uniqueVisitorSet.add(visitorKey);
        });

        setUniqueVisitors(uniqueVisitorSet.size);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <div className="bg-white">
          <div role="status" className="z-10 p-10 mx-auto max-w-[100px]">
            <svg
              aria-hidden="true"
              class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>{" "}
        </div>
      ) : (
        <div className="">
          <div className="mx-auto">
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 my-2 gap-2 sm:gap-5">
              {/* first  */}
              <div className="col-span-1  cursor-pointer">
                <div className="px-4 lg:px-5 bg-white hover:bg-gray-50 text-gray-700 h-20 lg:h-28 shadow-sm flex gap-3 items-center rounded  ease-in-out">
                  <div>
                    <LiaUsersSolid className="h-6 w-6 md:h-9 md:w-9 xl:h-12 xl:w-12 text-[#b8ce3a]" />
                  </div>
                  <div className="">
                    {user && user.length && (
                      <h3 className="lg:text-3xl font-semibold">
                        {user.length}
                      </h3>
                    )}

                    <p className="text-[11px] sm:text-sm ">Total Users</p>
                  </div>
                </div>
              </div>

              <div className="col-span-1 ">
                <div className="px-4 lg:px-5 bg-white hover:bg-gray-50 text-gray-700 h-20 lg:h-28 shadow-sm flex gap-3 items-center rounded  ease-in-out">
                  <div>
                    <AiOutlineIdcard className="h-6 w-6 md:h-9 md:w-9 xl:h-12 xl:w-12 text-blue-600" />
                  </div>
                  <div>
                    <h3 className=" lg:text-3xl font-semibold">
                      {totalCardsCreated && totalCardsCreated}
                    </h3>
                    <p className="text-[11px] sm:text-sm">
                      Total Cards Created
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-span-1 ">
                <div className="px-4 lg:px-5 bg-white hover:bg-gray-50 text-gray-700 h-20 lg:h-28 shadow-sm flex gap-3 items-center rounded  ease-in-out">
                  <div>
                    <TbUserDollar className="h-6 w-6 md:h-9 md:w-9 xl:h-12 xl:w-12 text-green-600" />
                  </div>
                  <div>
                    <h3 className=" lg:text-3xl font-semibold">3</h3>
                    <p className="text-[11px] sm:text-sm">Premium Users</p>
                  </div>
                </div>
              </div>

              <div className="col-span-1 ">
                <div className="px-4 lg:px-5 bg-white hover:bg-gray-50 text-gray-700 h-20 lg:h-28 shadow-sm flex gap-3 items-center rounded  ease-in-out">
                  <div>
                    <AiOutlineUser className="h-6 w-6 md:h-9 md:w-9 xl:h-12 xl:w-12 text-[#d83cce]" />
                  </div>
                  <div>
                    <h3 className=" lg:text-3xl font-semibold">{FreeUser}</h3>
                    <p className="text-[11px] sm:text-sm">Other Users</p>
                  </div>
                </div>
              </div>

              <div className="col-span-1 ">
                <div className="px-4 lg:px-5 bg-white hover:bg-gray-50 text-gray-700 h-20 lg:h-28 shadow-sm flex gap-3 items-center rounded  ease-in-out">
                  <div>
                    <AiOutlineUserAdd className="h-6 w-6 md:h-9 md:w-9 xl:h-12 xl:w-12 text-[#e59f44]" />
                  </div>
                  <div>
                    <h3 className=" lg:text-3xl font-semibold">
                      {uniqueVisitors && uniqueVisitors}
                    </h3>
                    <p className="text-[11px] sm:text-sm">Total Visitors </p>
                  </div>
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="px-4 lg:px-5 bg-white hover:bg-gray-50 text-gray-700 h-20 lg:h-28 shadow-sm flex gap-3 items-center rounded  ease-in-out">
                  <div>
                    <AiOutlineUserAdd className="h-6 w-6 md:h-9 md:w-9 xl:h-12 xl:w-12 text-[#38b8d2]" />
                  </div>
                  <div>
                    <h3 className=" lg:text-3xl font-semibold">
                      {totalHitCount && totalHitCount}
                    </h3>
                    <p className="text-[11px] sm:text-sm">
                      Total View on Cards{" "}
                    </p>
                  </div>
                </div>
              </div>
              {/* right charts  */}
              <div className="col-span-2 row-span-4 bg-white text-center">
                <span className="font-semibold">Active/Inactive users</span>
                <div className="px-4 md:pl-32 lg:pl-32">
                  <ActiveUser />
                </div>
              </div>
              {/* charts  */}
              <div className="col-span-2 row-span-3 bg-white px-4 lg:px-5 text-center">
                <span className="font-semibold">User Gained This week</span>
                <div className="">
                  <UserChartThisWeek />
                </div>
              </div>
              <div className="col-span-2 sm:col-span-4  bg-white  text-center p-2">
                <div className="font-semibold">New Users from this week </div>
                <div className="p-2">
                  <UserTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cards;
