import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";
import QRCode from "qrcode";

import Template1 from "../Templates/Template1";
import Template2 from "../Templates/Template2";
import Template3 from "../Templates/Template3";
import Template4 from "../Templates/Template4";
import Loading from "../Loading";

const Design = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [wopen, wsetOpen] = React.useState(false);
  const whandleOpen = () => wsetOpen(true);
  const whandleClose = () => wsetOpen(false);

  const [isloading, setloading] = useState(true);
  const [name, setName] = useState("");
  const params = useParams();
  console.log("params", params);

  const [primary, setPrimary] = useState("");
  const [secondary, setSecondary] = useState("");

  const [cardData, setCardData] = useState([]);
  const [singleData, setSingledata] = useState([]);

  const url = process.env.REACT_APP_PUBLIC_URL + "personal-detail/";

  let access_token = localStorage.getItem("access_token");

  const config = {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  };

  const singleurl =
    process.env.REACT_APP_PUBLIC_URL + "shared-card/" + params.link;

  useEffect(() => {
    async function getData() {
      try {
        let mydata = await axios.get(singleurl, config);
        setSingledata(mydata.data);
        console.log("===================", singleData);
        setloading(false);
      } catch (error) {
        setloading(false);
      }
    }
    getData();
  }, []);

  const [qrcode, setQr] = useState("");

  useEffect(() => {
    QRCode.toDataURL(
      process.env.REACT_APP_PUBLIC_URL + "view-card/" + params.id
    ).then((data) => {
      setQr(data);
    });
  }, []);

  const even = (e) => {
    if (e % 2 === 0) {
      console.log("sure");
    }
  };

  const handleClick = (url, filename) => {
    axios
      .get(url.replace("http", "https"), {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <>
      {isloading ? (
        <Loading />
      ) : (
        <>
          {params.id === "0" && <Template1 data={singleData} />}
          {params.id === "1" && <Template2 data={singleData} />}
          {params.id === "2" && <Template3 data={singleData} />}
          {params.id === "3" && <Template4 data={singleData} />}
        </>
      )}
    </>
  );
};

export default Design;
