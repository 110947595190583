// src/components/Modal.js

import React from "react";

const Modal = ({ isOpen, closeModal, confirmDelete }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div
        className="fixed inset-0 bg-black opacity-40"
        onClick={closeModal}
      ></div>

      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 space-y-2 rounded shadow-lg">
        <h2 className="text-lg font-semibold mb-4">Confirm Delete!</h2>
        <p>Are you sure you want to delete?</p>
        <div className="space-x-2">
          <button
            className="mt-4 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
            onClick={confirmDelete}
          >
            Delete
          </button>
          <button
            className="mt-4 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default Modal;
