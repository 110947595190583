import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts";

const QuarterUserData = () => {
  let access_token = localStorage.getItem("access_token");
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        setChartData(response.data);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchData();
  }, []);

  const currentDate = new Date();
  const last90Days = Array.from({ length: 90 }, (_, i) => {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() - i);
    return date;
  });

  const UsersAndCardCountByDay = last90Days.map((date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const usersJoined = chartData.filter(
      (item) =>
        item.date_joined.split("T")[0] === date.toISOString().split("T")[0]
    );

    const cardCounts = usersJoined.map((user) => user.cards.length);
    const totalCardCount = cardCounts.reduce(
      (acc, cardCount) => acc + cardCount,
      0
    );

    return {
      day: `${day}-${month}`,
      Users: usersJoined.length,
      cardCount: totalCardCount,
    };
  });

  return (
    <>
      <div className="hidden sm:block -ml-10 md:p-5 py-2 h-[250px] sm:h-[350px]">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={UsersAndCardCountByDay}>
            <XAxis dataKey="day" reversed interval={9} />
            <YAxis />
            <Tooltip />
            <Legend wrapperStyle={{ left: 30 }} />
            <Area
              type="monotone"
              dataKey="Users"
              stroke="#a0e030"
              fill="#a0e030"
              stackId="stack"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="sm:hidden -ml-10 md:p-5 py-2 h-[250px] sm:h-[350px]">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={UsersAndCardCountByDay}>
            <XAxis dataKey="day" reversed interval={15} />
            <YAxis />
            <Tooltip />
            <Legend wrapperStyle={{ left: 30 }} />
            <Area
              type="monotone"
              dataKey="Users"
              stroke="#a0e030"
              fill="#a0e030"
              stackId="stack"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default QuarterUserData;
