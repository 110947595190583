/* eslint-disable jsx-a11y/scope */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "./DeleteModal";
import { GetToken } from "../../../../services/localstorage";
import ReactPaginate from "react-paginate";
import "./UserTable.css";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

const UserTable = () => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [user, setUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalAdmin, setTotalAdmin] = useState();
  const [otherUser, setOtherUser] = useState();
  let access_token = localStorage.getItem("access_token");
  const loggedInUserId = localStorage.getItem("user_id");
  const [modalUserId, setModalUserId] = useState(null);

  const openDeleteModal = (userId) => {
    setModalUserId(userId);
  };

  const closeDeleteModal = () => {
    setModalUserId(null);
  };

  const confirmDeleteUser = async (userId) => {
    await deleteUser(userId);
    closeDeleteModal();
  };

  // all users data
  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setUser(response.data);
      setLoading(false);
      // Total admin count
      const totalTrueValues = response.data.filter(
        (item) => item.is_superuser
      ).length;
      const TotalFalseValues = response.data.filter(
        (item) => item.is_superuser === false
      ).length;
      setOtherUser(TotalFalseValues);
      setTotalAdmin(totalTrueValues);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  // delete user
  const deleteUser = async (itemId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/?id=${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      fetchUserData();
      toast.error("Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error:", error);
      alert("Error Deleting user details!");
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  // current user data
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;
  useEffect(() => {
    const UserData = async () => {
      const token = GetToken();
      try {
        const response = await axios.get(baseUrl + "get-user-profile/", {
          headers: {
            Authorization: `Bearer ${token["access_token"]}`,
          },
        });
        setCurrentUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    UserData();
  }, []);

  // change value through toggle button
  const ChangeValue = async (userId, isActive) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/?id=${userId}`,
        { is_active: isActive },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setUser((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, is_active: isActive } : user
        )
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Update this line to sort the user array before pagination
  const sortUsersByName = (users) => {
    return users.slice().sort((a, b) => {
      const nameA =
        a.first_name.toLowerCase() + " " + a.last_name.toLowerCase();
      const nameB =
        b.first_name.toLowerCase() + " " + b.last_name.toLowerCase();
      return nameA.localeCompare(nameB);
    });
  };
  // total pages available
  const usersPerPage = 10;
  const totalPages = Math.ceil(user.length / usersPerPage);

  // Pagination handle
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  // User display in pagination
  const startIndex = (currentPage - 1) * usersPerPage;
  const endIndex = startIndex + usersPerPage;
  const usersToDisplay = sortUsersByName(user).slice(startIndex, endIndex);

  return (
    <div className="">
      {loading ? (
        <p className="text-center">Please Be Patient...</p>
      ) : (
        <div className="w-full">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 pb-4">
            <div className="bg-blue-500 p-4 text-white text-lg font-semibold rounded">
              Total Users: {user.length}
            </div>
            <div className="bg-blue-500 p-4 text-white text-lg font-semibold rounded">
              Total Admin: {TotalAdmin}
            </div>
            <div className="bg-blue-500 p-4 text-white text-lg font-semibold rounded">
              Premium Users:
            </div>
            <div className="bg-blue-500 p-4 text-white text-lg font-semibold rounded">
              Other Users: {otherUser}
            </div>
          </div>
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full overflow-hidden">
              <table className="rounded w-full text-sm text-center text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                  <tr>
                    <th scope="col" className="px-4 py-3">
                      Sr.no
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Cards Created
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {usersToDisplay.map((item, index) => (
                    <tr
                      className={`${
                        item.is_superuser ? "font-semibold" : ""
                      } border-b bg-white hover:bg-gray-100`}
                      key={index}
                    >
                      <td scope="row" className="px-4 py-4 whitespace-nowrap">
                        {startIndex + index + 1}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap">
                        {item.first_name + " " + item.last_name}&nbsp;
                        <span className="text-[9px]">
                          {item.is_superuser && "(admin)"}
                        </span>
                      </td>
                      <td className="px-4 py-4">{item.email}</td>
                      <td className="px-4 py-4">
                        {item.cards.length ? item.cards.length : "0"}
                      </td>
                      <td className="px-4 py-4">
                        {item.is_active ? "Active" : "Inactive"}
                      </td>

                      <td className="px-4 py-4 space-x-3 md:text-lg whitespace-nowrap">
                        {currentUser && currentUser.id !== item.id ? (
                          <>
                            <Link to={`/dashboard/${item.id}`}>
                              <button className="text-blue-500">
                                <AiOutlineEye />
                              </button>
                            </Link>
                            <Link to={`/dashboard/edit-user/${item.id}`}>
                              <button className="text-yellow-500">
                                <BsPencil />
                              </button>
                            </Link>
                            <button
                              className="text-red-500"
                              onClick={() => openDeleteModal(item.id)}
                            >
                              <RiDeleteBin5Line />
                            </button>
                            <label className="relative inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={item.is_active}
                                onChange={() =>
                                  ChangeValue(item.id, !item.is_active)
                                }
                              />
                              <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                          </>
                        ) : (
                          <div></div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            {/* Pagination Buttons */}
            <ReactPaginate
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination flex"}
              activeClassName={"active"}
              previousLabel={<MdNavigateBefore />}
              nextLabel={<MdNavigateNext />}
              breakLabel={"..."}
              previousClassName={"previous"}
              nextClassName={"next"}
              pageLinkClassName={"page-link"}
              activeLinkClassName={"active"}
            />
          </div>
        </div>
      )}
      <Modal
        isOpen={modalUserId !== null}
        closeModal={closeDeleteModal}
        confirmDelete={() => confirmDeleteUser(modalUserId)}
      />
    </div>
  );
};

export default UserTable;
