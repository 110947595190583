import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { BsBell, BsPerson, BsPower } from "react-icons/bs";
import { GetToken, RemoveToken } from "../../../services/localstorage";
import { useDispatch } from "react-redux";
import { unsetUserAuth } from "../../../features/authSlice";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ProfileDropdown = () => {
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(unsetUserAuth({ access_token: null }));
    RemoveToken();
    toast.warning("Logged Out Successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setTimeout(() => {
      Navigate("/");
    }, 3000);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = GetToken();
        const response = await fetch(baseUrl + "get-user-profile/", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token["access_token"]}`,
          },
        });
        const userData = await response.json();
        console.log("==================", userData);
        setUser(userData);
      } catch (error) {
        // Handle error
      }
    };

    fetchUserData();
  }, []);
  return (
    <div className="p-1 bg-white shadow rounded border hover:bg-gray-50">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button>
            <div className="flex items-center gap-1 font-semibold">
              <BsPerson />
              <span>{user?.first_name + " " + user?.last_name} </span>
            </div>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-bottom-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/dashboard/profile"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <span className="flex gap-1 items-center ">
                      <BsBell />
                      <strong>Notification</strong>
                    </span>
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <span className="flex gap-1 items-center ">
                      <BsPerson />
                      <strong>
                        {user?.first_name + " " + user?.last_name}
                      </strong>
                    </span>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <span className="flex gap-1 items-center" onClick={logout}>
                      <BsPower />
                      <strong>SignOut</strong>
                    </span>
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ProfileDropdown;
