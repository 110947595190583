import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { GoChevronLeft } from "react-icons/go";
import Navbar from "../../navbar/navbar";
import Sidebar from "../sidebar";
import { ToastContainer, toast } from "react-toastify";
import Modal from "./EditModal";
import Footer from "../../../Footer";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [editedUser, setEditedUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    profile_img_url: "",
    google_id: "",
    g_log: "",
    mobile_number: "",
    is_active: false,
    is_staff: false,
    is_superuser: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false); // Track modal state

  // Fetch user data from backend based on the ID
  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setUserDetails(response.data);
      setEditedUser(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  // Handle toggle button changes for boolean fields
  const handleToggleChange = (event) => {
    const { name, checked } = event.target;
    setEditedUser((prevUser) => ({ ...prevUser, [name]: checked }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsModalOpen(true); // Open the modal for confirmation
  };

  // Function to confirm the update
  const confirmEdit = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/?id=${id}`,
        editedUser,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      toast.success("Data Updated Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsModalOpen(false); // Close the modal
    }
  };
  return (
    <div className="md:flex">
      <div className="hidden md:inline-flex w-2/12 fixed top-0 h-screen">
        <div className="md:sticky top-0">
          <Sidebar />
        </div>
      </div>
      <div className="md:flex-1 bg-slate-100">
        <div className="md:ml-[215px]">
          <Navbar />
          <div>
            <div
              className="flex items-center text-blue-500 p-2 cursor-pointer font-semibold"
              onClick={() => navigate("/dashboard/user-management")}
            >
              <GoChevronLeft className="h-6 w-6" />
              <span className="text-lg">Go Back</span>
            </div>
            <div className="px-2 py-2 mx-auto max-w-3xl">
              {userDetails ? (
                <div className="bg-white shadow-lg rounded-lg p-6">
                  <h2 className="text-2xl font-semibold mb-4">
                    Edit User Details
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-2">
                      <div className="flex">
                        <label
                          htmlFor="first_name"
                          className="font-medium w-1/2"
                        >
                          First Name:
                        </label>
                        <label
                          htmlFor="last_name"
                          className="font-medium hidden md:inline-flex"
                        >
                          Last Name:
                        </label>
                      </div>
                      <div className="md:flex gap-2">
                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          value={editedUser.first_name}
                          onChange={handleInputChange}
                          className="border rounded-lg px-3 py-2 w-full mt-1 mb-2"
                        />
                        <label
                          htmlFor="last_name"
                          className="font-medium md:hidden"
                        >
                          Last Name:
                        </label>
                        <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          value={editedUser.last_name}
                          onChange={handleInputChange}
                          className="border rounded-lg px-3 py-2 w-full mt-1 mb-2"
                        />
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="flex">
                        <label htmlFor="email" className="font-medium w-1/2">
                          Email:
                        </label>
                        <label
                          htmlFor="mobile_number"
                          className="font-medium hidden md:inline-flex"
                        >
                          Mobile Number:
                        </label>
                      </div>
                      <div className="md:flex gap-2">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={editedUser.email}
                          onChange={handleInputChange}
                          className="border rounded-lg px-3 py-2 w-full mt-1 mb-2"
                        />
                        <label
                          htmlFor="mobile_number"
                          className="font-medium md:hidden"
                        >
                          Mobile Number:
                        </label>
                        <input
                          type="text"
                          id="mobile_number"
                          name="mobile_number"
                          value={editedUser.mobile_number}
                          onChange={handleInputChange}
                          className="border rounded-lg px-3 py-2 w-full mt-1 mb-2"
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <label htmlFor="profile_img_url" className="font-medium">
                        Profile Image URL:
                      </label>
                      <input
                        type="text"
                        id="profile_img_url"
                        name="profile_img_url"
                        value={editedUser.profile_img_url}
                        onChange={handleInputChange}
                        className="border rounded-lg px-3 py-2 w-full mt-1"
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="google_id" className="font-medium">
                        Google ID:
                      </label>
                      <input
                        type="text"
                        id="google_id"
                        name="google_id"
                        value={editedUser.google_id}
                        onChange={handleInputChange}
                        className="border rounded-lg px-3 py-2 w-full mt-1"
                      />
                    </div>

                    {/* check box  */}

                    <div className="md:flex">
                      <div className="mb-2 w-1/2">
                        <label htmlFor="g_log" className="font-medium">
                          Google Login:
                        </label>
                        <label className="switch ml-3">
                          <input
                            type="checkbox"
                            id="is_active"
                            name="is_active"
                            checked={editedUser.g_log}
                            onChange={handleToggleChange}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="mb-2">
                        <label htmlFor="is_active" className="font-medium">
                          Is Active:
                        </label>
                        <label className="switch ml-3">
                          <input
                            type="checkbox"
                            id="is_active"
                            name="is_active"
                            checked={editedUser.is_active}
                            onChange={handleToggleChange}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="md:flex">
                      <div className="mb-2 w-1/2">
                        <label htmlFor="is_staff" className="font-medium">
                          Is Staff:
                        </label>
                        <label className="switch ml-3">
                          <input
                            type="checkbox"
                            id="is_staff"
                            name="is_staff"
                            checked={editedUser.is_staff}
                            onChange={handleToggleChange}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="mb-2">
                        <label htmlFor="is_superuser" className="font-medium">
                          Is Superuser (Admin):
                        </label>
                        <label className="switch ml-3">
                          <input
                            type="checkbox"
                            id="is_superuser"
                            name="is_superuser"
                            checked={editedUser.is_superuser}
                            onChange={handleToggleChange}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                    >
                      Update
                    </button>
                  </form>
                  <ToastContainer />

                  {/* Render the modal */}
                  <Modal
                    isOpen={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                    confirmEdit={confirmEdit}
                  />
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default EditUser;
