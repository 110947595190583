import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

const TodayUserData = () => {
  let access_token = localStorage.getItem("access_token");
  const [chartData, setChartData] = useState([]);
  const [FreeUser, setFreeUser] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        setChartData(response.data);
        const TotalFalseValues = response.data.filter(
          (item) => item.is_superuser == false
        ).length;
        setFreeUser(TotalFalseValues);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchData();
  }, []);

  // Calculate the date range for today
  const currentDate = new Date();

  // Create an array of hours from 00:00 to the current hour
  const hoursInDay = Array.from(
    { length: currentDate.getHours() + 1 },
    (_, i) => i
  );

  // Calculate user counts for each hour
  const UsersByHour = hoursInDay.map((hour) => {
    const usersJoinedThisHour = chartData.filter(
      (item) =>
        new Date(item.date_joined).getDate() === currentDate.getDate() &&
        new Date(item.date_joined).getHours() === hour
    );
    return {
      hour: `${hour}:00`,
      Users: usersJoinedThisHour.length,
    };
  });
  return (
    <div className="-ml-7 md:p-5 h-[250px] sm:h-[350px]">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={UsersByHour}>
          <XAxis dataKey="hour" interval={1} />
          <YAxis />
          <Tooltip />
          <Legend wrapperStyle={{ left: 30 }} />
          <Area
            type="monotone"
            dataKey="Users"
            stroke="#a0e030"
            fill="#a0e030"
            stackId="stack"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TodayUserData;
