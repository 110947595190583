import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "../Nav";
import ProductUpdate from "./ProductUpdate";
import { GetToken } from "../../services/localstorage";
import axios from "axios";
import { toast } from "react-toastify";

const ServiceUpdate = () => {
  const base_url = process.env.REACT_APP_PUBLIC_URL;
  const { access_token } = GetToken();
  const id = localStorage.getItem("update_card_id");
  const config = {
    headers: {
      authorization: `Bearer ${access_token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const service_url = base_url + `product-detail/?card_link=${id}`;

  const [products, setProducts] = useState([]);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(service_url, config);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products", error);
      }
    };

    fetchData();
  }, []);

  const handleTabClick = (index) => {
    setSelectedProductIndex(index);
  };

  const s_url = base_url + "product-detail/";

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        s_url,
        { title: "title", description: "Description" },
        config
      );

      // Show a success toast
      toast.success("Product added successfully");

      // Update the products state with the new product
      setProducts((prevProducts) => [...prevProducts, response.data]);
    } catch (error) {
      console.error("Error adding product", error);
      // Handle error and show an error toast if needed
      toast.error("Failed to add product");
    }
  };

  return (
    <div>
      <Nav />
      <div>
        <nav>
          <ul className="flex justify-center text-xl text-gray-600 font-medium px-3 gap-4">
            <li>
              <Link to="/update">Details</Link>
            </li>
            <li className="text-blue-500">
              <Link>Products</Link>
            </li>
          </ul>
        </nav>
      </div>

      {/* Content */}
      <div className="block max-w-sm container mx-auto h-auto mb-8 mt-10 md:max-w-[700px] p-6 bg-white border border-gray-200 rounded-lg shadow">
        <h1
          className="mb-5 text-xl text-blue-600 font-medium cursor-pointer"
          onClick={handleAddProduct}
        >
          Add Product
        </h1>
        {products && products.length > 0 ? (
          <div className="w-full">
            <div className="grid grid-cols-4 sm:grid-cols-6  border-b border-gray-200">
              {products.map((product, index) => (
                <TabItem
                  key={index}
                  isActive={index === selectedProductIndex}
                  onClick={() => handleTabClick(index)}
                >
                  <span className="sm:hover:bg-gray-200 rounded sm:hover:text-gray-700 text-sm">
                    Product {index + 1}
                  </span>
                </TabItem>
              ))}
            </div>

            {/* Display the details of the selected product */}
            {products.length > 0 && (
              <div key={selectedProductIndex}>
                <ProductUpdate product={products[selectedProductIndex]} />
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

// TabItem component to be used for each tab
const TabItem = ({ isActive, onClick, children }) => {
  return (
    <button
      className={`${
        isActive
          ? "border-black text-primary border-b-2"
          : "border-transparent text-gray-500 hover:text-primary"
      } px-1 py-1 mx-1 font-medium focus:outline-none`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default ServiceUpdate;
