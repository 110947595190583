import React, { useState } from "react";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import ReactPaginate from "react-paginate";
import countryList from "country-list";

const WorldUserTable = ({ deviceData }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const dataPerPage = 5;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const startIndex = currentPage * dataPerPage;
  const endIndex = startIndex + dataPerPage;

  // Sort the data by the number of users in descending order
  const sortedData = [...deviceData].sort((a, b) => b.value - a.value);
  const currentData = sortedData.slice(startIndex, endIndex);

  // Function to get full country name from ISO code
  const getCountryName = (isoCode) => {
    const country = countryList.getName(isoCode.toUpperCase());
    return country || isoCode;
  };

  return (
    <div className="sm:py-20 rounded-lg p-2">
      <table className="rounded w-full text-sm text-center text-gray-500">
        <thead className="text-xs text-gray-700 uppercase  ">
          <tr>
            <th scope="col" className="px-4 text-left py-3">
              Country
            </th>
            <th scope="col" className="px-4 py-3 text-right">
              Users
            </th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => (
            <tr
              key={index + startIndex}
              className=" bg-white hover:bg-gray-100"
            >
              <td scope="row" className="px-4 py-3 text-left whitespace-nowrap">
                {getCountryName(item.country)}
              </td>
              <td
                scope="row"
                className="px-4 py-3 text-right whitespace-nowrap"
              >
                {item.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {deviceData && deviceData.length > 5 && (
        <ReactPaginate
          pageCount={Math.ceil(deviceData.length / dataPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination flex justify-center"}
          previousLabel={<MdNavigateBefore className=" text-white" />}
          nextLabel={<MdNavigateNext className="text-white" />}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          pageClassName={"page-item"}
          pageLinkClassName={
            "page-link bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-full px-3 py-1 mx-1" // Style the page links
          }
        />
      )}
    </div>
  );
};

export default WorldUserTable;
