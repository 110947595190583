import React, { useState, useEffect, useRef } from "react";
import log from "./images/grow.gif";
import { useLoginUserMutation } from "../services/UserApi";
import Navbar from "./Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserAuth } from "../features/authSlice";
import { GetToken, StoreToken } from "../services/localstorage";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { gapi } from "gapi-script";
import { GoogleLogin } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./Loading";
import Footer from "./Footer";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const Login = () => {
  const [loading, setloading] = useState(false);
  const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
  const navigate = useNavigate();
  const [servererror, seterror] = useState({});
  const inputRef = useRef(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { loginWithRedirect } = useAuth0();

  const handleTogglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [logUser] = useLoginUserMutation();
  const dispatch = useDispatch();

  const handlesubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const data = new FormData(e.currentTarget);
    const actualdata = {
      username: data.get("email"),
      password: data.get("password"),
    };

    const result = await logUser(actualdata);
    if (result.error) {
      seterror(result.error.data);
      setloading(false);
    } else if (result.data) {
      StoreToken(result.data);

      let { access_token } = GetToken();
      setloading(false);
      dispatch(setUserAuth({ access_token: access_token }));
    }
  };

  let { access_token } = GetToken();
  useEffect(() => {
    dispatch(setUserAuth({ access_token: access_token }));
  }, [access_token, dispatch]);

  const log_url = process.env.REACT_APP_PUBLIC_URL + "google-login/";
  const onSuccess = async (res) => {
    setloading(true);
    const idToken = res.credential;
    const decodedToken = jwtDecode(idToken);
    const actualdata = {
      first_name: decodedToken.given_name,
      last_name: decodedToken.family_name,
      email: decodedToken.email,
      g_profile_img_url: decodedToken.picture,
      google_id: clientId,
    };
    console.log(actualdata);
    await axios
      .post(log_url, actualdata)
      .then((response) => {
        StoreToken(response.data);
        setloading(false);
        navigate("/");
      })
      .catch((error) => {
        seterror(error.response.data);
        setloading(false);
      });
  };
  const onFailure = (err) => {
    console.log("failed:", err);
  };

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const errors = { ...formErrors };

    if (name === "email") {
      if (!value) {
        errors.email = "*Email is required";
      } else {
        errors.email = "";
      }
    }
    if (name === "password") {
      if (!value) {
        errors.password = "*Password is required";
      } else {
        errors.password = "";
      }
    }

    setFormErrors(errors);
  };
  return (
    <>
      {/* <Loading/> */}

      <Navbar />
      {loading ? (
        <Loading class="flex justify-center" />
      ) : (
        <>
          <a
            href
            class="flex flex-col mx-10 h-[450px]  mt-10 mb-10 items-center md:mx-auto bg-white border rounded-lg shadow-lg sm:max-w-[500px] sm:mx-auto    md:flex-row justify-center md:max-w-[750px]"
          >
            <img
              class="object-cover   hidden md:block w-full rounded-t-lg h-96 md:h-auto md:w-[350px] md:rounded-none md:rounded-l-lg "
              src={log}
              alt=""
            />

            <div class="flex flex-col  p-4 leading-normal   ">
              <form onSubmit={(e) => handlesubmit(e)}>
                <div class="relative  z-0 w-full mb-6 group ">
                  <input
                    type="email"
                    name="email"
                    id="floating_email"
                    class="block  py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                    ref={inputRef}
                    onChange={(e) => handleInputChange(e)}
                  />
                  {formErrors.email && (
                    <div className="text-red-500 text-xs">
                      {formErrors.email}
                    </div>
                  )}
                  <label
                    htmlFor="floating_email"
                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Email
                  </label>
                </div>

                <div class="relative flex z-0 w-full mb-6 group">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    id="floating_password"
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                    onChange={(e) => handleInputChange(e)}
                  />

                  {passwordVisible ? (
                    <AiFillEye
                      size={20}
                      color="blue"
                      class="mt-3 "
                      onClick={() => handleTogglePassword()}
                    >
                      {passwordVisible ? "hide" : "show"}
                    </AiFillEye>
                  ) : (
                    <AiFillEyeInvisible
                      size={20}
                      color="blue"
                      class="mt-3 "
                      onClick={() => handleTogglePassword()}
                    >
                      {passwordVisible ? "hide" : "show"}
                    </AiFillEyeInvisible>
                  )}

                  <label
                    htmlFor="floating_password"
                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Password
                  </label>
                </div>
                {formErrors.password && (
                  <div className="text-red-500 text-xs -mt-5">
                    {formErrors.password}
                  </div>
                )}
                <div class="flex mt-[-5px]  justify-end">
                  <Link to="/reset-password" class="text-blue-600 text-sm ">
                    Forgot Password?
                  </Link>
                </div>

                <div class="flex flex-col  justify-between">
                  <button
                    type="submit"
                    class="text-white rounded-md bg-blue-700 mt-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm  text-center dark:bg-blue-600  py-2  mx-2.5 dark:hover:bg-blue-700 dark:focus:ring-blue-800  "
                  >
                    Login
                  </button>

                  <div className="mx-3">
                    <button onClick={() => loginWithRedirect}></button>

                    <GoogleLogin
                      clientId={clientId}
                      buttonText="Sign in with google"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      scope="email profile"
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={true}
                      class="mt-5"
                    />
                  </div>
                </div>

                <hr class="mt-4"></hr>
                <div class=" flex justify-end text-gray-700 mt-2   text-xs md:text-sm ">
                  <span>
                    Not Registered?{" "}
                    <Link to="/signup" class=" text-blue-500">
                      Sign Up
                    </Link>
                  </span>
                </div>
                <div class="bg-white flex h-10 justify-center items-center"></div>
              </form>
            </div>
          </a>
        </>
      )}

      <ToastContainer />
      <Footer />
    </>
  );
};

export default Login;
