import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const COLORS = ["#0088FE", "#FF8042"];

const BrowserData = () => {
  let access_token = localStorage.getItem("access_token");
  const [dynamicData, setDynamicData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDynamicData(response.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Calculate active and non-active user counts
  const totalUsers = dynamicData.length;
  const activeUsers = dynamicData.filter((item) => item.is_active).length;
  const nonActiveUsers = totalUsers - activeUsers;

  const pieChartData = [
    { name: "Active Users", count: activeUsers },
    { name: "Inactive Users", count: nonActiveUsers },
  ];

  return (
    <>
      {/* mobile responsive  */}
      <div className="flex justify-center items-center sm:hidden">
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              dataKey="count"
              isAnimationActive={true}
              data={pieChartData}
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label={(entry) =>
                entry.name
                  .split(" ")
                  .map((part) => part[0])
                  .join("")
              }
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend wrapperStyle={{ left: 30 }} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      {/* medium screen size  */}
      <div className="hidden lg:hidden sm:flex sm:justify-center sm:items-center">
        <div className="sm:w-full -ml-20 text-center">
          <ResponsiveContainer width="100%" height={450}>
            <PieChart>
              <Pie
                dataKey="count"
                isAnimationActive={true}
                data={pieChartData}
                cx="50%"
                cy="50%"
                outerRadius={100}
                label={(entry) =>
                  entry.name
                    .split(" ")
                    .map((part) => part[0])
                    .join("")
                }
              >
                {pieChartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend wrapperStyle={{ left: -10 }} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      {/* large responsive  */}
      <div className="hidden xl:hidden lg:flex lg:justify-center lg:items-center">
        <div className="lg:w-full -ml-20 text-center">
          <ResponsiveContainer width="100%" height={450}>
            <PieChart>
              <Pie
                dataKey="count"
                isAnimationActive={true}
                data={pieChartData}
                cx="50%"
                cy="50%"
                outerRadius={100}
                label={(entry) =>
                  entry.name
                    .split(" ")
                    .map((part) => part[0])
                    .join("")
                }
              >
                {pieChartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend wrapperStyle={{ left: -10 }} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      {/* extra large screen responsive  */}
      <div className="hidden xl:flex xl:justify-center xl:items-center">
        <div className="xl:w-full -ml-20 text-center">
          <ResponsiveContainer width="100%" height={450}>
            <PieChart>
              <Pie
                dataKey="count"
                isAnimationActive={true}
                data={pieChartData}
                cx="50%"
                cy="50%"
                outerRadius={140}
                label={(entry) => entry.name}
              >
                {pieChartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend wrapperStyle={{ left: -10 }} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default BrowserData;
