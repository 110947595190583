import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
} from "recharts";

const Last30Minutes = () => {
  let access_token = localStorage.getItem("access_token");
  const [chartData, setChartData] = useState([]);
  const [totalUsersInLast30Minutes, setTotalUsersInLast30Minutes] = useState(0); // New state for total users

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        setChartData(response.data);
        const currentTime = new Date();
        const thirtyMinutesAgo = new Date(currentTime - 30 * 60 * 1000);
        const usersInLast30Minutes = response.data.filter(
          (item) => new Date(item.date_joined) >= thirtyMinutesAgo
        );
        setTotalUsersInLast30Minutes(usersInLast30Minutes.length);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchData();
  }, []);

  // Calculate the time range for the last 30 minutes
  const currentTime = new Date();
  const thirtyMinutesAgo = new Date(currentTime - 30 * 60 * 1000);

  // Create an array of 1-minute intervals within the last 30 minutes
  const intervalsInLast30Minutes = [];
  let intervalTime = new Date(thirtyMinutesAgo);
  while (intervalTime <= currentTime) {
    intervalsInLast30Minutes.push(new Date(intervalTime));
    intervalTime = new Date(intervalTime.getTime() + 60 * 1000);
  }

  // Calculate user counts for each 1-minute interval
  const UsersByInterval = intervalsInLast30Minutes.map((interval) => {
    const usersJoinedThisInterval = chartData.filter(
      (item) =>
        new Date(item.date_joined) >= interval &&
        new Date(item.date_joined) < new Date(interval.getTime() + 60 * 1000)
    );
    return {
      interval: `${interval.getHours()}:${interval.getMinutes()}`,
      Users: usersJoinedThisInterval.length,
    };
  });
  return (
    <>
      <div className="p-2 text-[12px] font-semibold text-gray-500">
        USERS IN LAST 30 MINUTES
        <div className="text-4xl px-4">{totalUsersInLast30Minutes}</div>{" "}
        {/* Display total users */}
      </div>
      <div className="p-2 text-[12px] font-semibold text-gray-500">
        USERS PER MINUTE
      </div>
      <div className="p-5">
        <ResponsiveContainer width="100%" height={100}>
          <LineChart data={UsersByInterval}>
            <Tooltip />
            <Line
              type="monotone"
              dataKey="Users"
              stroke="#2f72e5"
              fill="#a0e030"
              stackId="stack"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default Last30Minutes;
