import { GetToken, StoreToken } from "../../services/localstorage";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import axios from "axios";

const Otpverify = () => {
  const [code, setCode] = useState("");
  const base_url = process.env.REACT_APP_PUBLIC_URL;

  const handleChange = (code) => {
    setCode(code);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const navigate = useNavigate();
  const [servererror, setError] = useState({});
  const [loading, setloading] = useState(false);

  const handleVerify = () => {
    if (!code) {
      setError({ status: "Verification Code required." });
    }

    setloading(true);

    axios
      .post(
        base_url + "verify/",
        { otp: code, user_key: window.sessionStorage.getItem("user_key") },
        config
      )
      .then((response) => {
        setError(response.data);
        setloading(false);
        // StoreToken(response.data)
        toast.success("User Verified. Now try Logging in.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigate("/login");
        }, 3000);

        // navigate('/login')
      })
      .catch((error) => {
        setError(error.response.data);
        setloading(false);
      });
  };

  const resend = () => {
    setloading(true);
    axios
      .get(
        base_url + "resend/" + window.sessionStorage.getItem("user_key"),
        config
      )
      .then((response) => {
        setError(response.data);
        setloading(false);
      });
  };

  return (
    <div class=" max-w-lg h-screen  mx-auto flex justify-center items-center my-auto p-2">
      <div class="block -mt-10 sm:max-w-lg p-10 bg-slate-50 border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 class="text-2xl font-medium text-gray-500 text-center my-3">
          OTP Verification
        </h5>
        <p class="font-normal mt-1 text-center text-gray-500 dark:text-gray-400">
          Enter OTP Code Sent To Your Email
        </p>

        <div class="flex justify-center my-4">
          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={4}
            inputStyle={{
              width: "40px",
              height: "40px",
              margin: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              fontSize: "16px",
              textAlign: "center",
            }}
            renderInput={(props) => <input {...props} />}
            separator={<span style={{ width: "8px" }}></span>}
          />
        </div>
        <div className="my-3">
          <p class="font-normal mt-1 text-center text-gray-500 dark:text-gray-400">
            Don't Receive OTP Code?
          </p>
          <p
            onClick={resend}
            class="font-medium cursor-pointer mt-1 text-center text-blue-500 dark:text-gray-400"
          >
            Resend Code
          </p>
        </div>

        <div class="flex justify-center  mt-2 gap-3">
          <button
            type="button"
            class="text-white bg-blue-500 h-10 mt-2 px-10 hover:bg-[#13CAFD] shadow-lg rounded text-md font-medium"
            onClick={handleVerify}
          >
            Verify & Proceed
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Otpverify;
