/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetToken } from "../../../../services/localstorage";
import axios from "axios";
const Profile = () => {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  // Getting user data from backend

  useEffect(() => {
    // Function to fetch user data using the JWT token
    const UserData = async () => {
      try {
        const token = GetToken();
        const response = await fetch(baseUrl + "get-user-profile/", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token["access_token"]}`,
          },
        });
        const userData = await response.json();
        setUserDetails(userData);
      } catch (error) {
        // Handle error
      }
    };

    UserData();
  }, []);

  return (
    <div className="bg-gray-100 h-auto p-1">
      <div className="mx-auto max-w-3xl h-auto md:w-2/3">
        {userDetails ? (
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-2xl font-semibold">Your Account Details </h2>
            <div className="py-2 space-y-2">
              {userDetails.profile_img_url && (
                <p className="p-2">
                  <img
                    src={userDetails.profile_img_url}
                    alt="profile img"
                    className="bg-green md:h-[110px] p-1 border"
                  />
                </p>
              )}
              {/* personal details  */}
              <div className="border-b font-semibold">Personal Details</div>
              <div className="md:flex p-3 space-y-2">
                <div className="md:w-1/2 space-y-2">
                  <p className="text-gray-600">
                    <span className="font-medium">User Id: </span>
                    {userDetails.id}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">First Name:</span>{" "}
                    {userDetails.first_name}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">Last Name:</span>{" "}
                    {userDetails.last_name}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-gray-600">
                    <span className="font-medium">Email:</span>{" "}
                    {userDetails.email}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">Mobile Number: </span>{" "}
                    {userDetails.mobile_number
                      ? userDetails.mobile_number
                      : "N/A"}
                  </p>
                </div>
              </div>
              {/* status details  */}
              <div className="border-b font-semibold">Status Details</div>
              <div className="md:flex p-3 space-y-2">
                <div className="w-1/2 space-y-3">
                  <p className="text-gray-600">
                    <span className="font-medium">Active:</span>{" "}
                    {userDetails.is_active === true ? "True" : "False"}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">Staff:</span>{" "}
                    {userDetails.is_staff === true ? "True" : "False"}
                  </p>{" "}
                  <p className="text-gray-600">
                    <span className="font-medium">Admin: </span>{" "}
                    {userDetails.is_superuser === true ? "True" : "False"}
                  </p>
                </div>
                <div className="space-y-3">
                  <p className="text-gray-600">
                    <span className="font-medium">Google Authenticated: </span>
                    {userDetails.g_log === true ? "True" : "False"}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">IP Address:{} </span>
                  </p>
                </div>
              </div>

              {/* entry details  */}
              <div className="border-b font-semibold">Entry Details</div>
              <div className="md:flex p-3 space-y-2">
                <div className="w-1/2 space-y-3">
                  <p className="text-gray-600">
                    <span className="font-medium">Joined Date: </span>
                    {userDetails.date_joined}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">Otp: </span>
                    {userDetails.otp}
                  </p>
                </div>
                <div className="space-y-3 md:pl-5">
                  <p className="text-gray-600">
                    <span className="font-medium">User Key: </span>
                    {userDetails.user_key}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">
                      Otp Creation time:&nbsp;
                    </span>
                    {userDetails.otp_creation_time}
                  </p>
                </div>
              </div>
              {/* Other details  */}
              <div className="border-b font-semibold">Other Details</div>
              <div className="md:flex p-3 space-y-2">
                <div className="md:w-1/2 space-y-3">
                  <p className="text-gray-600">
                    <span className="font-medium">Profile-User:</span>{" "}
                    {userDetails.profile_user
                      ? userDetails.profile_user
                      : "N/A"}
                  </p>
                  <p className="text-gray-600 md:w-full truncate">
                    <span className="font-medium flex">profile-img-url:</span>
                    {userDetails.profile_img_url
                      ? userDetails.profile_img_url
                      : "N/A"}
                  </p>
                </div>
                <div className="space-y-3">
                  {/* <p className="text-gray-600">
                    <span className="font-medium">Cards Created:</span>{" "}
                    {userDetails.cards && userDetails.cards.length}
                  </p> */}
                  <p className="text-gray-600">
                    <span className="font-medium">Google Id:</span>
                    {userDetails.google_id ? userDetails.google_id : "N/A"}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="p-4">
              <Link to={`/dashboard/edit-user/${userDetails.id}`}>
                <span className="text-white p-2 bg-blue-500 hover:bg-blue-600 rounded px-4">
                  Edit
                </span>
              </Link>
            </div> */}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Profile;
