import React, { useEffect, useState } from "react";
import { BsArrowLeft, BsSpeedometer2 } from "react-icons/bs";
import { DiGoogleAnalytics } from "react-icons/di";
import { GiSightDisabled } from "react-icons/gi";
import { LiaSignInAltSolid } from "react-icons/lia";
import { TbHomeCog, TbUserCog } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import { GetToken } from "../../../services/localstorage";
import logo from "../../images/img.png";

function Sidebar() {
  const location = useLocation(); // Get the current location
  const [Data, setData] = useState();
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  useEffect(() => {
    const UserData = async () => {
      try {
        const token = GetToken();
        const response = await fetch(baseUrl + "get-user-profile/", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token["access_token"]}`,
          },
        });
        const userData = await response.json();
        setData(userData);
      } catch (error) {
        console.log(error);
      }
    };

    UserData();
  }, []);

  return (
    <div
      className="bg-gray-200 p-5 h-screen sticky z-50 w-full"
      style={{ fontFamily: "tahoma" }}
    >
      <div className="m-2 flex items-center">
        <span className="text-center text-xl ">
          <Link to={"/"}>
            <img src={logo} className="h-7" alt="logo" />
          </Link>
        </span>
      </div>
      <hr className="text-black" />
      <div className="mt-2 ">
        <Link
          to={"/"}
          className="p-2 m-1 flex items-center hover:bg-blue-500 hover:text-white hover:rounded-lg"
        >
          <span className="break-words w-[150px] flex items-center gap-1">
            <BsArrowLeft /> Back To Cards
          </span>
        </Link>
        <Link
          to="/dashboard"
          className={`p-2 m-1 flex items-center hover:bg-blue-500 hover:text-white hover:rounded-lg cursor-pointer ${
            location.pathname === "/dashboard"
              ? "bg-blue-500 text-white rounded-lg"
              : ""
          }`}
        >
          <span className="flex items-center gap-1">
            <BsSpeedometer2 className="text-gray-700" />
            Dashboard/Home
          </span>
        </Link>
        <Link
          to="/dashboard/user-management"
          className={`p-2 m-1 flex items-center hover:bg-blue-500 hover:text-white hover:rounded-lg cursor-pointer ${
            location.pathname === "/dashboard/user-management"
              ? "bg-blue-500 text-white rounded-lg"
              : ""
          }`}
        >
          <span className="flex items-center gap-1">
            <TbUserCog className="text-gray-700" />
            User management
          </span>
        </Link>
        <Link
          to="/dashboard/account-setting"
          className={`p-2 m-1 flex items-center hover:bg-blue-500 hover:text-white hover:rounded-lg cursor-pointer ${
            location.pathname === "/dashboard/account-setting"
              ? "bg-blue-500 text-white rounded-lg"
              : ""
          }`}
        >
          <span className="flex items-center gap-1">
            <TbHomeCog className="text-gray-700" />
            Account Details
          </span>
        </Link>
        <Link
          to="/dashboard/analytics"
          className={`p-2 m-1 flex items-center hover:bg-blue-500 hover:text-white hover:rounded-lg cursor-pointer ${
            location.pathname === "/dashboard/analytics"
              ? "bg-blue-500 text-white rounded-lg"
              : ""
          }`}
        >
          <span className="flex items-center gap-1">
            <DiGoogleAnalytics className="text-gray-700 text-xl" />
            Analytics
          </span>
        </Link>
      </div>
    </div>
  );
}

export default Sidebar;
