import React , {useState,useMemo} from 'react';
import { GetToken } from '../../services/localstorage';
import axios from 'axios';
import { Link,useNavigate } from 'react-router-dom';
import Nav from '../Nav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CompanyUpdate = () => {

    const base_url =process.env.REACT_APP_PUBLIC_URL

    const [servererror,seterror] = useState({})
    const [isloading,setloading] = useState(false)
    const [counter,setCounter] = useState(0)
    const[success,setSuccess] =  useState(false)
      
    const navigate=useNavigate()

   const id=localStorage.getItem('update_card_id') 
//    console.log(id)
   const {access_token} = GetToken()

   const config = {
    headers: {
        "authorization" : `Bearer ${access_token}`,
       
    }
}

  const work_url= base_url+"create-work-detail/?id="+id
 
  const [workData,setWorkdata]=useState([])


  useMemo(()=>{
    const WorkGetting= async ()=>{
        setloading(true)
    
            try{
                let mydata=await axios.get(work_url,config)
                setWorkdata(mydata.data)
                // console.log(mydata.data)
                setloading(false)
            }
            catch(error){
                setloading(false)
                seterror(error)
            }
      }
      WorkGetting()
  },[counter])
    

  const handleCompanySubmit = async (e)=>{
    e.preventDefault();
    setloading(true);
    setCounter(counter+1)
    const data = new FormData(e.currentTarget);
    const actualdata = {
        company_name :data.get('company_name'),
        business_title :data.get('business_title'),
        address :data.get('address'),
        about_us: data.get('about_us'),
        job_position:data.get('job_position'),
        link: data.get('link')
    }
    
         axios.put(work_url,actualdata,config).then(response=>{
            setloading(false);
            setWorkdata(response.data)
            console.log(response.data)
            setSuccess(true)
            toast.success('Updated  Successfully !', {
                position: toast.POSITION.TOP_RIGHT
            });
             setTimeout(()=>{
                 navigate('/card')
             },6000)
             
        })                        
        .catch(error=>{
            console.log(error)
            seterror(error.response.data)
            setloading(false);           
        })    

   }


  return (
    <div>
            <Nav/>
            <div >
                    <nav >
                        <ul class="flex  justify-center text-xl text-gray-600 font-medium px-3 gap-4 ">
                            <li><Link to='/update'>Personal</Link></li>
                            <li class="text-blue-500"><Link >Work</Link></li>
                            <li><Link to="/service-update">Services</Link></li>
                            <li><Link to='/social-update'>Social</Link></li>

                        </ul>
                    </nav>
                </div>

            <a class="block max-w-sm container mx-auto  h-auto  mb-5   mt-10 md:max-w-[700px]  p-6 bg-white border border-gray-200 rounded-lg shadow ">

                <form onSubmit={(e)=>handleCompanySubmit(e)}>
                <h1 class="mb-5 text-xl text-blue-600 font-medium">Company Details</h1>
                    <div class="grid md:grid-cols-2 md:gap-6">
                        <div class="relative z-0 w-full mb-6 group">
                            <input type="text" name="company_name" id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={workData.company_name}  required />
                            <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Business Name</label>
                        </div>


                        <div class="relative z-0 w-full mb-6 group">
                            <input type="text" name="business_title" id="floating_last_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  defaultValue={workData.business_title} required />
                            <label for="floating_last_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Business Titlle/Category</label>
                        </div>
                    </div>


                    <div class="grid md:grid-cols-2 md:gap-6">
                        <div class="relative z-0 w-full mb-6 group">
                            <input type="text" name="address" id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none e dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={workData.address} required />
                            <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Business Location</label>
                        </div>


                        <div class="relative z-0 w-full mb-6 group">
                            <input type="text" name="link" id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={workData.link}  required />
                            <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Business  Website Link</label>
                        </div>
                    </div>

                    <div class="grid md:grid-cols-2 md:gap-6">
                        <div class="relative z-0 w-full mb-6 group">
                            <input type="text" name="job_position" id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={workData.job_position}  required />
                            <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Designation</label>
                        </div>
                    </div>

                    <label for="message" class="block mb-2 text-sm font-medium text-gray-500 dark:text-white">About Your Business</label>
                    <textarea id="message" defaultValue={workData.about_us} rows="4" name="about_us" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500  outline-none shadow-md
        " placeholder="Write your thoughts here..."></textarea>


                    <div class="mb-3">

                        <button type="submit" class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Update</button>


                        <ToastContainer/>
{/* 

                        <button type="submit" class="text-white mx-4 mt-4 bg-green-600 hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 shadow-md
                        "><Link to='/user-details'>Previous</Link></button> */}

                    </div>
                </form>

            </a>

        </div>


  )
}

export default CompanyUpdate
