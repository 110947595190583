import React, { useState, useEffect } from "react";
import "./Custom.css";
import {
  BsFillTelephoneFill,
  BsShareFill,
  BsPersonFillAdd,
  BsFacebook,
  BsTwitter,
  BsLinkedin,
  BsTelephoneFill,
  BsInstagram,
  BsTelegram,
  BsYoutube,
  BsBoxes,
} from "react-icons/bs";
import { PiShareNetwork } from "react-icons/pi";
import threads from "../images/threads.png";
import { HiOutlineCursorClick } from "react-icons/hi";
import { FaLink, FaMapMarkerAlt } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { BiLinkExternal, BiSolidCloudDownload } from "react-icons/bi";
import { IoMdPersonAdd } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";
import QRCode from "qrcode";
import { Helmet } from "react-helmet";
import { FcPrevious } from "react-icons/fc";

import Modal from "react-modal";
import "./Modal.css";
import { TbWorldWww } from "react-icons/tb";
import Carousel from "./Carousel";
import ReactCardFlip from "react-card-flip";

const CardShare = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [wopen, wsetOpen] = React.useState(false);
  const whandleOpen = () => wsetOpen(true);
  const whandleClose = () => wsetOpen(false);

  const [isloading, setloading] = useState(true);
  const [name, setName] = useState("");
  const params = useParams();

  console.log("id====", params.id);
  const [cardData, setCardData] = useState([]);
  const [cardProducts, setCardProducts] = useState([]);
  const [social, setSocial] = useState([]);

  // flip animation
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };
  // ping animate
  const [isAnimating, setIsAnimating] = useState(true);

  const handleIconClick = () => {
    setIsAnimating(false); // Stop animation
  };

  // console.log(params.id)
  const url = process.env.REACT_APP_PUBLIC_URL + "shared-card/" + params.id;

  useEffect(() => {
    async function getData() {
      try {
        let mydata = await axios.get(url);
        setCardData(mydata.data);
        console.log("mydata", mydata.data);
        setloading(false);
        setCardProducts(mydata.data[0].card_products);
        console.log("socials", mydata.data[0].card_socials);

        // const datasocial = mydata.data[0].social_media;
        // const socialmedia = JSON.parse(datasocial);
        // const dataArray = Object.keys(socialmedia).map((key) => ({
        //   [key]: socialmedia[key],
        // }));
        // console.log("dataArray", dataArray);

        setSocial(mydata.data[0].card_socials);
      } catch (error) {
        setloading(false);
      }
    }
    getData();
  }, []);

  const [qrcode, setQr] = useState("");

  useEffect(() => {
    QRCode.toDataURL(
      process.env.REACT_APP_WEB_URL + "shared-card/" + params.id
    ).then((data) => {
      setQr(data);
      console.log("qr", qrcode);
    });
  }, []);

  const even = (e) => {
    if (e % 2 === 0) {
      console.log("sure");
    }
  };

  const handleClick = (url, filename) => {
    axios
      .get(url.replace("http", "https"), {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  function cardhandle(card, index) {
    console.log(card, "==============++++++++++++");
    const code = card.card_customisations[0].template_code;
    console.log(
      "card data======================+++++++++++++",
      card.card_customisations
    );
    const primary = card.card_customisations[0].card_color_primary;
    const secondary = card.card_customisations[0].card_color_sec;

    const specificKeys = [
      "threads",
      "twitter",
      "facebook",
      "instagram",
      "linkedin",
    ];

    return (
      <div key={index}>
        <Helmet>
          <title class="capi">
            {card.first_name + " " + card.last_name}-Profile
          </title>
          <meta property="og:image" content={card.profile_img} />
          <meta property="og:title" content="create a bussiness digital card" />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={process.env.REACT_APP_WEB_URL + "shared-card/" + params.id}
          />
        </Helmet>

        <div class="flex mt-1  md:mx-10 ">
          <Link to="/" class="flex">
            <FcPrevious class=" " size={30} />
            <p class=" text-lg font-medium text-[#55AFF6]">Home</p>
          </Link>
        </div>

        {/* Card 1 */}

        {code === "0" && (
          <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <div class="container mx-auto bottom-3 flex border-blue600  max-w-sm justify-center border  mt-10 h-auto">
              <div
                class=" w-full h-[250px] relative"
                style={{ backgroundColor: primary }}
              >
                <div
                  class=" w-[280px] h-[40px] rounded-b-2xl mx-auto"
                  style={{ backgroundColor: secondary }}
                >
                  {/* <BiInfinite color='white' size={90} class="mx-auto"/> */}

                  <p class="text-center text-white  text-xl font-medium capitalize">
                    {card.business_name}
                  </p>
                </div>
                <p
                  class="text-center mt-2 uppercase   text-2xl font-bold"
                  style={{ color: secondary }}
                >
                  {card.first_name + " " + card.last_name}
                </p>
                <p class="text-center uppercase">{card.profession}</p>
                <img
                  src={card.profile_img}
                  class="w-[180px] h-[180px] object-cover mx-auto mt-3"
                  alt="img"
                />
                <div
                  class=" flex flex-col w-[120px] h-1 mt-1 mx-auto"
                  style={{ backgroundColor: secondary }}
                ></div>

                <div className="flex">
                  <div>
                    <a
                      href
                      class="block max-w-sm p-6 bg-white dark:border-gray-700"
                    >
                      <div class="flex">
                        <div
                          class="w-7 h-[200px] mx-5 "
                          style={{ backgroundColor: secondary }}
                        >
                          <BsFillTelephoneFill
                            color={primary}
                            size={18}
                            class="mx-auto mt-7"
                          />
                          <GrMail
                            color={primary}
                            size={18}
                            class="mx-auto mt-[38px]"
                          />
                          <BsShareFill
                            color={primary}
                            size={18}
                            class="mx-auto mt-[30px]"
                          />
                        </div>
                        <div class="flex flex-col">
                          <div>
                            <div class="mt-6  ">
                              <a href={"tel:" + card.mobile_number}>
                                <p>{card.mobile_number}</p>
                              </a>

                              <a href={"tel:" + card.telephone}>
                                <p>{card.telephone}</p>
                              </a>
                              <div
                                class="w-full h-1 mt-3"
                                style={{ backgroundColor: primary }}
                              ></div>
                            </div>
                          </div>

                          <div>
                            <div class="mt-3">
                              <a href={"mailto: " + card.email}>
                                <p>{card.email}</p>
                              </a>

                              <div
                                class=" w-full h-1 mt-2"
                                style={{ backgroundColor: primary }}
                              ></div>
                            </div>
                          </div>

                          <div>
                            <div class="mt-3">
                              <a
                                href={card.website_link}
                                target="_blank"
                                rel=" noreferrer"
                              >
                                <p>
                                  {" "}
                                  {card.website_link.replace(
                                    /^(https?:\/\/)/,
                                    ""
                                  )}
                                </p>
                              </a>
                              <div
                                class=" w-full h-1 mt-2"
                                style={{ backgroundColor: primary }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                {/* <div
                className="rounded my-4 w-80"
                style={{ backgroundColor: secondary }}
              >
                <Carousel />
              </div> */}
                {/* {console.log("cardProducts", cardProducts)} */}

                {cardProducts && cardProducts.length > 0 && cardProducts[0].title && (
                  <div
                    className="p-2 my-3 rounded-lg text-center  text-lg font-semibold flex items-center justify-center gap-2 cursor-pointer"
                    style={{ backgroundColor: primary, color: secondary }}
                    onClick={handleFlip}
                  >
                    <BsBoxes /> View Products
                  </div>
                )}
                <div
                  class=" w-full h-auto"
                  style={{ backgroundColor: secondary }}
                >
                  <div class="flex justify-center">
                    <button
                      type="button"
                      class=" flex items-center px-2 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg  font-medium rounded-lg text-sm py-2 text-center my-2"
                      onClick={handleOpen}
                      style={{ backgroundColor: primary, color: secondary }}
                    >
                      <BsShareFill class="mr-2" size={16} />
                      Share
                    </button>
                  </div>
                  <div class="flex items-center flex-col">
                    <p className="text-lg font-medium text-white hover:font-medium mb-1 ">
                      Scan to share
                    </p>
                    <img
                      src={qrcode}
                      class="shadow-lg w-[150px] rounded-xl  h-[150px]"
                      alt="product qr-code "
                    />

                    <div class="flex flex-col text-center">
                      <a href={qrcode} download class="mx-6">
                        <span class="text-md font-bold text-white hover:font-medium">
                          <u> Download</u>
                        </span>
                      </a>

                      {/* <a href={qrcode} download class="mx-6 ">
                       <span class="text-md font-bold  text-white  hover:font-medium">Share</span>
                        </a> */}
                      {/* E1D7CD */}
                      <div
                        class=" w-[150px] h-0.5 mt-1  mx-auto"
                        style={{ backgroundColor: secondary }}
                      ></div>
                    </div>
                    <div className="">
                      {social.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="cursor-pointer flex gap-6 justify-center py-1"
                          >
                            {Object.keys(item).map((key) => {
                              if (key === "facebook" && item[key]) {
                                const shareUrl = `https://www.facebook.com/${item[key]}`;
                                return (
                                  <div
                                    key={index}
                                    onClick={() =>
                                      window.open(shareUrl, "_blank")
                                    }
                                    className="flex items-center gap-1"
                                  >
                                    <BsFacebook
                                      color={primary}
                                      size={27}
                                      className="mt-1"
                                    />
                                    {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                                  </div>
                                );
                              }

                              if (key === "instagram" && item[key]) {
                                const shareUrl = `https://www.instagram.com/${item[key]}`;
                                return (
                                  <div
                                    onClick={() =>
                                      window.open(shareUrl, "_blank")
                                    }
                                    className="flex items-center gap-1"
                                  >
                                    <BsInstagram
                                      color={primary}
                                      size={24}
                                      className="mt-1.5"
                                    />
                                    {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                                  </div>
                                );
                              }

                              if (key === "twitter" && item[key]) {
                                const shareUrl = `https://twitter.com/${item[key]}`;
                                return (
                                  <div
                                    onClick={() =>
                                      window.open(shareUrl, "_blank")
                                    }
                                    className="flex items-center gap-1"
                                  >
                                    <BsTwitter
                                      color={primary}
                                      size={28}
                                      className="mt-0.5"
                                    />
                                    {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                                  </div>
                                );
                              }

                              if (key === "linkedin" && item[key]) {
                                const shareUrl = `https://www.linkedin.com/in/${item[key]}`;
                                return (
                                  <div
                                    onClick={() =>
                                      window.open(shareUrl, "_blank")
                                    }
                                    className="flex items-center gap-1"
                                  >
                                    <BsLinkedin
                                      color={primary}
                                      size={24}
                                      className="mt-1.5"
                                    />
                                    {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                                  </div>
                                );
                              }

                              if (key === "telegram" && item[key]) {
                                const shareUrl = `https://t.me/${item[key]}`;
                                return (
                                  <div
                                    onClick={() =>
                                      window.open(shareUrl, "_blank")
                                    }
                                    className="flex items-center gap-1"
                                  >
                                    <BsTelegram color={primary} size={30} />
                                    {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                                  </div>
                                );
                              }

                              if (key === "others" && item[key]) {
                                const shareUrl = `https://${item[key]}`;
                                return (
                                  <div
                                    onClick={() =>
                                      window.open(shareUrl, "_blank")
                                    }
                                    className="flex items-center gap-1"
                                  >
                                    <BiLinkExternal color={primary} size={30} />
                                    {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {/* <div class="flex flex-col    mx-auto">
                    <div class="flex justify-center  gap-2 ">
                      <ul>
                        <li class="flex gap-4">
                          <BsShareFill size={18} color="white" />
                          <span onClick={handleOpen} class="text-white">
                            Share Details
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div> */}

                  <div class=" flex h-10 justify-center rounded-md mt-4  ">
                    <a
                      download
                      href={
                        process.env.REACT_APP_PUBLIC_URL +
                        "vcf-file/" +
                        card.card_link
                      }
                      class="flex"
                    >
                      <button
                        class=" text-xl  rounded-lg px-3  font-medium"
                        style={{ backgroundColor: primary, color: secondary }}
                      >
                        Add To Contacts
                      </button>
                    </a>
                  </div>

                  <div class="p-5">
                    <p class="text-white text-center my-2">{card.address}</p>
                    <Link
                      className="flex items-center justify-center  p-2 rounded font-semibold text-lg"
                      style={{ backgroundColor: primary, color: secondary }}
                      to={`https://www.google.com/maps?q=${card.address}`}
                      target="_blank"
                    >
                      View on Map
                      <FaMapMarkerAlt className="mx-1" color={secondary} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* BACK SIDE OF THE CARD  */}
            <div className="container mx-auto flex my-10  max-w-lg justify-center  h-auto ">
              <div
                style={{ backgroundColor: primary }}
                className="block max-w-sm p-8  w-full text-white   border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
              >
                {cardProducts && cardProducts.length > 0 && (
                  <div
                    className="rounded w-80"
                    style={{ backgroundColor: primary }}
                  >
                    <Carousel
                      cardProducts={cardProducts}
                      secondary={secondary}
                      primary={primary}
                    />
                  </div>
                )}
                <div
                  className="p-2 my-3 rounded-lg text-center text-white text-lg font-semibold flex items-center justify-center gap-2 cursor-pointer"
                  style={{ backgroundColor: secondary }}
                  onClick={handleFlip}
                >
                  Go Back To Card Details
                </div>
              </div>
            </div>
          </ReactCardFlip>
        )}

        {/* CARD 2---------------------------------------------------------------------------------------------------------------- */}

        {code === "1" && (
          <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <div class="container mx-auto flex my-10  max-w-lg justify-center  h-auto">
              <a
                href
                class="block max-w-sm p-6  w-full   border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                style={{ backgroundColor: primary }}
              >
                <div
                  class="w-full mt-[-24px] h-8 py-1 rounded-b-3xl"
                  style={{ backgroundColor: secondary }}
                >
                  <p class="text-center font-medium capitalize text-white">
                    {card.business_name}
                  </p>
                </div>

                <div>
                  <img
                    src={card.profile_img}
                    class="w-40 h-40 rounded-full object-cover mx-auto mt-2  shadow-lg"
                    alt="img"
                  ></img>
                  <p class="uppercase font-bold text-center text-white mt-2 text-xl">
                    {" "}
                    {card.first_name + " " + card.last_name}
                  </p>
                  <p class="uppercase text-center text-sm text-white  font-medium">
                    {" "}
                    {card.profession}
                  </p>
                  <div
                    class="   w-[150px] h-0.5 mt-1  mx-auto"
                    style={{ backgroundColor: secondary }}
                  ></div>
                </div>

                <div className={`flex h-auto gap-10 transition-opacity my-2`}>
                  <div
                    class="w-5 h-[200px] mx-[-20px] rounded-r-lg flex items-center"
                    style={{ backgroundColor: secondary }}
                  ></div>
                  <div class=" flex  ">
                    <ul class="flex flex-col  text-lg  mt-6  font-medium">
                      <li class="flex hover:cursor-pointer hover:text-[#CD3047]  gap-2">
                        <BsFillTelephoneFill
                          color="white"
                          size={20}
                          class="mt-1 "
                        />
                        <div class="flex flex-col">
                          <a href={"tel:" + card.mobile_number}>
                            <p class="text-md  text-white">
                              {" "}
                              {card.mobile_number}
                            </p>
                          </a>
                          <span class="text-gray-300 text-sm mx-1">
                            (Contact)
                          </span>
                        </div>
                      </li>

                      <li class="flex hover:cursor-pointer mt-1 gap-2">
                        <GrMail size={22} color="white" class="mt-1" />

                        <div class="flex flex-col">
                          <a href={"mailto: " + card.email}>
                            <p class="text-white">{card.email}</p>
                          </a>
                          <span class="text-gray-300 text-sm mx-1">
                            (Email)
                          </span>
                        </div>
                      </li>
                      <li class="flex hover:cursor-pointer  mt-1 hover:text-[#CD3047] gap-2">
                        <FaLink size={22} color="white" class="mt-1" />
                        <div class="flex flex-col">
                          <a
                            href={card.website_link}
                            target="_blank"
                            rel=" noreferrer"
                          >
                            {" "}
                            <p class="text-white">
                              {" "}
                              {card.website_link.replace(/^(https?:\/\/)/, "")}
                            </p>
                          </a>
                          <span class="text-gray-300 text-sm mx-1">
                            (Website)
                          </span>
                        </div>

                        {/* <div class="flex flex-col">
                
                        
                    </div> */}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="my-1 flex  items-center flex-col">
                  <div className="flex ">
                    <div class="flex justify-center">
                      <button
                        type="button"
                        class="text-white flex hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg  font-medium rounded-lg text-sm py-2 text-center my-2"
                        onClick={handleOpen}
                        style={{ backgroundColor: secondary }}
                      >
                        <BsShareFill class="mx-2" size={18} />
                      </button>
                    </div>
                  </div>
                  {cardProducts && cardProducts.length > 0 && cardProducts[0].title && (
                    <div
                      className="p-2 my-2 w-full rounded-lg text-center text-white text-lg font-semibold flex items-center justify-center gap-2 cursor-pointer"
                      style={{ backgroundColor: secondary }}
                      onClick={handleFlip}
                    >
                      <BsBoxes /> View Products
                    </div>
                  )}
                  <p className="text-lg font-medium text-white hover:font-medium mb-2 ">
                    Scan to share
                  </p>
                  <img
                    src={qrcode}
                    class="shadow-lg w-[150px] rounded-xl h-[150px]"
                    alt="qr-code"
                  />
                  <div class="flex flex-col text-center">
                    <a href={qrcode} download class="mx-6 mt-3 flex gap-1 ">
                      <span class="text-md font-bold text-white hover:font-medium ">
                        Download QR
                      </span>
                      <BiSolidCloudDownload
                        color="white"
                        className="mt-0.5"
                        size={20}
                      />
                    </a>

                    {/* <a href={qrcode} download class="mx-6 ">
                    <span class="text-md font-bold  text-white  hover:font-medium">Share</span>
                   </a> */}
                    <div
                      class=" w-[150px] h-0.5 mt-1  mx-auto"
                      style={{ backgroundColor: secondary }}
                    ></div>
                  </div>
                </div>
                <div className="">
                  {social.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="cursor-pointer flex gap-6 justify-center py-1"
                      >
                        {Object.keys(item).map((key) => {
                          if (key === "facebook" && item[key]) {
                            const shareUrl = `https://www.facebook.com/${item[key]}`;
                            return (
                              <div
                                key={index}
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BsFacebook
                                  color={secondary}
                                  size={27}
                                  className="mt-1"
                                />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }

                          if (key === "instagram" && item[key]) {
                            const shareUrl = `https://www.instagram.com/${item[key]}`;
                            return (
                              <div
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BsInstagram
                                  color={secondary}
                                  size={24}
                                  className="mt-1.5"
                                />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }

                          if (key === "twitter" && item[key]) {
                            const shareUrl = `https://twitter.com/${item[key]}`;
                            return (
                              <div
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BsTwitter
                                  color={secondary}
                                  size={28}
                                  className="mt-0.5"
                                />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }

                          if (key === "linkedin" && item[key]) {
                            const shareUrl = `https://www.linkedin.com/in/${item[key]}`;
                            return (
                              <div
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BsLinkedin
                                  color={secondary}
                                  size={24}
                                  className="mt-1.5"
                                />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }

                          if (key === "telegram" && item[key]) {
                            const shareUrl = `https://t.me/${item[key]}`;
                            return (
                              <div
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BsTelegram color={secondary} size={30} />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }

                          if (key === "others" && item[key]) {
                            const shareUrl = `https://${item[key]}`;
                            return (
                              <div
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BiLinkExternal color={secondary} size={30} />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    );
                  })}
                </div>
                <a
                  download
                  href={
                    process.env.REACT_APP_PUBLIC_URL +
                    "vcf-file/" +
                    card.card_link
                  }
                >
                  <IoMdPersonAdd
                    color="white"
                    size={60}
                    class=" mt-5 mx-auto  p-1 rounded-lg  "
                    style={{ backgroundColor: secondary }}
                  />
                </a>

                <div
                  class="rounded mt-5"
                  style={{ backgroundColor: secondary }}
                >
                  <div class="p-5">
                    <p class="text-white text-center my-2">{card.address}</p>
                    <Link
                      className="flex items-center justify-center text-white p-2 rounded-xl font-semibold text-lg"
                      style={{ backgroundColor: primary }}
                      to={`https://www.google.com/maps?q=${card.address}`}
                      target="_blank"
                    >
                      View on Map <FaMapMarkerAlt className="mx-1" />
                    </Link>
                  </div>
                </div>
              </a>
            </div>

            {/* BACKSIDE OF THE CARD / FLIP Card */}

            <div className="container mx-auto flex my-10  max-w-lg justify-center  h-auto ">
              <div
                style={{ backgroundColor: primary }}
                className="block max-w-sm p-8  w-full text-white   border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
              >
                {cardProducts && cardProducts.length > 0 && (
                  <div
                    className="rounded w-80"
                    style={{ backgroundColor: primary }}
                  >
                    <Carousel
                      cardProducts={cardProducts}
                      secondary={secondary}
                      primary={primary}
                    />
                  </div>
                )}
                <div
                  className="p-2 my-3 rounded-lg text-center text-white text-lg font-semibold flex items-center justify-center gap-2 cursor-pointer"
                  style={{ backgroundColor: secondary }}
                  onClick={handleFlip}
                >
                  Go Back To Card Details
                </div>
              </div>
            </div>
          </ReactCardFlip>
        )}

        {/* --------------------------------------------------- */}

        {code === "2" && (
          <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <div class="container mx-auto flex  mb-10  max-w-lg justify-center mt-10 h-auto ">
              <a
                href
                class="block max-w-sm p-6  w-full  border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                style={{ backgroundColor: primary }}
              >
                <div
                  class=" w-[200px] h-[220px]   flex flex-col mx-auto mt-[-24px]"
                  style={{ backgroundColor: secondary }}
                >
                  <img
                    src={card.profile_img}
                    class="w-40 h-40 rounded-full object-cover mx-auto mt-2"
                    alt="img"
                  />

                  <p
                    class="text-xl text-white font-medium capitalize text-center
                  "
                  >
                    {" "}
                    {card.first_name + " " + card.last_name}
                  </p>
                  <div class="bg-white w-[150px] h-0.5 mt-1  mx-auto"></div>
                </div>

                <div>
                  {/* <img
                  src={card.company_logo}
                  class="w-[200px] h-[120px] mx-auto py-3"
                /> */}
                  {/* <BiInfinite color={secondary} size={100} class="mx-auto mt-[-10px]" /> */}

                  <p class="text-white capitalize text-center text-2xl mt-1 font-medium ">
                    {card.business_name}
                  </p>

                  <p class="text-md font-normal text-white uppercase text-center">
                    {card.profession}
                  </p>
                </div>
                <div class="flex flex-col mt-2">
                  <div class="mt-2">
                    <BsFillTelephoneFill
                      color={secondary}
                      size={20}
                      class="mx-auto"
                    />
                    <a href={"tel:" + card.mobile_number}>
                      <p class="text-sm text-center text-white mt-1">
                        {card.mobile_number}
                      </p>
                    </a>
                    <a href={"tel:" + card.telephone}>
                      <p class="text-sm text-center text-white ">
                        {" "}
                        {card.telephone}
                      </p>
                    </a>
                    <div
                      class=" w-[150px] h-0.5 mt-1  mx-auto"
                      style={{ backgroundColor: secondary }}
                    ></div>
                  </div>

                  <div class="mt-2">
                    <GrMail color={secondary} size={20} class="mx-auto" />
                    <a href={"mailto: " + card.email}>
                      <p class="text-sm text-center text-white mt-1">
                        {card.email}
                      </p>
                    </a>

                    <div
                      class="  w-[150px] h-0.5 mt-1  mx-auto"
                      style={{ backgroundColor: secondary }}
                    ></div>
                  </div>

                  <div class="mt-2">
                    <TbWorldWww color={secondary} size={20} class="mx-auto" />
                    <p class="text-sm text-center text-white mt-1">
                      <a
                        href={card.website_link}
                        target="_blank"
                        rel=" noreferrer"
                      >
                        {" "}
                        {card.website_link.replace(/^(https?:\/\/)/, "")}
                      </a>
                    </p>
                    <div
                      class="   w-[150px] h-0.5 mt-1  mx-auto"
                      style={{ backgroundColor: secondary }}
                    ></div>
                  </div>
                </div>

                {/* ORANGE */}
                <div class="mt-5 flex  items-center flex-col cursor-pointer">
                {cardProducts && cardProducts.length > 0 && cardProducts[0].title && (
                    <div
                      className="p-2 my-3 w-full rounded text-center text-white text-lg font-semibold flex items-center justify-center gap-2 cursor-pointer"
                      style={{ backgroundColor: secondary }}
                      onClick={handleFlip}
                    >
                      <BsBoxes /> View Products
                    </div>
                  )}
                  <div class="flex justify-center">
                    <button
                      type="button"
                      class="text-white flex items-center px-3 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg  font-medium rounded-lg text-sm py-2 text-center my-2"
                      onClick={handleOpen}
                      style={{ backgroundColor: secondary }}
                    >
                      <BsShareFill class="mr-2" size={16} />
                      Share
                    </button>
                  </div>
                  <p className="text-lg font-medium text-white hover:font-medium mb-2 ">
                    Scan to share
                  </p>
                  <img
                    src={qrcode}
                    class="shadow-lg w-[150px] rounded-xl h-[150px]"
                    alt="qr-code"
                  />

                  <div class="flex flex-col text-center">
                    <a href={qrcode} download class="mx-6 mt-3 ">
                      <span class="text-md font-bold text-white hover:font-medium ">
                        Download
                      </span>
                    </a>

                    {/* <a href={qrcode} download class="mx-6 ">
                          <span class="text-md font-bold text-white hover:font-medium">Share</span>
                        </a> */}
                    <div class="bg-white w-[150px] h-0.5 my-1  mx-auto"></div>
                  </div>
                </div>
                <div className="">
                  {social.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="cursor-pointer flex gap-6 justify-center py-2"
                      >
                        {Object.keys(item).map((key) => {
                          if (key === "facebook" && item[key]) {
                            const shareUrl = `https://www.facebook.com/${item[key]}`;
                            return (
                              <div
                                key={index}
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BsFacebook
                                  color={secondary}
                                  size={27}
                                  className="mt-1"
                                />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }

                          if (key === "instagram" && item[key]) {
                            const shareUrl = `https://www.instagram.com/${item[key]}`;
                            return (
                              <div
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BsInstagram
                                  color={secondary}
                                  size={24}
                                  className="mt-1.5"
                                />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }

                          if (key === "twitter" && item[key]) {
                            const shareUrl = `https://twitter.com/${item[key]}`;
                            return (
                              <div
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BsTwitter
                                  color={secondary}
                                  size={28}
                                  className="mt-0.5"
                                />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }

                          if (key === "linkedin" && item[key]) {
                            const shareUrl = `https://www.linkedin.com/in/${item[key]}`;
                            return (
                              <div
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BsLinkedin
                                  color={secondary}
                                  size={24}
                                  className="mt-1.5"
                                />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }

                          if (key === "telegram" && item[key]) {
                            const shareUrl = `https://t.me/${item[key]}`;
                            return (
                              <div
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BsTelegram color={secondary} size={30} />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }

                          if (key === "others" && item[key]) {
                            const shareUrl = `https://${item[key]}`;
                            return (
                              <div
                                onClick={() => window.open(shareUrl, "_blank")}
                                className="flex items-center gap-1"
                              >
                                <BiLinkExternal color={secondary} size={30} />
                                {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    );
                  })}
                </div>
                <div
                  class=" mx-auto w-10 h-10 justify-center mt-4 rounded-full "
                  style={{ backgroundColor: secondary }}
                >
                  <a
                    download
                    href={
                      process.env.REACT_APP_PUBLIC_URL +
                      "vcf-file/" +
                      card.card_link
                    }
                    class="flex"
                  >
                    <IoMdPersonAdd
                      color="white"
                      className="mt-1 px-1"
                      size={33}
                    ></IoMdPersonAdd>
                  </a>
                </div>
                <div
                  class="p-5 rounded py-2 my-3"
                  style={{ backgroundColor: secondary }}
                >
                  <p class="text-white text-center my-2">{card.address}</p>
                  <Link
                    className="flex items-center justify-center text-white p-1 rounded font-semibold text-lg"
                    style={{ backgroundColor: primary }}
                    to={`https://www.google.com/maps?q=${card.address}`}
                    target="_blank"
                  >
                    View on Map
                    <FaMapMarkerAlt className="mx-1" color="white" />
                  </Link>
                </div>
              </a>
            </div>
            {/* BACKSIDE OF THE CARD / FLIP Card */}

            <div className="container mx-auto flex my-10  max-w-lg justify-center  h-auto ">
              <div
                style={{ backgroundColor: primary }}
                className="block max-w-sm p-7  w-full text-white   border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
              >
                {cardProducts && cardProducts.length > 0 && (
                  <div
                    className="rounded sm:w-80"
                    style={{ backgroundColor: primary }}
                  >
                    <Carousel
                      cardProducts={cardProducts}
                      secondary={secondary}
                      primary={primary}
                    />
                  </div>
                )}
                <div
                  className="p-2 my-3 rounded-lg text-center text-white text-lg font-semibold flex items-center justify-center gap-2 cursor-pointer"
                  style={{ backgroundColor: secondary }}
                  onClick={handleFlip}
                >
                  Go Back To Card Details
                </div>
              </div>
            </div>
          </ReactCardFlip>
        )}

        {/* TEMPLATE 4 (THEME WHITE & VOILET-BLUE) */}
        {code === "3" && (
          <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <div
              class="max-w-sm   border container mb-10  mx-auto mt-10 h-auto rounded-lg border-gray-200  shadow dark:bg-gray-800 dark:border-gray-700"
              style={{ backgroundColor: primary }}
            >
              <div
                class="  flex justify-center relative h-[150px] rounded-lg "
                style={{ backgroundColor: secondary }}
              >
                <h1
                  class="text-center text-white text-3xl mt-3 font-medium font-sans capitalize
                  "
                >
                  {card.business_name}
                </h1>
              </div>

              <div
                class="relative mt-[-80px] mx-auto rounded-full w-40 h-40"
                style={{ backgroundColor: primary }}
              >
                <img
                  src={card.profile_img}
                  class="w-40 h-40   shadow-xl  rounded-full object-cover absolute "
                  alt="img"
                />
              </div>

              <div
                class="w-[110px] h-[5px] mt-5 flex mx-auto "
                style={{ backgroundColor: secondary }}
              ></div>

              <div>
                <h1 class="text-center  text-gray-900 mt-2 font-medium text-3xl capitalize">
                  {card.first_name + " " + card.last_name}
                </h1>
                <span class="flex justify-center text-md text-gray-500 font-medium">
                  {card.profession}
                </span>
              </div>

              <div className="w-full py-2">
                <div class="flex  gap-3 mx-6   my-1 ">
                  <button
                    class="rounded-full flex justify-center w-8 h-8"
                    style={{ backgroundColor: secondary }}
                  >
                    <BsTelephoneFill color={primary} size={18} class="mt-1.5" />
                  </button>
                  <div className="flex space-x-32">
                    <a href={"tel:" + card.mobile_number}>
                      <span class="font-medium mt-1">{card.mobile_number}</span>
                    </a>
                  </div>
                </div>

                <div class="flex  gap-3 mx-6   mt-3 ">
                  <button
                    class="rounded-full flex justify-center w-8 h-8"
                    style={{ backgroundColor: secondary }}
                  >
                    <GrMail color={primary} size={18} class="mt-1.5" />
                  </button>
                  <a href={"mailto: " + card.email}>
                    <span class="font-medium mt-1">{card.email}</span>
                  </a>
                </div>

                <div class="flex  gap-3 mx-6   mt-3 ">
                  <button
                    class="rounded-full flex justify-center w-8 h-8 "
                    style={{ backgroundColor: secondary }}
                  >
                    <TbWorldWww color={primary} size={18} class="mt-1.5" />
                  </button>

                  <p class="font-medium xmt-1">
                    <a
                      href={card.website_link}
                      target="_blank"
                      rel=" noreferrer"
                    >
                      {" "}
                      {card.website_link.replace(/^(https?:\/\/)/, "")}
                    </a>
                  </p>
                </div>

                {cardProducts && cardProducts.length > 0 && cardProducts[0].title && (
                  <div
                    className="p-2 my-3 w-11/12 mx-auto rounded text-center text-white text-lg font-semibold flex items-center justify-center gap-2 cursor-pointer"
                    style={{ backgroundColor: secondary }}
                    onClick={handleFlip}
                  >
                    <BsBoxes /> View Products
                  </div>
                )}
              </div>
              <div class="flex justify-center">
                <button
                  type="button"
                  class="text-white flex items-center px-2 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg  font-medium rounded-lg text-sm py-2 text-center my-2"
                  onClick={handleOpen}
                  style={{ backgroundColor: secondary }}
                >
                  <BsShareFill class="mr-2" size={16} />
                  Share
                </button>
              </div>
              <div class=" flex  items-center flex-col">
                <p className="text-lg font-medium hover:font-medium ">
                  Scan to share
                </p>
                <img
                  src={qrcode}
                  class="shadow-lg w-[150px]  h-[150px] rounded"
                  alt="qr-code"
                />
              </div>

              <div class="flex flex-col text-center">
                <a href={qrcode} download class="mx-6 mt-1 ">
                  <span
                    class="text-md font-bold  hover:font-medium "
                    style={{ color: secondary }}
                  >
                    <u>Download</u>
                  </span>
                </a>
              </div>
              <div className="">
                {social.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="cursor-pointer flex gap-6 justify-center py-1"
                    >
                      {Object.keys(item).map((key) => {
                        if (key === "facebook" && item[key]) {
                          const shareUrl = `https://www.facebook.com/${item[key]}`;
                          return (
                            <div
                              key={index}
                              onClick={() => window.open(shareUrl, "_blank")}
                              className="flex items-center gap-1"
                            >
                              <BsFacebook
                                color={secondary}
                                size={27}
                                className="mt-1"
                              />
                              {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                            </div>
                          );
                        }

                        if (key === "instagram" && item[key]) {
                          const shareUrl = `https://www.instagram.com/${item[key]}`;
                          return (
                            <div
                              onClick={() => window.open(shareUrl, "_blank")}
                              className="flex items-center gap-1"
                            >
                              <BsInstagram
                                color={secondary}
                                size={24}
                                className="mt-1.5"
                              />
                              {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                            </div>
                          );
                        }

                        if (key === "twitter" && item[key]) {
                          const shareUrl = `https://twitter.com/${item[key]}`;
                          return (
                            <div
                              onClick={() => window.open(shareUrl, "_blank")}
                              className="flex items-center gap-1"
                            >
                              <BsTwitter
                                color={secondary}
                                size={28}
                                className="mt-0.5"
                              />
                              {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                            </div>
                          );
                        }

                        if (key === "linkedin" && item[key]) {
                          const shareUrl = `https://www.linkedin.com/in/${item[key]}`;
                          return (
                            <div
                              onClick={() => window.open(shareUrl, "_blank")}
                              className="flex items-center gap-1"
                            >
                              <BsLinkedin
                                color={secondary}
                                size={24}
                                className="mt-1.5"
                              />
                              {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                            </div>
                          );
                        }

                        if (key === "telegram" && item[key]) {
                          const shareUrl = `https://t.me/${item[key]}`;
                          return (
                            <div
                              onClick={() => window.open(shareUrl, "_blank")}
                              className="flex items-center gap-1"
                            >
                              <BsTelegram color={secondary} size={30} />
                              {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                            </div>
                          );
                        }

                        if (key === "others" && item[key]) {
                          const shareUrl = `https://${item[key]}`;
                          return (
                            <div
                              onClick={() => window.open(shareUrl, "_blank")}
                              className="flex items-center gap-1"
                            >
                              <BiLinkExternal color={secondary} size={30} />
                              {/* <span className="text-sm text-gray-600">
                                {item[key]}
                              </span> */}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  );
                })}
              </div>
              <div
                class=" mt-4  justify-center relative h-auto rounded-lg "
                style={{ backgroundColor: secondary }}
              >
                <div class="flex justify-center">
                  <a
                    class="flex"
                    download
                    href={
                      process.env.REACT_APP_PUBLIC_URL +
                      "vcf-file/" +
                      card.card_link
                    }
                  >
                    <BsPersonFillAdd color={primary} class="mt-2" size={36} />
                    <span class="text-white  font-bold mt-4 mx-3">
                      Add to Contacts
                    </span>
                  </a>
                </div>
                <div class="p-5">
                  <p class="text-white text-center my-2">{card.address}</p>
                  <Link
                    className="flex items-center justify-center p-2 rounded font-semibold text-lg"
                    style={{ backgroundColor: primary }}
                    to={`https://www.google.com/maps?q=${card.address}`}
                    target="_blank"
                  >
                    View on Map
                    <FaMapMarkerAlt className="mx-1" color={secondary} />
                  </Link>
                </div>
              </div>
              <Modal
                isOpen={open}
                onRequestClose={handleClose}
                className="modal"
                overlayclassName="overlay"
              >
                <div class="flex flex-col">
                  <span class="text-center text-2xl text-gray-900 font-medium">
                    Share Profile
                  </span>

                  <span class="text-justify text-gray-600 mt-2">
                    Share Digital VCard in your network.
                  </span>

                  <div class="flex flex-row mt-5 gap-3 justify-center">
                    <a
                      href={
                        `mailto:?subject=" + card.first_name + " " + card.last_name + " digital card&body=${process.env.REACT_APP_WEB_URL}shared-card/` +
                        params.id
                      }
                      target="blank"
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png"
                        width={26}
                        class="mt-1 hover:cursor-pointer"
                        alt=""
                      />
                    </a>

                    <div
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/sharer/sharer.php?u=https://cardz.visiontrek.in/shared-card/" +
                            params.id,
                          "_blank"
                        )
                      }
                    >
                      <img
                        src="https://png.pngtree.com/element_our/png_detail/20181011/facebook-social-media-icon-design-template-vector-png_126986.png"
                        width={27}
                      />
                    </div>

                    <a
                      href={
                        `https://www.twitter.com/share?url=${process.env.REACT_APP_WEB_URL}shared-card/` +
                        params.id
                      }
                      target="blank"
                    >
                      <BsTwitter
                        color="#00A7E6"
                        class="hover:cursor-pointer"
                        size={27}
                      />
                    </a>

                    <a
                      href={
                        "https://www.linkedin.com/shareArticle?mini=true&url=https%3A//cardz.visiontrek.in/shared-card/" +
                        params.id +
                        "&title=" +
                        card.first_name +
                        "%20card&summary=this%20is%20create%20by%20me&source=d"
                      }
                      target="blank"
                    >
                      <BsLinkedin
                        color="#0A63BC"
                        size={24}
                        class="mt-0.5 hover:cursor-pointer"
                      />
                    </a>

                    <div
                      onClick={() =>
                        window.open(
                          `https://api.whatsapp.com/send?text=${process.env.REACT_APP_WEB_URL}shared-card/` +
                            params.id,
                          "_blank"
                        )
                      }
                    >
                      <IoLogoWhatsapp
                        color="#3FE05C"
                        class="hover:cursor-pointer"
                        size={29}
                      />
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            {/* BACKSIDE OF THE CARD / FLIP Card */}

            <div className="container mx-auto flex my-10  max-w-lg justify-center  h-auto ">
              <div
                style={{ backgroundColor: primary }}
                className="block max-w-sm p-8  w-full text-white   border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
              >
                {cardProducts && (
                  <div
                    className="rounded w-80"
                    style={{ backgroundColor: primary }}
                  >
                    <Carousel
                      cardProducts={cardProducts}
                      secondary={secondary}
                      primary={primary}
                    />
                  </div>
                )}
                <div
                  className="p-2 my-3 rounded-lg text-center text-white text-lg font-semibold flex items-center justify-center gap-2 cursor-pointer"
                  style={{ backgroundColor: secondary }}
                  onClick={handleFlip}
                >
                  Go Back To Card Details
                </div>
              </div>
            </div>
          </ReactCardFlip>
        )}

        <Modal
          isOpen={wopen}
          onRequestClose={whandleClose}
          className="modal"
          overlayclassName="overlay"
        >
          <div>
            <input
              id="message"
              rows="4"
              class="block p-1 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border outline-none border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Your Name"
              onInput={(e) => setName(e.target.value)}
            />
            <div class="mt-5">
              <button
                class="text-white mt-4 bg-blue-700 mx-2 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 shadow-md"
                onClick={() =>
                  window.open(
                    "sms:" +
                      card.mobile_number +
                      "?body=Hi My name is " +
                      name +
                      " I got connected to you via your cardz visiontrek Card. Please respond back. Thank you",
                    "_blank"
                  )
                }
              >
                Text
              </button>

              <button
                type="submit"
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?text= Hi My name is " +
                      name +
                      " I got connected to you via your cardz visiontrek Card. Please respond back. Thank you",
                    "_blank"
                  )
                }
                class="text-white mt-4 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 shadow-md"
              >
                WhatsApp
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={open}
          onRequestClose={handleClose}
          className="modal"
          overlayclassName="overlay"
        >
          <div class="flex flex-col">
            <span class="text-center text-2xl text-gray-900 font-medium">
              Share Profile
            </span>

            <span class="text-justify text-gray-600 mt-2">
              Share Digital VCard in your network.
            </span>

            <div class="flex flex-row mt-5 gap-2 justify-center">
              <a
                href={
                  "mailto:?subject=" +
                  card.first_name +
                  " " +
                  card.last_name +
                  " digital card&body=https://cardz.visiontrek.in/shared-card/" +
                  params.id
                }
                target="blank"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png"
                  width={26}
                  class="mt-2 hover:cursor-pointer"
                />
              </a>
              <div
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?text=https://cardz.visiontrek.in/shared-card/" +
                      params.id,
                    "_blank"
                  )
                }
              >
                <IoLogoWhatsapp
                  color="#3FE05C"
                  class="hover:cursor-pointer mt-1"
                  size={29}
                />
              </div>

              {/* 
              <div
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/sharer/sharer.php?u=https://cardz.visiontrek.in/shared-card/" +
                      params.id,
                    "_blank"
                  )
                }
              >
                <img
                  src="https://png.pngtree.com/element_our/png_detail/20181011/facebook-social-media-icon-design-template-vector-png_126986.png"
                  width={27}
                />
              </div> */}

              {/* <a
                href={
                  "https://www.twitter.com/share?url=https://cardz.visiontrek.in/shared-card/" +
                  params.id
                }
                target="blank"
              >
                <BsTwitter
                  color="#00A7E6"
                  class="hover:cursor-pointer"
                  size={27}
                />
              </a> */}

              {/* <a
                href={
                  "https://www.linkedin.com/shareArticle?mini=true&url=https%3A//cardz.visiontrek.in/shared-card/" +
                  params.id +
                  "&title=" +
                  card.first_name +
                  "%20card&summary=this%20is%20create%20by%20me&source=d"
                }
                target="blank"
              >
                <BsLinkedin
                  color="#0A63BC"
                  size={24}
                  class="mt-0.5 hover:cursor-pointer"
                />
              </a> */}
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div key={""}>
      <div>{cardData.map(cardhandle)}</div>
    </div>
  );
};

export default CardShare;
