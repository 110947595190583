import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../Loading";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const CreatePassword = () => {
  const [error, seterror] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordNewVisible, setPasswordNewVisible] = useState(false);

  const handleTogglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleToggleNewPassword = () => {
    setPasswordNewVisible(!passwordNewVisible);
  };

  const url = process.env.REACT_APP_PUBLIC_URL;

  const createpassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    const fromdata = new FormData(e.currentTarget);
    const password = fromdata.get("password");
    const cpassword = fromdata.get("cpassword");
    const user_key = window.sessionStorage.getItem("user_key");

    // Check if the password and cpassword match
    if (password !== cpassword) {
      toast.error("Passwords do not match!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // Check password length
    if (password.length < 8 || password.length > 16) {
      toast.error("Password must be 8-16 characters long!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const data = {
      password: password,
      cpassword: cpassword,
      user_key: user_key,
    };

    try {
      await axios.post(url + "create-password/", data);
      setLoading(false);
      toast.warning("Password Changed Successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      seterror(error.response.data);
      setLoading(false);
      console.log(error.response.data);
    }
  };

  const [otpsend, setotpsend] = useState(false);

  const resend = () => {
    setLoading(true);
    axios
      .get(url + "resend/" + window.sessionStorage.getItem("user_key"))
      .then((response) => {
        setotpsend(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div>
      {loading ? <Loading class="flex justify-center" /> : ""}

      <div class="container mx-auto h-[370px] justify-center  flex mt-10">
        <a
          href="#"
          class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-lg  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
        >
          <h5 class="mb-2 text-2xl text-center font-bold tracking-tight text-blue-600 dark:text-white">
            OTP Verified
          </h5>
          <p class="font-normal text-center text-lg text-gray-500 dark:text-gray-400">
            Create your new password
          </p>

          <form onSubmit={(e) => createpassword(e)}>
            <div class="flex">
              <input
                id="email"
                type={passwordVisible ? "text" : "password"}
                name="password"
                class="block py-2.5 px-0 w-full mt-5 text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder="New Password"
                required
                pattern=".{8,}"
              />

              {passwordVisible ? (
                <AiFillEye
                  size={20}
                  color="blue"
                  class="mt-9 "
                  onClick={() => handleTogglePassword()}
                >
                  {passwordVisible ? "hide" : "show"}
                </AiFillEye>
              ) : (
                <AiFillEyeInvisible
                  size={20}
                  color="blue"
                  class="mt-9 "
                  onClick={() => handleTogglePassword()}
                >
                  {passwordVisible ? "hide" : "show"}
                </AiFillEyeInvisible>
              )}
            </div>

            <div class="flex mt-3">
              <input
                id="password"
                type={passwordNewVisible ? "text" : "password"}
                name="cpassword"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder="Confirm Password"
                required
              />

              {passwordNewVisible ? (
                <AiFillEye
                  size={20}
                  color="blue"
                  class="mt-4 "
                  onClick={() => handleToggleNewPassword()}
                >
                  {passwordNewVisible ? "hide" : "show"}
                </AiFillEye>
              ) : (
                <AiFillEyeInvisible
                  size={20}
                  color="blue"
                  class="mt-4 "
                  onClick={() => handleToggleNewPassword()}
                >
                  {passwordNewVisible ? "hide" : "show"}
                </AiFillEyeInvisible>
              )}
            </div>

            {error.error ? <p>{error.error}</p> : ""}

            <button
              type="submit"
              onClick={resend}
              class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
          <div class="mt-3">
            <Link to="/login" class="text-blue-600">
              Go to Signin Page
            </Link>
          </div>
        </a>
      </div>
      <ToastContainer />

      {/* <div class="d-none">
                           <span>Otp verified</span>
                           <p>Create your new password</p>


                           <form onSubmit={(e)=>createpassword(e)}>
                           <input  id="email" name="password" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="" required />

                           <input  id="email" name="cpassword" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="" required />

                           {error.error?<p>
                            {error.error}
                           </p>:""}

                           <button type="submit" onClick={resend} class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                           
                           Submit</button>
                       
                           
                           </form>
                           <Link to='/login'>Go to Signin Page</   Link>
                         </div> */}
    </div>
  );
};

export default CreatePassword;
