import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

const Yesterday = () => {
  let access_token = localStorage.getItem("access_token");
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}analytics/device-data/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        const yesterdayData = groupDataByYesterday(response.data);
        const chartData = calculateTotalViewsPerHour(yesterdayData);

        // Set the chartData state
        setChartData(chartData);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchData();
  }, []);

  const groupDataByYesterday = (data) => {
    const currentTime = new Date();
    const yesterday = new Date();
    yesterday.setDate(currentTime.getDate() - 1); // For yesterday

    return data.filter((item) => {
      const timestamp = new Date(item.timestamp);
      return (
        timestamp.getDate() === yesterday.getDate() &&
        timestamp.getMonth() === yesterday.getMonth() &&
        timestamp.getFullYear() === yesterday.getFullYear()
      );
    });
  };

  const calculateTotalViewsPerHour = (data) => {
    const chartData = [];

    for (let i = 0; i <= 23; i++) {
      const Views = data.filter((item) => {
        const timestamp = new Date(item.timestamp);
        return timestamp.getHours() === i;
      }).length;

      chartData.push({
        hour: i,
        Views: Views,
      });
    }

    return chartData;
  };

  return (
    <div className="-ml-9 md:p-5 p-1 py-2 h-[220px] sm:h-[300px] lg:h-[350px]">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={chartData}>
          <XAxis dataKey="hour" interval={1} />
          <YAxis />
          <Tooltip />
          <Legend wrapperStyle={{ left: 30 }} />
          <Area
            type="monotone"
            dataKey="Views"
            stroke="#a0e030"
            fill="#a0e030"
            stackId="stack"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Yesterday;
