import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const UserChartThisWeek = () => {
  const access_token = localStorage.getItem("access_token");
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        setChartData(response.data);
        console.log(response.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, [access_token]);

  const currentDate = new Date();
  const last7Days = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() - i);
    return date;
  });

  const UsersAndCardCountByDay = last7Days.map((date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const formattedDate = date.toISOString().split("T")[0];

    const usersJoined = chartData.filter(
      (item) => item.date_joined.split("T")[0] === formattedDate
    );
    return {
      day: `${day}-${month}`,
      Users: usersJoined.length,
    };
  });

  return (
    <div className="-ml-9 p-5">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={UsersAndCardCountByDay}>
          <XAxis dataKey="day" reversed />
          <YAxis />
          <Tooltip />
          <Legend wrapperStyle={{ left: 30 }} />
          <Bar dataKey="Users" fill="#a0e030" stackId="stack" />
          {/* <Bar dataKey="cardCount" fill="#3182CE" stackId="stack" /> */}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UserChartThisWeek;
