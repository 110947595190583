import React, { useState } from "react";
import Navbar from "../Navbar";
import gif from "../images/gif.gif";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useRegisterUserMutation } from "../../services/UserApi";
import Footer from "../Footer";
import axios from "axios";
import Loading from "../Loading";
import { ToastContainer, toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Modal from "react-modal";
import "../Card/Modal.css";
import { RxCrossCircled, RxRotateCounterClockwise } from "react-icons/rx";
import { BsCheckCircle, BsFillCheckCircleFill } from "react-icons/bs";
import { TbRotateDot, TbRotate, TbRotateClockwise } from "react-icons/tb";
import AvatarEditor from "react-avatar-editor";
Modal.setAppElement("#root");

const Signup = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const url = process.env.REACT_APP_PUBLIC_URL + "register/";

  const handleToggleNewPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleToggleConfirmPassword = () => {
    setConfirmPassword(!confirmPassword);
  };
  const [servererror, seterror] = useState({});

  const [registerUser] = useRegisterUserMutation();

  const navigate = useNavigate();
  const [loading, setloading] = useState({ unload: true });
  const handleProfileChange = (event) => {
    const imageFile = event.target.files[0];
    const imageFilename = event.target.files.name;

    if (!imageFile) {
      toast.warning("Please select an image first.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif|jfif|webp)$/)) {
      setInvalidImage("Please select a valid image (JPG, JPEG, PNG).");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      setUserInfo({
        ...userInfo,
        filepreview: e.target.result,
      });

      setInvalidImage(null);
    };

    reader.readAsDataURL(imageFile);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);
    const actualdata = {
      first_name: data.get("first_name"),
      last_name: data.get("last_name"),
      email: data.get("email"),
      password: data.get("password"),
      password2: data.get("cpassword"),
      profile_img: userInfo.file,
      mobile_number: data.get("mobile"),
    };

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    console.log("login data", actualdata);
    const errotlist = {};

    if (actualdata.first_name === "") {
      errotlist["first_name"] = "firstname required field";
    }

    if (actualdata.last_name === "") {
      errotlist["last_name"] = "last_name required field";
    }

    if (actualdata.email === "") {
      errotlist["email"] = "email required field";
    }

    if (actualdata.password === "") {
      errotlist["password"] = "password required field";
    }

    if (actualdata.password2 === "") {
      errotlist["password2"] = "conform password required field";
    }

    if (Object.keys(errotlist).length !== 0) {
      console.log("here");
      seterror(errotlist);
    } else {
      setloading({ load: true });
      const result = await axios.post(url, actualdata, config);

      if (result.error) {
        console.log(result.error.data);
        seterror(result.error.data);
        setloading({ unload: true });
      } else if (result.data) {
        window.sessionStorage.setItem("user_key", result.data["user_key"]);
        setloading({ unload: true });
        toast.warning("OTP Sent Successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigate("/verify");
        }, 3000);
        // navigate('/verify')
      }
    }
  };

  const [invalidImage, setInvalidImage] = useState(null);
  const [userInfo, setUserInfo] = useState({
    file: [],
    filepreview: null,
  });
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);
  const [editor, setEditor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRotate = () => {
    // Increment the rotation angle by 90 degrees (you can adjust this value)
    setRotate((prevRotate) => prevRotate + 90);
  };

  const handleCrop = () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      canvas.toBlob(
        (blob) => {
          const file = new File([blob], "profile.jpg", {
            type: "image/jpeg",
            lastModified: Date.now(),
          });
          setUserInfo({
            ...userInfo,
            file: file,
          });
        },
        "image/jpeg",
        1
      );
    }
    setIsModalOpen(false); // Close the modal after cropping
  };

  const [formErrors, setFormErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    password: "",
    cpassword: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const errors = { ...formErrors };
    if (name === "first_name") {
      if (!value) {
        errors.first_name = "*first name is required";
      } else {
        errors.first_name = "";
      }
    }
    if (name === "last_name") {
      if (!value) {
        errors.last_name = "*last name is required";
      } else {
        errors.last_name = "";
      }
    }
    if (name === "email") {
      if (!value) {
        errors.email = "*Provide Your Email";
      } else {
        errors.email = "";
      }
    }
    if (name === "mobile") {
      if (!value) {
        errors.mobile = "*Provide Your Mobile Number";
      } else {
        errors.mobile = "";
      }
    }
    if (name === "password") {
      if (!value) {
        errors.password = "*Set Your Password";
      } else {
        errors.password = "";
      }
    }
    if (name === "cpassword") {
      if (!value) {
        errors.cpassword = "*Confirm Password";
      } else {
        errors.cpassword = "";
      }
    }

    setFormErrors(errors);
  };

  return (
    <>
      <Navbar />

      {loading.load ? <Loading className="flex justify-center" /> : ""}

      <a
        href="#"
        className="flex flex-col  h-auto mt-10 mb-10 items-center mx-auto bg-white border rounded-lg shadow-lg md:flex-row md:max-w-[700px] "
      >
        <div className="flex flex-col justify-between mx-5 p-4 leading-normal">
          {/* encType="multipart/form-data"  */}
          <form onSubmit={(e) => handlesubmit(e)} encType="multipart/form-data">
            <div className="grid md:grid-cols-2 md:gap-6 ">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="first_name"
                  id="floating_first_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  onChange={(e) => handleInputChange(e)}
                />
                {formErrors.first_name && (
                  <div className="text-red-500 text-xs">
                    {formErrors.first_name}
                  </div>
                )}
                <label
                  for="floating_first_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  First name
                </label>
              </div>

              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="last_name"
                  id="floating_last_name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  onChange={(e) => handleInputChange(e)}
                />
                {formErrors.last_name && (
                  <div className="text-red-500 text-xs">
                    {formErrors.last_name}
                  </div>
                )}
                <label
                  for="floating_last_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Last name
                </label>
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6 ">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="email"
                  name="email"
                  id="floating_email"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  onChange={(e) => handleInputChange(e)}
                />
                {formErrors.email && (
                  <div className="text-red-500 text-xs">{formErrors.email}</div>
                )}
                <label
                  for="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Email address
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="mobile"
                  id="floating_phone"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  maxLength={10}
                  pattern=".{10,}"
                  onChange={(e) => handleInputChange(e)}
                />
                {formErrors.mobile && (
                  <div className="text-red-500 text-xs">
                    {formErrors.mobile}
                  </div>
                )}
                <label
                  for="floating_phone"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Mobile Number{" "}
                </label>
              </div>
            </div>
            <div>
              <div className="relative z-0 w-full group">
                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75  origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 md:text-lg">
                  Upload Profile Image
                </label>

                <input
                  name="profile"
                  className="block w-full mt-5 px-2 pt-1 pb-1 text-xs text-gray-900 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                  id="small_size"
                  type="file"
                  required
                  accept="image/png, image/jpeg"
                  onChange={handleProfileChange}
                  onClick={() => setIsModalOpen(true)}
                />
              </div>

              {invalidImage && (
                <div className="text-red-500">{invalidImage}</div>
              )}

              <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Image Editor"
                className="fixed inset-0 flex items-center justify-center"
              >
                <div className="bg-white p-4 rounded-lg shadow-md max-w-md w-full">
                  <AvatarEditor
                    ref={(editor) => setEditor(editor)}
                    image={userInfo.filepreview}
                    width={250}
                    height={250}
                    border={50}
                    borderRadius={1000}
                    color={[1, 1, 1, 0.6]}
                    scale={scale}
                    rotate={rotate}
                    className="mx-auto"
                  />

                  <div className="mt-4">
                    <input
                      type="range"
                      min="1"
                      max="2"
                      step="0.01"
                      value={scale}
                      onChange={(e) => setScale(parseFloat(e.target.value))}
                      className="w-full"
                    />
                  </div>

                  <div className="mt-4 flex gap-3 justify-center">
                    <BsFillCheckCircleFill
                      color="green"
                      onClick={handleCrop}
                      size={27}
                    />
                    <TbRotateClockwise
                      color="white"
                      size={27}
                      onClick={handleRotate}
                      className="bg-gray-500 p-0.5 rounded-full"
                    />

                    <RxCrossCircled
                      color="white"
                      size={27}
                      className="bg-red-600 p-0.5 rounded-full"
                      onClick={() => setIsModalOpen(false)}
                    />
                  </div>
                </div>
              </Modal>
            </div>

            <div className="relative z-0 w-full mb-6 group">
              <div className="flex">
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  id="floating_password"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  onChange={(e) => handleInputChange(e)}
                />

                {passwordVisible ? (
                  <AiFillEye
                    size={20}
                    color="blue"
                    className="mt-9 "
                    onClick={() => handleToggleNewPassword()}
                  >
                    {passwordVisible ? "hide" : "show"}
                  </AiFillEye>
                ) : (
                  <AiFillEyeInvisible
                    size={20}
                    color="blue"
                    className="mt-9 "
                    onClick={() => handleToggleNewPassword()}
                  >
                    {passwordVisible ? "hide" : "show"}
                  </AiFillEyeInvisible>
                )}

                {/* <AiFillEye size={20} color="blue" className="mt-9 " onClick={() => handleToggleNewPassword()}>
                    {passwordVisible ? 'hide' : 'show'}
                        </AiFillEye> */}
                <label
                  for="floating_password"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Password
                </label>
              </div>
              {formErrors.password && (
                <div className="text-red-500 text-xs">
                  {formErrors.password}
                </div>
              )}
            </div>

            <div className="relative z-0 w-full mb-6 group">
              <div className="flex">
                <input
                  type={confirmPassword ? "text" : "password"}
                  name="cpassword"
                  id="floating_repeat_password"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  onChange={(e) => handleInputChange(e)}
                />

                {confirmPassword ? (
                  <AiFillEye
                    size={20}
                    color="blue"
                    className="mt-9 "
                    onClick={() => handleToggleConfirmPassword()}
                  >
                    {confirmPassword ? "hide" : "show"}
                  </AiFillEye>
                ) : (
                  <AiFillEyeInvisible
                    size={20}
                    color="blue"
                    className="mt-9 "
                    onClick={() => handleToggleConfirmPassword()}
                  >
                    {confirmPassword ? "hide" : "show"}
                  </AiFillEyeInvisible>
                )}

                <label
                  for="floating_repeat_password"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Confirm password
                </label>
              </div>
              {formErrors.cpassword && (
                <div className="text-red-500 text-xs">
                  {formErrors.cpassword}
                </div>
              )}
            </div>

            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Register
            </button>
            <div className="text-gray-700 mt-3">
              <span>
                Already have an account ?{" "}
                <Link to="/login" className="text-blue-800 font-medium">
                  Sign In
                </Link>
              </span>
            </div>
          </form>
        </div>
        <img
          className="object-cover hidden md:block w-full rounded-t-lg h-96 md:h-auto
         md:w-[250px] md:rounded-none md:rounded-l-lg "
          src={gif}
          alt=""
        />
      </a>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default Signup;
