import React, { useState, useEffect } from "react";
import { BsFillTelephoneFill, BsShareFill, BsWhatsapp } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { BiInfinite } from "react-icons/bi";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";
import QRCode from "qrcode";
import { Helmet } from "react-helmet";
import { TbArrowsExchange2 } from "react-icons/tb";
import { GetToken } from "../../services/localstorage";
import { ChromePicker } from "react-color";
import { ToastContainer, toast } from "react-toastify";

const Template1 = ({ data }) => {
  const [primary, setPrimary] = useState("#E1D7CD");
  const [secondary, setSecondary] = useState("#333132");
  const [isopen, setIsopen] = useState(false);
  const [isopenPri, setIsopenPri] = useState(false);
  const { access_token } = GetToken();
  const [i, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleColorPrimary = (selectedColor) => {
    setPrimary(selectedColor.hex);
    console.log(primary);
  };

  const handleColorSecondary = (selectedColor) => {
    setSecondary(selectedColor.hex);
    console.log(secondary);
  };

  const params = useParams();

  const [qrcode, setQr] = useState("");

  const url =
    process.env.REACT_APP_PUBLIC_URL + "card-customise/" + `?id=${params.link}`;
  //  console.log("--------",data[0].card_link)
  const config = {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  };

  const id1 = async () => {
    const newId = data[0].id;
    setId(newId);
    console.log("-----------------------", i);
  };
  useEffect(() => {
    id1();
  }, [data]);

  const handleClick = (e) => {
    e.preventDefault();
    const actualdata = {
      card: i,
      card_color_primary: primary,
      card_color_sec: secondary,
      template_code: params.id,
    };
    axios.post(url, actualdata, config).then((response) => {
      toast.success("Yay! Card created!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate(`/shared-card/${params.link}`);
      }, 3000);
      console.log("posted data", actualdata);
    });
  };
  useEffect(() => {
    QRCode.toDataURL(
      process.env.REACT_APP_PUBLIC_URL + "view-card/" + params.id
    ).then((data) => {
      setQr(data);
    });
  }, []);

  return (
    <div
      class="flex flex-col max-w-sm h-auto md:flex-row justify-center
        mx-auto gap-5"
    >
      <div class="text-center">
        <div class="flex-col max-w-sm md:w-[300px] rounded-lg mt-5 mx-auto border shadow-lg mb-3  md:mt-[200px]">
          <h1 class="text-center text-xl font-medium text-gray-700 p-2">
            Customize Theme For Your Business Card
          </h1>
          <div className="flex justify-center items-center gap-2">
            <button
              class="border p-2  shadow-lg rounded-md font-medium"
              style={{ backgroundColor: secondary, color: "white" }}
              onClick={() => setIsopen(!isopen)}
            >
              Primary Color
            </button>
            <br />

            <button
              class="border p-2 mt-3 mb-2 rounded-md shadow-lg font-medium"
              style={{ backgroundColor: primary, color: "white" }}
              onClick={() => setIsopenPri(!isopenPri)}
            >
              Secondary Color
            </button>
          </div>
          {isopen ? (
            <span
              onMouseLeave={() => setIsopen(!isopen)}
              className="absolute z-50 -translate-x-44"
            >
              <ChromePicker color={secondary} onChange={handleColorSecondary} />
            </span>
          ) : (
            ""
          )}
          {isopenPri ? (
            <span
              onMouseLeave={() => setIsopenPri(!isopenPri)}
              className="absolute z-50 -translate-x-44"
            >
              <ChromePicker color={primary} onChange={handleColorPrimary} />
            </span>
          ) : (
            ""
          )}

          <form onSubmit={(e) => handleClick(e)}>
            <button class="text-white bg-blue-500 h-10 mt-3 px-5 hover:bg-[#13CAFD]  shadow-lg rounded text-lg font-medium mx-auto mb-3">
              Save
            </button>
          </form>
        </div>
      </div>

      <div>
        {data.map((item, index) => (
          <>
            <Helmet>
              <title class="capi">
                {item.first_name + " " + item.last_name}-Profile
              </title>
              <meta property="og:image" content={item.profile_img} />
              <meta
                property="og:title"
                content="create a bussiness digital card"
              />
              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content={
                  process.env.REACT_APP_PUBLIC_URL + "view-card/" + params.id
                }
              />
            </Helmet>
            <h1 className="text-center text-xl sm:hidden font-semibold ">
              <u>Live Card Preview</u>
            </h1>
            <div class="container mx-auto flex  shadow-lg rounded-md  max-w-sm justify-center border  mt-2 md:mt-10 h-auto ">
              <div
                class=" w-full h-[250px] mb-10 relative"
                style={{ backgroundColor: primary }}
              >
                <div
                  class=" w-[280px] h-[40px] rounded-b-2xl mx-auto"
                  style={{ backgroundColor: secondary }}
                >
                  {/* <BiInfinite color='white' size={90} class="mx-auto" /> */}

                  <p class="text-center capitalize text-white mt-2 text-xl font-medium">
                    {item.business_name}
                  </p>
                </div>
                <p
                  class="text-center mt-7 uppercase   text-2xl font-bold"
                  style={{ color: secondary }}
                >
                  {item.first_name + " " + item.last_name}
                </p>
                <p class="text-center uppercase">{item.profession}</p>
                <img
                  src={item.profile_img}
                  class="w-[180px] h-[180px] object-cover mx-auto mt-3"
                />
                <div
                  class=" flex flex-col w-[120px] h-1 mt-1 mx-auto"
                  style={{ backgroundColor: secondary }}
                ></div>

                <a
                  href="#"
                  class="block max-w-sm p-6 bg-white  hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                >
                  <div class="flex">
                    <div
                      class="w-7 h-[200px] mx-5 "
                      style={{ backgroundColor: secondary }}
                    >
                      <BsFillTelephoneFill
                        color={primary}
                        size={18}
                        class="mx-auto mt-7"
                      />
                      <GrMail
                        color={primary}
                        size={18}
                        class="mx-auto mt-[40px]"
                      />
                      <BsShareFill
                        color={primary}
                        size={18}
                        class="mx-auto mt-[30px]"
                      />
                    </div>
                    <div class="flex flex-col">
                      <div>
                        <div class="mt-3">
                          <p>{item.mobile_number}</p>
                          <p>{item.mobile_number}</p>
                          <div
                            class=" w-[120px] h-1 mt-2"
                            style={{ backgroundColor: primary }}
                          ></div>
                        </div>
                      </div>

                      <div>
                        <div class="mt-3">
                          <p>{item.email}</p>

                          <div
                            class=" w-[120px] h-1 mt-2"
                            style={{ backgroundColor: primary }}
                          ></div>
                        </div>
                      </div>

                      <div>
                        <div class="mt-3">
                          <a
                            href={item.website_link}
                            target="_blank"
                            rel=" noreferrer"
                          >
                            {" "}
                            <p>
                              {item.website_link.replace(/^(https?:\/\/)/, "")}
                            </p>
                          </a>

                          <div
                            class="w-[120px] h-1 mt-2"
                            style={{ backgroundColor: primary }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

                <div
                  class="w-full h-auto pb-2"
                  style={{ backgroundColor: secondary }}
                >
                  <div class="flex py-4 items-center flex-col">
                    <img src={qrcode} class="shadow-lg w-[150px]  h-[150px]" />

                    <div class="flex flex-col text-center">
                      <a href={qrcode} class="mx-6 mt-3 ">
                        <span class="text-md font-bold text-white hover:font-medium ">
                          Download
                        </span>
                      </a>

                      {/* <a href={qrcode} class="mx-6 ">
                                            <span class="text-md font-bold  text-white  hover:font-medium">Share</span>
                                        </a> */}
                      <div
                        class=" w-[150px] h-0.5 mt-1  mx-auto"
                        style={{ backgroundColor: primary }}
                      ></div>
                    </div>
                  </div>

                  <div class="flex flex-col   mt-5 mx-auto">
                    <div class="flex justify-center  gap-2 ">
                      <ul>
                        <li class="flex gap-2">
                          <TbArrowsExchange2 size={25} color="white" />
                          <span class="text-white">Exchange Contacts</span>
                        </li>
                        <li class="flex gap-4 mt-2">
                          <BsShareFill size={18} color="white" />
                          <span class="text-white">Share Details</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class=" flex h-10 justify-center rounded-md mt-4  ">
                    <button
                      class=" text-xl bg-[#E1D7CD] rounded-lg px-3 text-white font-medium"
                      style={{ backgroundColor: primary }}
                    >
                      Add To Contacts
                    </button>
                  </div>
                  <div class="mt-5">
                    <p class="text-center text-sm text-gray-400">
                      {" "}
                      {item.address}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Template1;
