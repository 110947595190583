import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { AiOutlineBars } from "react-icons/ai";
import { BsPower, BsSpeedometer2 } from "react-icons/bs";
import { DiGoogleAnalytics } from "react-icons/di";
import { GiSightDisabled } from "react-icons/gi";
import { BsPerson } from "react-icons/bs";
import { TbHomeCog, TbUserCog } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { unsetUserAuth } from "../../../features/authSlice";
import { GetToken, RemoveToken } from "../../../services/localstorage";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown() {
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(unsetUserAuth({ access_token: null }));
    RemoveToken();
    toast.warning("Logged Out Successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setTimeout(() => {
      Navigate("/");
    }, 3000);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = GetToken();
        const response = await fetch(baseUrl + "get-user-profile/", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token["access_token"]}`,
          },
        });
        const userData = await response.json();
        console.log("==================", userData);
        setUser(userData);
      } catch (error) {
        // Handle error
      }
    };

    fetchUserData();
  }, []);
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>
          <AiOutlineBars />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-bottom-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="../"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  <span className="flex items-center gap-1">
                    <FaHome /> Home
                  </span>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/dashboard"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  <span className="flex items-center gap-1">
                    <BsSpeedometer2 /> Dashboard
                  </span>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/dashboard/analytics"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  <span className="flex items-center gap-1">
                    <DiGoogleAnalytics />
                    Analytics
                  </span>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/dashboard/account-setting"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  <span className="flex items-center gap-1">
                    <TbHomeCog />
                    Account Setting
                  </span>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/dashboard/user-management"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  <span className="flex items-center gap-1">
                    <TbUserCog /> User Management
                  </span>
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              <div className="flex items-center justify-between">
                <span className="flex gap-1 items-center px-4 py-2 text-sm">
                  <BsPerson />
                  <strong>{user?.first_name + " " + user?.last_name}</strong>
                </span>
                <span
                  className="mx-2 bg-red-500 text-white font-bold p-2 rounded"
                  onClick={logout}
                >
                  <BsPower />
                </span>
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
