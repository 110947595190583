/* eslint-disable jsx-a11y/scope */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../sidebar/userManagement/DeleteModal";
import { GetToken } from "../../../../services/localstorage";
import ReactPaginate from "react-paginate";
import "../../sidebar/userManagement/UserTable.css";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

const UserTable = () => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [user, setUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let access_token = localStorage.getItem("access_token");
  const [modalUserId, setModalUserId] = useState(null);

  const openDeleteModal = (userId) => {
    setModalUserId(userId);
  };

  const closeDeleteModal = () => {
    setModalUserId(null);
  };

  const confirmDeleteUser = async (userId) => {
    await deleteUser(userId);
    closeDeleteModal();
  };

  const fetchLast7DaysUserData = async () => {
    try {
      const currentDate = new Date();
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(currentDate.getDate() - 7);

      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const filteredUserData = response.data.filter((item) => {
        const itemDate = new Date(item.date_joined);
        return itemDate >= sevenDaysAgo && itemDate <= currentDate;
      });

      setUser(filteredUserData);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLast7DaysUserData();
  }, []);

  // delete user
  const deleteUser = async (itemId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/?id=${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      //   fetchUserData();
      fetchLast7DaysUserData();
      toast.error("Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error:", error);
      alert("Error Deleting user details!");
    }
  };

  // current user data
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;
  useEffect(() => {
    const UserData = async () => {
      const token = GetToken();
      try {
        const response = await axios.get(baseUrl + "get-user-profile/", {
          headers: {
            Authorization: `Bearer ${token["access_token"]}`,
          },
        });
        setCurrentUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    UserData();
  }, []);

  // Update this line to sort the user array before pagination
  const sortUsersByName = (users) => {
    return users.slice().sort((a, b) => {
      const nameA =
        a.first_name.toLowerCase() + " " + a.last_name.toLowerCase();
      const nameB =
        b.first_name.toLowerCase() + " " + b.last_name.toLowerCase();
      return nameA.localeCompare(nameB);
    });
  };
  // total pages available
  const usersPerPage = 5;
  const totalPages = Math.ceil(user.length / usersPerPage);

  // Pagination handle
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  // User display in pagination
  const startIndex = (currentPage - 1) * usersPerPage;
  const endIndex = startIndex + usersPerPage;
  const usersToDisplay = sortUsersByName(user).slice(startIndex, endIndex);

  return (
    <div className="">
      {console.log("Users to Display:", usersToDisplay)}
      {loading ? (
        <p className="text-center">Please Be Patient...</p>
      ) : (
        <div className="w-full">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full overflow-hidden">
              <table className="rounded w-full text-sm text-center text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                  <tr>
                    <th scope="col" className="px-4 py-3">
                      Sr.no
                    </th>
                    <th></th>
                    <th scope="col" className="px-4 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {usersToDisplay.map((item, index) => (
                    <tr
                      className={`${
                        item.is_superuser ? "font-semibold" : ""
                      } border-b bg-white hover:bg-gray-100`}
                      key={index}
                    >
                      <td scope="row" className="px-4 py-4 whitespace-nowrap">
                        {startIndex + index + 1}
                      </td>
                      <td>
                        {" "}
                        {item.profile_img_url && (
                          <img
                            src={item.profile_img_url}
                            alt="img"
                            className="h-8 w-8 object-center object-cover rounded-full"
                          />
                        )}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap flex items-center justify-center gap-2">
                        {" "}
                        {item.first_name + " " + item.last_name}&nbsp;
                        <span className="text-[9px]">
                          {item.is_superuser && "(admin)"}
                        </span>
                      </td>
                      <td className="px-4 py-4">{item.email}</td>

                      <td className="px-4 py-4 space-x-3 md:text-lg whitespace-nowrap">
                        {currentUser && currentUser.id !== item.id ? (
                          <>
                            <Link to={`/dashboard/${item.id}`}>
                              <button className="text-blue-500">
                                <AiOutlineEye />
                              </button>
                            </Link>
                            <Link to={`/dashboard/edit-user/${item.id}`}>
                              <button className="text-yellow-500">
                                <BsPencil />
                              </button>
                            </Link>
                            <button
                              className="text-red-500"
                              onClick={() => openDeleteModal(item.id)}
                            >
                              <RiDeleteBin5Line />
                            </button>
                          </>
                        ) : (
                          <div></div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            {/* Pagination Buttons */}
            <ReactPaginate
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination flex"}
              activeClassName={"active"}
              previousLabel={<MdNavigateBefore />}
              nextLabel={<MdNavigateNext />}
              breakLabel={"..."}
              previousClassName={"previous"}
              nextClassName={"next"}
              pageLinkClassName={"page-link"}
              activeLinkClassName={"active"}
            />
          </div>
        </div>
      )}
      <Modal
        isOpen={modalUserId !== null}
        closeModal={closeDeleteModal}
        confirmDelete={() => confirmDeleteUser(modalUserId)}
      />
    </div>
  );
};

export default UserTable;
