import React, { useState, useEffect } from "react";
import { BsFillTelephoneFill, BsShareFill } from "react-icons/bs";
import { FaLink } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { IoMdPersonAdd } from "react-icons/io";
import axios from "axios";
import { ChromePicker } from "react-color";
import QRCode from "qrcode";
import { Helmet } from "react-helmet";
import { GetToken } from "../../services/localstorage";
import { ToastContainer, toast } from "react-toastify";
import { useParams, Link, useNavigate } from "react-router-dom";
import Carousel from "../Card/Carousel";
import { TbArrowsExchange2 } from "react-icons/tb";

const Template2 = ({ data }) => {
  console.log("=======================+++", data);

  // console.log(id)
  const [primary, setPrimary] = useState("#424041");
  const [secondary, setSecondary] = useState("#CD3047");
  const params = useParams();
  console.log("id=", params);
  const [qrcode, setQr] = useState("");
  const [isopen, setIsopen] = useState(false);
  const [isopenPri, setIsopenPri] = useState(false);
  const { access_token } = GetToken();
  const [id, setId] = useState(null);
  const navigate = useNavigate();

  const handleColorPrimary = (selectedColor) => {
    setPrimary(selectedColor.hex);
    console.log(primary);
  };

  const handleColorSecondary = (selectedColor) => {
    setSecondary(selectedColor.hex);
    console.log(secondary);
  };

  const url =
    process.env.REACT_APP_PUBLIC_URL + "card-customise/" + `?id=${params.link}`;
  //  console.log("--------",data[0].card_link)
  const config = {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  };

  const id1 = async () => {
    const newId = data[0].id;
    setId(newId);
    console.log("-----------------------", id);
  };
  useEffect(() => {
    id1();
  }, [data]);

  const handleClick = (e) => {
    e.preventDefault();
    const actualdata = {
      card: id,
      card_color_primary: primary,
      card_color_sec: secondary,
      template_code: params.id,
    };
    axios.post(url, actualdata, config).then((response) => {
      toast.success("Yay! Card created!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate(`/shared-card/${params.link}`);
      }, 3000);
      console.log("posted data", actualdata);
    });
  };

  useEffect(() => {
    QRCode.toDataURL(
      process.env.REACT_APP_PUBLIC_URL + "view-card/" + params.id
    ).then((data) => {
      setQr(data);
    });
  }, []);

  return (
    <div class="flex flex-col max-w-sm  h-auto md:flex-row justify-center mx-auto gap-5">
      <div class="text-center">
        <div class="flex-col max-w-sm md:w-[300px] rounded-lg mt-5 mx-auto border shadow-lg mb-3  md:mt-[200px]">
          <h1 class="text-center text-xl font-medium text-gray-700 p-2">
            Customize Theme For Your Business Card
          </h1>
          <div className="flex justify-center items-center gap-2">
            <button
              class="border p-2  shadow-lg rounded-md font-medium"
              style={{ backgroundColor: secondary, color: "white" }}
              onClick={() => setIsopen(!isopen)}
            >
              Primary Color
            </button>
            <br />

            <button
              class="border p-2 mt-3 mb-2 rounded-md shadow-lg font-medium"
              style={{ backgroundColor: primary, color: "white" }}
              onClick={() => setIsopenPri(!isopenPri)}
            >
              Secondary Color
            </button>
          </div>
          {isopen ? (
            <span
              onMouseLeave={() => setIsopen(!isopen)}
              className="absolute z-50 -translate-x-44"
            >
              <ChromePicker color={secondary} onChange={handleColorSecondary} />
            </span>
          ) : (
            ""
          )}
          {isopenPri ? (
            <span
              onMouseLeave={() => setIsopenPri(!isopenPri)}
              className="absolute z-50 -translate-x-44"
            >
              <ChromePicker color={primary} onChange={handleColorPrimary} />
            </span>
          ) : (
            ""
          )}

          <form onSubmit={(e) => handleClick(e)}>
            <button class="text-white bg-blue-500 h-10 mt-3 px-5 hover:bg-[#13CAFD]  shadow-lg rounded text-lg font-medium mx-auto mb-3">
              Save
            </button>
          </form>
        </div>
      </div>

      <div>
        {data.map((item, index) => (
          <div key={index}>
            <Helmet>
              <title class="capi">
                {item.first_name + " " + item.last_name}-Profile
              </title>
              <meta property="og:image" content={item.profile_img} />
              <meta
                property="og:title"
                content="create a bussiness digital card"
              />
              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content={
                  process.env.REACT_APP_PUBLIC_URL + "view-card/" + params.id
                }
              />
            </Helmet>
            <h1 className="text-center text-xl sm:hidden font-semibold ">
              <u>Live Card Preview</u>
            </h1>
            <div class="container mx-auto flex  max-w-sm justify-center p-2 h-auto">
              <a
                href="#"
                class="block max-w-sm p-6  w-full  border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                style={{ backgroundColor: primary }}
              >
                <div
                  class=" w-full mt-[-24px] h-8 py-1 rounded-b-3xl"
                  style={{ backgroundColor: secondary }}
                >
                  <p class="text-center font-medium text-white">
                    {item.business_name}
                  </p>
                </div>

                <div>
                  <img
                    src={item.profile_img}
                    class="w-40 h-40 rounded-full object-cover mx-auto mt-2  shadow-lg"
                  ></img>
                  <p class="uppercase font-bold text-center text-white mt-2 text-xl">
                    {" "}
                    {item.first_name + " " + item.last_name}
                  </p>
                  <p class="uppercase text-center text-sm text-white  font-medium">
                    {" "}
                    {item.profession}
                  </p>
                  <div
                    class="   w-[150px] h-0.5 mt-1  mx-auto"
                    style={{ backgroundColor: secondary }}
                  ></div>
                </div>

                <div class="flex gap-10">
                  <div
                    class=" w-5 h-[200px] mx-[-20px] rounded-r-lg"
                    style={{ backgroundColor: secondary }}
                  ></div>
                  <div class="flex h-auto gap-4 ">
                    <div class=" flex  ">
                      <ul class="flex flex-col  text-lg  mt-6  font-medium">
                        <li class="flex hover:cursor-pointer hover:text-[#CD3047]  gap-2">
                          <BsFillTelephoneFill
                            color="white"
                            size={20}
                            class="mt-1 "
                          />
                          <div class="flex flex-col">
                            <p class="text-md  text-white">
                              {" "}
                              {item.mobile_number}
                            </p>
                            <span class="text-gray-300 text-sm mx-1">
                              (Contact)
                            </span>
                          </div>
                        </li>

                        <li class="flex hover:cursor-pointer mt-1 gap-2">
                          <GrMail size={22} color="white" class="mt-1" />

                          <div class="flex flex-col">
                            <p class="text-white">{item.email}</p>
                            <span class="text-gray-300 text-sm mx-1">
                              (Email)
                            </span>
                          </div>
                        </li>
                        <li class="flex hover:cursor-pointer  mt-1 hover:text-[#CD3047] gap-2">
                          <FaLink size={22} color="white" class="mt-1" />

                          <div class="flex flex-col">
                            <a
                              href={item.website_link}
                              target="_blank"
                              rel=" noreferrer"
                            >
                              {" "}
                              <p class="text-white">
                                {" "}
                                {item.website_link.replace(
                                  /^(https?:\/\/)/,
                                  ""
                                )}
                              </p>
                            </a>

                            <span class="text-gray-300 text-sm mx-1">
                              (Company)
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="flex justify-center  ">
                  <div class="flex justify-center mt-3">
                    <button
                      type="button"
                      class="text-white  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm py-2 text-center mr-2 mb-2"
                      style={{ backgroundColor: secondary }}
                    >
                      {" "}
                      <BsShareFill class="mx-2" size={18} />
                    </button>
                  </div>

                  <div class="flex justify-center mt-3">
                    <button
                      type="button"
                      class="text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm  py-2 text-center mr-2 mb-2"
                      style={{ backgroundColor: secondary }}
                    >
                      {" "}
                      <TbArrowsExchange2 class="mx-2 " size={20} />
                    </button>
                  </div>
                </div>

                <div class="mt-5 flex  items-center  flex-col">
                  <img src={qrcode} class="shadow-lg w-[220px]  h-[220px]" />

                  <div class="flex flex-col text-center">
                    <a href={qrcode} class="mx-6 mt-3 ">
                      <span class="text-md font-bold text-white hover:font-medium ">
                        Download
                      </span>
                    </a>

                    {/* <a href={qrcode} class="mx-6 ">
                  <span class="text-md font-bold  text-white  hover:font-medium">Share</span>
                </a> */}
                    <div
                      class="w-[150px] h-0.5 mt-1  mx-auto"
                      style={{ backgroundColor: secondary }}
                    ></div>
                  </div>
                </div>

                <div
                  class=" max-w-md h-auto mt-5"
                  style={{ backgroundColor: secondary }}
                >
                  <IoMdPersonAdd
                    color="white"
                    size={40}
                    class=" mt-5 mx-auto  "
                  />

                  <p class="text-sm text-gray-100 px-3  pb-2 text-center">
                    {item.address}
                  </p>
                </div>
              </a>
            </div>
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Template2;
