import React from 'react'
import { Link } from 'react-router-dom'
import logo from './images/img.png'


const Nav = () => {
    return (
        <>
            <nav class=" mx-auto justify-center  container rounded-sm " >


                <div class='flex justify-around pt-3 py-3 '>
                    <Link to='/'>
                        <img
                            class='pt-2 py-2  '
                            src={logo}
                            alt="Logo"

                        />
                    </Link>
                    <button class=" text-white bg-blue-500 h-10 mt-5 px-3 hover:bg-[#13CAFD]  shadow-lg rounded text-md font-medium"> <Link to="/user-details">New Card</Link></button>


                </div>
            </nav>  

        </>
    )
}

export default Nav
