// const StoreToken = (value) => {
//   if (value) {
//     const { access, refresh } = value;
//     localStorage.setItem("access_token", access);
//     localStorage.setItem("refresh_token", refresh);
//   }
// };

// const GetToken = () => {
//   let access_token = localStorage.getItem("access_token");
//   let refresh_token = localStorage.getItem("refresh_token");
//   return { access_token, refresh_token };
// };

// const RemoveToken = () => {
//   localStorage.removeItem("access_token");
//   localStorage.removeItem("refresh_token");
//   localStorage.removeItem(
//     `oauth2_ss::${process.env.REACT_APP_LOCAL_URL}::1::DEFAULT::_ss_`
//   );
// };

// export { StoreToken, GetToken, RemoveToken };

const StoreToken = (value) => {
  if (value) {
    const { access, refresh } = value;
    const currentTime = new Date().getTime();
    const tokenExpirationTime = currentTime + 24 * 60 * 60 * 1000;
    localStorage.setItem("access_token", access);
    localStorage.setItem("refresh_token", refresh);
    localStorage.setItem("token_expiration", tokenExpirationTime.toString());
  }
};

const GetToken = () => {
  const currentTime = new Date().getTime();
  let access_token = localStorage.getItem("access_token");
  let refresh_token = localStorage.getItem("refresh_token");
  let tokenExpirationTime = localStorage.getItem("token_expiration");

  if (tokenExpirationTime && currentTime > parseInt(tokenExpirationTime)) {
    RemoveToken();
    access_token = null;
    refresh_token = null;
  }

  return { access_token, refresh_token };
};

const RemoveToken = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("token_expiration");
};

export { StoreToken, GetToken, RemoveToken };
