import React, { useState, useEffect } from "react";
import { BsPersonFillAdd, BsTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { BiInfinite } from "react-icons/bi";
import axios from "axios";
import fileDownload from "js-file-download";
import QRCode from "qrcode";
import { Helmet } from "react-helmet";
import { TbWorldWww } from "react-icons/tb";
import { GetToken } from "../../services/localstorage";
import { ChromePicker } from "react-color";
import { useParams, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ImFloppyDisk } from "react-icons/im";

const Template4 = ({ data }) => {
  const [primary, setPrimary] = useState("white");
  const [secondary, setSecondary] = useState("#7165B4");
  const [isopen, setIsopen] = useState(false);
  const [isopenPri, setIsopenPri] = useState(false);
  const { access_token } = GetToken();
  const [id, setId] = useState(null);
  const navigate = useNavigate();

  const handleColorPrimary = (selectedColor) => {
    setPrimary(selectedColor.hex);
    console.log(primary);
  };

  const handleColorSecondary = (selectedColor) => {
    setSecondary(selectedColor.hex);
    console.log(secondary);
  };

  const params = useParams();
  console.log("params", params);

  const [qrcode, setQr] = useState("");

  const url =
    process.env.REACT_APP_PUBLIC_URL + `card-customise/?id=${params.link}`;
  //  console.log("--------",data[0].card_link)
  const config = {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  };

  const id1 = async () => {
    const newId = data[0].id;
    setId(newId);
    console.log("-----------------------", id);
  };
  useEffect(() => {
    id1();
  }, [data]);

  const handleClick = (e) => {
    e.preventDefault();
    const actualdata = {
      card: id,
      card_color_primary: primary,
      card_color_sec: secondary,
      template_code: params.id,
    };
    axios.post(url, actualdata, config).then((response) => {
      toast.success("Yay! Card created!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate(`/shared-card/${params.link}`);
      }, 3000);
      console.log("posted data", actualdata);
    });
  };
  useEffect(() => {
    QRCode.toDataURL(
      process.env.REACT_APP_PUBLIC_URL + "view-card/" + params.id
    ).then((data) => {
      setQr(data);
    });
  }, []);

  return (
    <div class="flex flex-col max-w-sm  h-auto md:flex-row justify-center mx-auto gap-5">
      <div class="text-center">
        <div class="flex-col max-w-sm md:w-[300px] rounded-lg mt-5 mx-auto border shadow-lg mb-3  md:mt-[200px]">
          <h1 class="text-center text-xl font-medium text-gray-700 p-2">
            Customize Theme For Your Business Card
          </h1>
          <div className="flex justify-center items-center gap-2">
            <button
              class="border p-2  shadow-lg rounded-md font-medium"
              style={{ backgroundColor: secondary, color: primary }}
              onClick={() => setIsopen(!isopen)}
            >
              Primary Color
            </button>
            <br />

            <button
              class="border p-2 mt-3 mb-2  rounded-md shadow-lg font-medium"
              style={{ backgroundColor: primary, color: secondary }}
              onClick={() => setIsopenPri(!isopenPri)}
            >
              Secondary Color
            </button>
          </div>
          {isopen ? (
            <span
              onMouseLeave={() => setIsopen(!isopen)}
              className="absolute z-50 -translate-x-44"
            >
              <ChromePicker color={secondary} onChange={handleColorSecondary} />
            </span>
          ) : (
            ""
          )}
          {isopenPri ? (
            <span
              onMouseLeave={() => setIsopenPri(!isopenPri)}
              className="absolute z-50 -translate-x-44"
            >
              <ChromePicker color={primary} onChange={handleColorPrimary} />
            </span>
          ) : (
            ""
          )}

          <form onSubmit={(e) => handleClick(e)}>
            <button class="text-white bg-blue-500 h-10 mt-3 px-5 hover:bg-[#13CAFD]  shadow-lg rounded text-lg font-medium mx-auto mb-3">
              Save
            </button>
          </form>
        </div>
      </div>
      <div>
        {data.map((item, index) => (
          <div key={index}>
            {console.log(item)}
            <Helmet>
              <title class="capi">
                {item.first_name + " " + item.last_name}-Profile
              </title>
              <meta property="og:image" content={item.profile_img} />
              <meta
                property="og:title"
                content="create a bussiness digital card"
              />
              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content={
                  process.env.REACT_APP_PUBLIC_URL + "view-card/" + params.id
                }
              />
            </Helmet>
            <h1 className="text-center text-xl sm:hidden font-semibold mb-2 ">
              <u>Live Card Preview</u>
            </h1>
            <div
              class="max-w-sm  border container  mx-auto h-auto rounded-lg border-gray-200  shadow dark:bg-gray-800 dark:border-gray-700"
              style={{ backgroundColor: primary }}
            >
              <div
                class="  flex justify-center relative h-[150px] rounded-lg "
                style={{ backgroundColor: secondary }}
              >
                <h1
                  class="text-center text-white text-3xl mt-3 font-medium font-sans capitalize
  "
                >
                  {item.business_name}
                </h1>
              </div>

              <div
                class="relative mt-[-80px] mx-auto rounded-full w-40 h-40"
                style={{ backgroundColor: primary }}
              >
                <img
                  src={item.profile_img}
                  class="w-40 h-40 shadow-xl  rounded-full object-cover absolute "
                  alt="img"
                />
              </div>

              <div
                class="w-[110px] h-[5px] mt-5 flex mx-auto "
                style={{ backgroundColor: secondary }}
              ></div>

              <div>
                <h1 class="text-center  text-gray-900 mt-2 font-medium text-3xl capitalize">
                  {item.first_name + " " + item.last_name}
                </h1>
                <span class="flex justify-center text-md text-gray-500 font-medium">
                  {item.profession}
                </span>
              </div>

              <div className="space-y-3 pt-2 mx-10">
                <div class="flex items-center gap-1">
                  <span
                    className="rounded-full p-2"
                    style={{ backgroundColor: secondary }}
                  >
                    <BsTelephoneFill color={primary} className="" />
                  </span>
                  <span class="font-medium">{item.mobile_number}</span>
                </div>

                <div class="flex items-center gap-1">
                  <span
                    className="rounded-full p-2"
                    style={{ backgroundColor: secondary }}
                  >
                    <GrMail color={primary} className="" />
                  </span>

                  <span class="font-medium">{item.email}</span>
                </div>

                <div class="flex items-center gap-1">
                  <button
                    class="rounded-full flex justify-center w-8 h-8"
                    style={{ backgroundColor: secondary }}
                  >
                    <TbWorldWww color={primary} size={18} class="mt-1.5" />
                  </button>

                  <p class="font-medium xmt-1">
                    <a
                      href={item.website_link}
                      target="_blank"
                      rel=" noreferrer"
                    >
                      {" "}
                      {item.website_link.replace(/^(https?:\/\/)/, "")}
                    </a>
                  </p>
                </div>
              </div>

              <div class="mt-5 flex  items-center flex-col">
                <img
                  src={qrcode}
                  class="shadow-lg w-[150px]  h-[150px]"
                  alt="img"
                />

                <div class="flex flex-col text-center">
                  <a href={qrcode} class="mx-6 mt-3 ">
                    <span
                      class="text-md font-bold  hover:font-medium "
                      style={{ color: secondary }}
                    >
                      Download
                    </span>
                  </a>
                </div>
              </div>

              <div
                class=" mt-4  justify-center relative h-[130px] rounded-lg "
                style={{ backgroundColor: secondary }}
              >
                <div class="flex justify-center">
                  <BsPersonFillAdd color="white " class="mt-2" size={36} />
                  <span class="text-white  font-bold mt-4 mx-3">
                    Add to Contacts
                  </span>
                </div>
                <p class="text-white text-center mt-2"> {item.address}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <ToastContainer />
    </div>
  );
};

export default Template4;
