import React, { useEffect } from "react";
import banner from "./images/top.png";
import img from "./images/chooseus.jpg";
import { FaBuilding } from "react-icons/fa";
import {
  BsPersonVcardFill,
  BsFillShareFill,
  BsFillCheckCircleFill,
  BsPlusCircle,
} from "react-icons/bs";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { GetToken, RemoveToken } from "../services/localstorage";
import axios from "axios";
import Slider from "react-slick";
import {
  AiFillDelete,
  AiFillEye,
  AiOutlineCopy,
  AiTwotoneEdit,
} from "react-icons/ai";
import Modal from "react-modal";
import "./Card/Modal.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Home = () => {
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  // State to hold the user data
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Function to fetch user data using the JWT token
    const fetchUserData = async () => {
      const token = GetToken();
      if (token["access_token"] !== null) {
        try {
          // console.log("++++++++++++=", token);
          // Make a request to your Django API to get the user data
          const response = await fetch(baseUrl + "get-user-profile/", {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token["access_token"]}`,
            },
          });
          const userData = await response.json();
          console.log("==================", userData);
          setUser(userData);
        } catch (error) {
          // Handle error
        }
      }
    };

    fetchUserData();
  }, []);

  // current user cards details
  const [cardData, setCardData] = useState();

  const fetchCardData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}personal-detail/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setCardData(response.data);
      console.log("Current user Card Data", response.data);
      setLoading(false);
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCardData();
  }, []);

  let access_token = localStorage.getItem("access_token");
  // console.log("getting access token", access_token);
  const settings = {
    dotsClass: "slick-dots slick-thumb",
    dots: true,
    lazyLoading: true,
    speed: 700,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          slidesToShow: cardData && cardData.length < 5 ? cardData.length : 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: cardData && cardData.length < 3 ? cardData.length : 3,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();
  // update button
  const Update = (card_link) => {
    localStorage.setItem("update_card_id", card_link);
    navigate("/update/" + card_link);
  };

  // copy button
  const copyToClipboard = () => {
    toast.success("Copied To Clipboard", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const base_url = process.env.REACT_APP_PUBLIC_URL;
  const config = {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  };
  const [wOpen, wsetOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [counter, Setcounter] = useState(1);

  const whandleOpen = async (e) => {
    wsetOpen(true);
    console.log(e);
    setDeleteId(e);
  };
  const whandleClose = () => wsetOpen(false);

  const delete_card = async (e) => {
    try {
      await axios.delete(base_url + "delete-card/" + e, config);

      whandleClose();
      Setcounter(counter + 1);
      fetchCardData();
    } catch (error) {}
  };

  function callDelete() {
    delete_card(deleteId);
    toast.error("Deleted Successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    whandleClose();
  }
  return (
    <>
      <div className="w-full">
        <Navbar />

        {/* WELCOME BANNER */}

        {access_token ? (
          <div className="">
            {/* welcome Username */}
            <div className="flex flex-col-reverse md:max-w-7xl  items-center h-auto  container sm:mx-auto gap-0 bg-white  md:h-[430px]  rounded-lg shadow-md md:flex-row ">
              <div className="flex flex-col container max-w-[350px] sm:max-w-[500px]   p-4 md:max-w-[600px] leading-normal">
                <h1 className=" text-gray-700 text-3xl text-center sm:text-left md:mx-auto md:text-5xl  mt-[20px]  font-medium">
                  <b>
                    Welcome
                    <span className="bg-white  text-[#110FFD]">
                      &nbsp;{user && user.first_name}
                    </span>
                  </b>
                </h1>
                <span className="text-gray-600  mt-5 text-xl sm:text-center md:mx-[40px] md:text-left text-center font-medium">
                <hr /><hr /> "Elevate Your Brand with Our Exquisite, Contemporary Digital Cards for Business Growth."
                </span>

                {access_token ? (
                  <Link
                    to="/user-details"
                    className="mx-auto my-4 flex flex-col items-center justify-center border-2 border-gray-400 rounded-lg w-36 sm:w-80 h-44  hover:bg-blue-100 hover:scale-105 ease-in-out transition-all transform duration-150"
                  >
                    <span className="flex flex-col items-center justify-center text-center">
                      <span>
                        <BsPlusCircle className="h-8 w-7 text-gray-500" />
                      </span>
                      <span className="font-semibold text-gray-500 mt-1">
                        Add New Card
                      </span>
                    </span>
                  </Link>
                ) : (
                  <Link to="/login">Get Started</Link>
                )}
              </div>
              <div className="  hidden  md:block">
                <img
                  className="object-cover px-1  xl:mx-16 rounded-t-lg h-96 mt-5 md:h-auto  md:w-[500px] md:rounded-none md:rounded-l-lg"
                  src={banner}
                  alt="banner img"
                />
              </div>
            </div>

            {/* User cards ============================== if card data > 0 then show data else no data  */}
            {cardData && cardData.length > 0 && (
              <>
                {loading ? (
                  <div className="bg-white">
                    <div
                      role="status"
                      className="z-10 p-10 mx-auto max-w-[200px]"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>{" "}
                  </div>
                ) : (
                  <div className="bg-gray-100 mt-10">
                    <h1 className="text-lg md:text-2xl p-2 text-gray-600 font-semibold text-center">
                      <u>My Cards</u>
                    </h1>
                    <div className={`w-full p-7 xl:p-16  rounded`}>
                      <Slider {...settings} className="text-center">
                        {cardData &&
                          cardData.map((item, index) => (
                            <div
                              key={index + 1}
                              className="rounded max-w-sm inline-block"
                            >
                              <div
                                className={`bg-white rounded-lg p-5 m-1 md:m-5 shadow-lg`}
                              >
                                <div className="w-full mx-auto">
                                  <img
                                    src={
                                      item?.profile_img ||
                                      user?.profile_img_url ||
                                      user?.profile_img
                                    }
                                    alt="profile pic"
                                    className="rounded-full h-28 w-28 mx-auto ring-1"
                                  />
                                </div>
                                <div className="pt-4 text-center space-y-2">
                                  <p className="text-2xl font-semibold">
                                    {item?.first_name + " " + item?.last_name}{" "}
                                  </p>
                                  <p>{item?.profession} </p>
                                  <p>Created On : {item?.create_on} </p>
                                </div>
                                <div className="text-center pt-2 ">
                                  <div className="flex justify-center space-x-2">
                                    <abbr title="Edit">
                                      <button
                                        type="button"
                                        className="text-white  mt-4 bg-green-700 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-green-700 dark:hover:bg-green-600 focus  shadow-md  hover:scale-90"
                                        onClick={() => Update(item.card_link)}
                                      >
                                        <AiTwotoneEdit
                                          size={17}
                                          color="white"
                                        />
                                      </button>
                                    </abbr>
                                    <abbr title="View">
                                      <Link
                                        to={`/shared-card/${item.card_link}`}
                                        key={item.card_link.toString()}
                                      >
                                        <button
                                          type="button"
                                          className="text-white   mt-4 bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800  shadow-md  hover:scale-90"
                                        >
                                          <AiFillEye size={17} color="white" />
                                        </button>
                                      </Link>
                                    </abbr>
                                    {/* COPY BUTTON  */}
                                    <abbr title="Copy Card Link">
                                      <CopyToClipboard
                                        text={
                                          process.env.REACT_APP_WEB_URL +
                                          "shared-card/" +
                                          item.card_link
                                        }
                                      >
                                        <button
                                          onClick={copyToClipboard}
                                          className="text-white mt-4 bg-gray-600 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-red-500 shadow-md hover:scale-90"
                                        >
                                          <AiOutlineCopy
                                            size={17}
                                            color="white"
                                          />
                                        </button>
                                      </CopyToClipboard>
                                    </abbr>
                                    <abbr title="Delete">
                                      <button
                                        type="button"
                                        className="text-white mt-4 bg-red-600 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-500  shadow-md  hover:scale-90"
                                        onClick={() =>
                                          whandleOpen(item.card_link)
                                        }
                                      >
                                        <AiFillDelete size={17} color="white" />
                                      </button>
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Slider>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <>
            <div className="flex flex-col-reverse md:max-w-7xl  items-center h-auto  container sm:mx-auto gap-0 bg-white  md:h-[430px]  rounded-lg shadow-md md:flex-row ">
              <div className="flex flex-col container max-w-[350px] sm:max-w-[500px]   p-4 md:max-w-[600px] leading-normal">
                <h1 className=" text-gray-900 text-3xl   text-center sm:text-left md:mx-auto  md:text-5xl  mt-[20px]  font-medium">
                  Welcome To
                  <span className="text-[#110FFD]">
                    &nbsp;Visiontrek
                  </span>
                </h1>
                <h1 className="text-[#110FFD] text-xl text-center sm:text-left md:mx-[40px]  md:text-left md:text-5xl mt-3 md:mt-5 font-medium">
                  Digital Card®
                </h1>
                <span className="text-gray-600  mt-5 text-xl sm:text-left md:mx-[40px] md:text-left text-center font-medium">
                "Elevate Your Brand with Our Exquisite, Contemporary Digital Cards for Business Growth."
                </span>

                <button className="text-white bg-blue-500 h-10 mx-auto mt-5 px-3  hover:bg-blue-600 hover:scale-105 transition-all transform duration-150 ease-in-out border-none  shadow-xl rounded text-md font-medium">
                  {access_token ? (
                    <Link to="/user-details">Get Started</Link>
                  ) : (
                    <Link to="/login">Get Started</Link>
                  )}
                </button>
              </div>
              <div className="  hidden  lg:block">
                <img
                  className="object-cover px-1  lg:mx-[60px] md:mx-[20px]  sm:w-[200px]   rounded-t-lg h-96 mt-5 md:h-auto  md:w-[500px] md:rounded-none md:rounded-l-lg"
                  src={banner}
                  alt=""
                />
              </div>
            </div>
          </>
        )}

        {/* THREE CARDS  */}

        <div
          className=" flex flex-col-reverse md:flex-row
                     container mx-auto mt-10 "
        >
          <div className="flex-1 ">
            <img
              src={img}
              alt=""
              className="md:w-[500px] rounded-lg  mt-50 md:mt-[100px]  mx-auto  shadow-lg object-cover  md:hover:scale-105 hover:duration-300 transition-all ease-in-out 
                    "
            />
          </div>
          <div className="flex-1  mb-5 ">
            <h1 className="mx-4 mt-[40px] text-2xl  font-medium text-center text-gray-700 md:text-left md:text-3xl">
            What's in store for you?

            </h1>
            <p className="text-gray-500 mx-4 md:text-left text-justify mt-5">
            A digital business card offers in today's fast-paced digital-centric world, as a dynamic & convenient way to present & exchange contact information, making networking & communication more efficient. Visiontrek Digicard® allow for customization, enabling you to showcase your brand's identity & stand out in a competitive market. Furthermore, our digital cards can incorporate multimedia elements, providing an interactive platform to showcase products or services.
            </p>

            <div className="flex flex-col  container mx-auto  mt-8">
              <div className="flex  mx-4">
                <BsFillCheckCircleFill color="green" size={25} />{" "}
                <span className="mx-2">Customer Data Protection - "Your Data, Your Way"</span>
              </div>
              <div className="flex mx-4 mt-4 ">
                <BsFillCheckCircleFill color="green" size={25} />{" "}
                <span className="mx-2">On-Demand Accessibility and Updates</span>
              </div>
              <div className="flex mx-4 mt-4 ">
                <BsFillCheckCircleFill color="green" size={25} />{" "}
                <span className="mx-2">Dedicated Support Team</span>
              </div>
              <div className="flex mx-4 mt-4 ">
                <BsFillCheckCircleFill color="green" size={25} />{" "}
                <span className="mx-2 mb-8">Global Reach</span>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <Modal
        isOpen={wOpen}
        onRequestClose={whandleClose}
        className="modal"
        overlayclassName="overlay"
      >
        <div className="modal-content">
          <h2 className="modal-title">Confirm Delete</h2>
          <p className="modal-message">Are you sure you want to delete?</p>
          <div className="modal-actions">
            <button
              className="modal-button modal-cancel"
              onClick={whandleClose}
            >
              Cancel
            </button>
            <button className="modal-button modal-confirm" onClick={callDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Home;
