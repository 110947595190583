import React, { useEffect, useState } from "react";
import axios from "axios";
import { LineChart, Line, Tooltip, ResponsiveContainer, XAxis } from "recharts";

const Last30MinutesViews = () => {
  const access_token = localStorage.getItem("access_token");
  const [chartData, setChartData] = useState([]);
  const [totalViewsIn30Minutes, setTotalViewsIn30Minutes] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}analytics/device-data/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        // Set the fetched data to the chartData state
        setChartData(response.data);

        // Calculate total views in the last 30 minutes
        const currentTime = new Date();
        const thirtyMinutesAgo = new Date(currentTime - 30 * 60 * 1000);
        const viewsInLast30Minutes = response.data.filter(
          (item) => new Date(item.timestamp) >= thirtyMinutesAgo
        );
        setTotalViewsIn30Minutes(viewsInLast30Minutes.length);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchData();
  }, [access_token]);

  // Calculate views count for each minute interval
  const calculateViewsPerMinute = () => {
    const currentTime = new Date();
    const thirtyMinutesAgo = new Date(currentTime - 30 * 60 * 1000);

    const viewsPerMinute = [];

    for (
      let intervalTime = thirtyMinutesAgo;
      intervalTime <= currentTime;
      intervalTime = new Date(intervalTime.getTime() + 60 * 1000)
    ) {
      const interval = intervalTime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
      });

      const viewsThisInterval = chartData.filter(
        (item) =>
          new Date(item.timestamp) >= intervalTime &&
          new Date(item.timestamp) <
            new Date(intervalTime.getTime() + 60 * 1000)
      );

      viewsPerMinute.push({
        interval: interval,
        Views: viewsThisInterval.length,
      });
    }

    return viewsPerMinute;
  };

  const viewsPerMinuteData = calculateViewsPerMinute();

  // Render the component
  return (
    <>
      <div className="p-2 text-[12px] font-semibold text-gray-500">
        VIEWS IN LAST 30 MINUTES
        <div className="text-4xl px-4">{totalViewsIn30Minutes}</div>
      </div>
      <div className="p-2 text-[12px] font-semibold text-gray-500">
        VIEWS PER MINUTE
      </div>
      <div className="p-5">
        <ResponsiveContainer width="100%" height={100}>
          <LineChart data={viewsPerMinuteData}>
            {/* <XAxis dataKey={"interval"} /> */}
            <Tooltip />
            <Line type="monotone" dataKey="Views" stroke="#2f72e5" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default Last30MinutesViews;
