import React from "react";
import logo from "./images/img.png";
import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div class="container mx-auto    ">
        {/* <div className="grid grid-cols-3 gap-4 ">
                    <div className="p-4 mx-auto">
                        <h2 className="text-xl font-bold mt-3 text-gray-600">Top Demand</h2>
                        <ul class="mt-3 pt-1" >
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer"><a href='https://visiontrek.in/services/web-development/'  target='blank'>Web Development</a></li>
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer"><a href='https://visiontrek.in/services/python-development/' target='blank'>Python</a></li>
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer"><a href='https://visiontrek.in/services/reactjs-development/' target='blank'>React Js</a></li>
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer"><a href='https://visiontrek.in/services/java-development/' target='blank'>Java</a></li>
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer"><a href='https://visiontrek.in/services/graphic-design/' target='blank'>Graphic designing</a></li>

                        </ul>
                    </div>
                    <div className=" p-4 mx-auto">
                        <h2 className="text-xl font-bold mt-3 text-gray-600 ">Company</h2>
                        <ul class="mt-3 " >


                            <li class="hover:text-[#13CAFD] hover:cursor-pointer"><a href='https://visiontrek.in/about-us/' target='blank'> About Us</a></li>
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer">Terms and Conditions</li>
                              <li class="hover:text-[#13CAFD] hover:cursor-pointer"><a href='https://visiontrek.in/privacy-policy/' target='blank'>Privacy Policy</a></li>
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer"><a href='https://visiontrek.in/blog/' target='blank'>Blog</a></li>
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer"><a href='https://visiontrek.in/meet-our-team/' target='blank'>Meet Our Team</a></li>

                        </ul >
                    </div>
                    <div className=" p-4 mx-auto">
                        <h2 className="text-xl font-bold mt-3 text-gray-600">Main Office</h2>
                        <ul class='mt-3'>
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer ">
                                E-195, Industrial Area, Phase 8-B, Mohali, Punjab, India</li>
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer ">info@visiontrek.in</li>
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer ">+91 93509 09124</li>
                            <li class="hover:text-[#13CAFD] hover:cursor-pointer ">9:30am - 6:30pm</li>

                        </ul>
                    </div>
                </div>
 */}

        {/* <h1 class="text-xl md:text-4xl text-center mt-8 text-blue-500 font-medium">
                    Discover our visiontrek inner system
                </h1> */}

        <div
          class="flex flex-col text-center text-md text-gray-600  mt-4
                     "
        >
          {/* <span>When you work with us, you'll get a team that is passionate about building and designing</span>
                    <span>beautiful, usable products. We will work with you to build something that looks great and</span>
                    <span>functions even better.</span> */}

          <button class=" text-white bg-blue-500  h-10 mt-5 px-3 hover:bg-blue-600 mx-auto shadow-lg rounded text-md font-medium">
            <a href="https://visiontrek.in/contact/" target="blank">
              Contact Us
            </a>
          </button>

          <span class="text-gray-400 mt-5 mb-6">
            @ 2023 Visiontrek Communication. All Rights Reserved.
          </span>
        </div>

        <div class=" container mx-auto flex flex-col items-center md:flex-row justify-around   mb-4 ">
          <div>
            <img src={logo} class="md:w-[180px] " />
          </div>

          <div class="flex gap-3 mt-4">
            <span class="text-gray-400 ">Follow us on social media </span>
            <a href="https://www.facebook.com/visiontrek.in" target="blank">
              <BsFacebook
                size={20}
                color="#1773EA "
                class="hover:cursor-pointer"
              />
            </a>
            <a href="https://www.instagram.com/visiontrek.in/" target="blank">
              <AiFillInstagram
                size={23}
                color="#EE0279"
                class="hover:cursor-pointer"
              />
            </a>

            <a href="https://twitter.com/VTrek" target="blank">
              <BsTwitter
                size={20}
                color="#1C9CEA"
                class="hover:cursor-pointer"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/visiontrek-communication/"
              target="blank"
            >
              <BsLinkedin
                size={20}
                color="#0C61BF"
                class="hover:cursor-pointer"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
