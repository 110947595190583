import React, { useState } from "react";
import ViewData from "./tabs/ViewData";
import UserData from "./tabs/UserData";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="w-full">
      <div className="flex border-b border-gray-200">
        <TabItem isActive={activeTab === 1} onClick={() => handleTabClick(1)}>
          <span className="hover:bg-gray-200 p-2 rounded hover:text-gray-700">
            {" "}
            Users Data
          </span>
        </TabItem>
        <TabItem isActive={activeTab === 2} onClick={() => handleTabClick(2)}>
          <span className="hover:bg-gray-200 p-2 rounded hover:text-gray-700">
            {" "}
            Views Data
          </span>
        </TabItem>
      </div>
      {/* Add content for each tab */}
      {activeTab === 1 && (
        <div className="p-2">
          <UserData />
        </div>
      )}
      {activeTab === 2 && (
        <div>
          <ViewData />
        </div>
      )}
    </div>
  );
};

// TabItem component to be used for each tab
const TabItem = ({ isActive, onClick, children }) => {
  return (
    <button
      className={`${
        isActive
          ? "border-black text-primary border-b-2"
          : "border-transparent text-gray-500 hover:text-primary"
      } px-4 py-2 mx-1 font-medium focus:outline-none`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Tabs;
