import React, { useState, useEffect } from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import { Link, useNavigate } from "react-router-dom";
import { GetToken } from "../../services/localstorage";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdAddCircle } from "react-icons/md";
import { AiFillMinusCircle } from "react-icons/ai";
import imageCompression from "browser-image-compression";
import { useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Custom.css";

const Service = () => {
  const [divs, setDivs] = useState([
    {
      title: "",
      description: "",
      imageFiles: [],
      videoFiles: [],
      totalFiles: 0,
    },
  ]);

  const [selectedFileNames, setSelectedFileNames] = useState(
    Array.from({ length: divs.length }, () => [])
  );
  const [totalSelectedFileCount, setTotalSelectedFileCount] = useState(0);

  const params = useParams();

  const handleInputChange = (divIndex, value) => {
    setDivs((prevDivs) =>
      prevDivs.map((div, index) =>
        index === divIndex ? { ...div, input: value } : div
      )
    );
  };

  const handleTextareaChange = (divIndex, value) => {
    setDivs((prevDivs) =>
      prevDivs.map((div, index) =>
        index === divIndex ? { ...div, textarea: value } : div
      )
    );
  };

  const handleFileChange = (divIndex, e) => {
    const files = e.target.files;
    const newDivs = [...divs];
    const newFileNames = [...selectedFileNames];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.startsWith("image/")) {
        newDivs[divIndex].imageFiles.push(file);
      } else if (file.type.startsWith("video/")) {
        newDivs[divIndex].videoFiles.push(file);
      }
      newFileNames[divIndex].push(file.name);
    }

    newDivs[divIndex].selectedFileCount = newFileNames[divIndex].length;
    newDivs[divIndex].totalFiles =
      newDivs[divIndex].imageFiles.length + newDivs[divIndex].videoFiles.length;
    setDivs(newDivs);
    setSelectedFileNames(newFileNames);
  };

  const handleRemoveFile = (divIndex, fileIndex) => {
    const newDivs = [...divs];
    const removedFileName = selectedFileNames[divIndex][fileIndex];

    if (removedFileName) {
      const filteredFileNames = selectedFileNames[divIndex].filter(
        (fileName) => fileName !== removedFileName
      );
      setSelectedFileNames((prevFileNames) => {
        const newFileNamesArray = [...prevFileNames];
        newFileNamesArray[divIndex] = filteredFileNames;
        return newFileNamesArray;
      });

      setTotalSelectedFileCount((prevCount) => prevCount - 1);
    }

    if (divs[divIndex]) {
      if (divs[divIndex].imageFiles[fileIndex]) {
        divs[divIndex].imageFiles.splice(fileIndex, 1);
      }
      if (divs[divIndex].videoFiles[fileIndex]) {
        divs[divIndex].videoFiles.splice(fileIndex, 1);
      }
    }

    setDivs(newDivs);
  };

  useEffect(() => {
    const totalSelectedFiles = selectedFileNames
      .map((names) => names.length)
      .reduce((total, count) => total + count, 0);
    setTotalSelectedFileCount(totalSelectedFiles);
  }, [selectedFileNames]);

  const handleAddDiv = () => {
    setSelectedFileNames([...selectedFileNames, []]);
    setTotalSelectedFileCount(0);
    // setSelectedFileCount([...selectedFileCount, []])

    setDivs((prevDivs) => [
      ...prevDivs,
      {
        id: prevDivs.length + 1,
        input: "",
        textarea: "",
        imageFiles: [],
        videoFiles: [],
      },
    ]);
  };

  const compressImagesWithBrowserCompression = async (images) => {
    const compressedImages = [];
    let compressedCount = 0;

    const maxSizeMB = 1;
    const targetSizeKB = 700;

    for (const file of images) {
      try {
        const imageSizeInBytes = file.size;
        const imageSizeInKB = Math.round(imageSizeInBytes / 1024);

        if (imageSizeInKB <= maxSizeMB * 1024) {
          // If the image size is less than or equal to 1MB, do not compress it
          compressedImages.push(file);
        } else {
          let compressedFile;

          if (imageSizeInKB > targetSizeKB) {
            compressedFile = await imageCompression(file, {
              maxSizeMB: maxSizeMB,
              maxWidthOrHeight: 1200,
              useWebWorker: true,
              maxIteration: 10,
              initialQuality: 1,
              minQuality: 0.8,
              quality: true,
              size: targetSizeKB * 1024,
            });

            compressedFile = new File([compressedFile], file.name, {
              type: file.type,
            });
          } else {
            compressedFile = file;
          }

          compressedImages.push(compressedFile);
        }

        compressedCount++;

        if (compressedCount === images.length) {
          compressedImages.forEach((compressedImg, index) => {
            const compressedSizeInBytes = compressedImg.size;

            const compressedSizeInKB = Math.round(compressedSizeInBytes / 1024);
            // console.log(
            //   `Image ${index + 1}: Original size: ${imageSizeInKB} KB, Compressed size: ${compressedSizeInKB} KB`
            // );
          });
        }
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }

    return compressedImages;
  };

  const [error, seterror] = useState(false);
  const [loading, setloading] = useState(false);

  const base_url = process.env.REACT_APP_PUBLIC_URL;

  const [inputList, setinputList] = useState([{}]);
  const { access_token } = GetToken();
  const [detail, setDetail] = useState({
    person: true,
  });
  const navigate = useNavigate();

  const s_url = base_url + "product-detail/";

  const config = {
    headers: {
      authorization: `Bearer ${access_token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const FileUploder = async (item, product_id) => {
    if (item.videoFiles) {
      await Promise.all(
        item.videoFiles.map((data) => {
          return axios.post(
            s_url,
            {
              videoFiles: data,
              product_id: product_id,
            },
            config
          );
        })
      );
    }

    if (item.imageFiles) {
      await Promise.all(
        item.imageFiles.map((data) => {
          return axios.post(
            s_url,
            {
              imageFiles: data,
              product_id: product_id,
            },
            config
          );
        })
      );
    }
  };

  const handleServiceSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const formData = new FormData(e.currentTarget);

    let dataToSend = [];
    divs.forEach((div, index) => {
      // Create an object for each div
      const divData = {
        title: formData.get(`title-${index}`),
        description: formData.get(`description-${index}`),
        imageFiles: div.imageFiles, // Use the image files for this div
        videoFiles: div.videoFiles, // Use the video files for this div
      };

      dataToSend.push(divData);
    });

    try {
      const compressedData = await Promise.all(
        dataToSend.map(async (divData) => {
          // Compress images for each div if needed
          const compressedImages = await compressImagesWithBrowserCompression(
            divData.imageFiles
          );
          return {
            ...divData,
            imageFiles: compressedImages,
          };
        })
      );

      compressedData.map((item, index) => {
        axios
          .post(
            s_url,
            { title: item.title, description: item.description },
            config
          )
          .then((response) => {
            setloading(false);
            FileUploder(item, response.data.product_id);
            // console.log(params.id)
            navigate("/choose/" + params.id);
          })
          .catch((error) => {
            seterror(error.response);
            setloading(false);
          });
      });
    } catch (error) {
      console.error("Error compressing images:", error);
      setloading(false);
    }
  };

  const handleDeleteDiv = (divIndex) => {
    setDivs((prevDivs) => prevDivs.filter((_, index) => index !== divIndex));
  };

  const delete_card = async (e) => {
    try {
      await axios.delete(base_url + "delete-card/" + e, config);
      console.log(e);
    } catch (error) {
      console.log("not delete");
    }
  };

  const getFirstCharacters = (text, charCount) => {
    return text.substring(0, charCount);
  };
  const getFileExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex !== -1) {
      return fileName.substring(lastDotIndex);
    }
    return "";
  };

  return (
    <>
      <div>
        <Nav />

        <div className="container max-w-sm mx-auto md:max-w-xl p-6 bg-white border border-gray-200 rounded-lg shadow">
          <h2 class="text-md font-medium text-gray-900 dark:text-gray-900 text-center">Add Products (Optional)</h2>
          <form onSubmit={(e) => handleServiceSubmit(e)}>
            <div className="rounded-lg shadow-lg">
              {divs.map((div, index) => (
                <div className="mb-6 p-3" key={div.id}>
                  {divs.length > 1 && (
                    <div className="flex justify-end">
                      <AiFillMinusCircle
                        onClick={() => handleDeleteDiv(index)}
                        size={29}
                        className="text-red-600 font-medium flex justify-end rounded-full p-1"
                      />
                    </div>
                  )}

                  <label
                    htmlFor={`title-${index}`}
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-gray-900"
                  >
                    Product Title
                  </label>
                  <input
                    name={`title-${index}`}
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-600 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="Title here..."
                    type="text"
                    value={div.input}
  
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                  <div className="mb-6">
                    <label
                      htmlFor={`description-${index}`}
                      className="block mb-2 mt-4 text-md font-medium text-gray-600 dark:text-gray-900"
                    >
                      Product Description
                    </label>
                    <textarea
                      name={`description-${index}`}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:placeholder-gray-400 dark:text-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none"
                      placeholder="Write something about your business/product here...."
                      value={div.textarea}
            
                      onChange={(e) =>
                        handleTextareaChange(index, e.target.value)
                      }
                    />
                  </div>

                  <label className="block font-medium text-md text-gray-600 dark:text-gray-900">
                    Upload Media ({div.totalFiles} file
                    {div.totalFiles !== 1 ? "s" : ""} chosen)
                  </label>

                  <div className="relative mt-3">
                    <label
                      htmlFor={`multiple_files-${index}`}
                      className="cursor-pointer text-gray-500 bg-gray-50 dark:text-gray-400 dark:bg-gray-800 hover:bg-gray-100 p-1 px-3 rounded-lg border border-gray-300 dark:border-gray-600 dark:hover:bg-gray-700"
                    >
                      Select Files
                    </label>
                    <input
                      name={`gallery-${index}`}
                      className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                      id={`multiple_files-${index}`}
                      type="file"
                      accept="image/jpeg,image/jpg,image/png,video/mp4,video/mkv"
                      multiple
                      onChange={(e) => handleFileChange(index, e)}
                    />
                  </div>

                  <div>
                    <div className="p-2 grid grid-cols-4 sm:grid-cols-7 gap-2">
                      {div.imageFiles.map((file, fileIndex) => (
                        <div className=" relative" key={fileIndex}>
                          {file.type.startsWith("image/") ? (
                            <img
                              src={URL.createObjectURL(file)}
                              alt={`Thumbnail ${fileIndex}`}
                              className="h-16 w-16 object-cover rounded-md border"
                            />
                          ) : file.type.startsWith("video/") ? (
                            <video
                              src={URL.createObjectURL(file)}
                              controls
                              className="h-16 w-16 rounded-md border object-center"
                            />
                          ) : null}
                          <button
                            type="button"
                            onClick={() => handleRemoveFile(index, fileIndex)}
                            className="text-red-600 hover:text-red-700 absolute top-0 right-0"
                          >
                            <RxCross2 color="red" />
                          </button>
                        </div>
                      ))}
                      {div.videoFiles.map((file, fileIndex) => (
                        <div className=" relative" key={fileIndex}>
                          {file.type.startsWith("image/") ? (
                            <img
                              src={URL.createObjectURL(file)}
                              alt={`Thumbnail ${fileIndex}`}
                              className="h-16 w-16 object-cover rounded-md border"
                            />
                          ) : file.type.startsWith("video/") ? (
                            <video
                              src={URL.createObjectURL(file)}
                              controls
                              className="h-16 w-16 rounded-md border object-center"
                            />
                          ) : null}
                          <button
                            type="button"
                            onClick={() => handleRemoveFile(index, fileIndex)}
                            className="text-red-600 hover:text-red-700 absolute top-0 right-0"
                          >
                            <RxCross2 color="red" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>

                  {index === divs.length - 1 && (
                    <div className="flex mt-4 justify-between">
                      <button
                        onClick={handleAddDiv}
                        className="flex gap-2 text-gray-600 font-medium text-md"
                      >
                        <MdAddCircle size={25} color="green" />
                        Add Another Product
                      </button>
                    </div>
                  )}

                  <hr className="mt-5"></hr>
                </div>
              ))}
            </div>

            <div className="mb-3 flex-col md:flex md:flex-row gap-3">
              <button
                type="submit"
                className="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 shadow-md"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Service;