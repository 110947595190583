import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Today = () => {
  const access_token = localStorage.getItem("access_token");
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}analytics/device-data/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        const today = new Date().toISOString().split("T")[0];
        const todayViewsData = response.data.filter(
          (item) => item.timestamp.split("T")[0] === today
        );

        const hourlyData = groupDataByHour(todayViewsData);
        const chartData = calculateTotalViewsPerHour(hourlyData);

        // Set the chartData state
        setChartData(chartData);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchData();
  }, [access_token]);

  const groupDataByHour = (data) => {
    return data.reduce((acc, item) => {
      const timestamp = new Date(item.timestamp);
      const hour = timestamp.getHours();
      acc[hour] = acc[hour] || { hour, Views: 0 };
      acc[hour].Views += 1; // Increment view count per hour
      return acc;
    }, {});
  };

  const calculateTotalViewsPerHour = (data) => {
    const chartData = [];
    const currentTime = new Date();

    for (let i = 0; i <= currentTime.getHours(); i++) {
      const timestamp = new Date(currentTime);
      timestamp.setHours(i, 0, 0, 0);

      const Views = data[i]?.Views || 0;
      chartData.push({
        hour: `${i < 10 ? "0" + i : i}:00`, // Format the hour as "00:00", "01:00", etc.
        Views: Views,
      });
    }

    return chartData;
  };

  return (
    <div className="-ml-9 md:p-5 py-2 h-[220px] sm:h-[300px] lg:h-[350px]">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={chartData}>
          <XAxis dataKey="hour" interval={1} />
          <YAxis />
          <Tooltip />
          <Legend wrapperStyle={{ left: 30 }} />
          <Area
            type="monotone"
            dataKey="Views"
            stroke="#a0e030"
            fill="#a0e030"
            stackId="stack"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Today;
