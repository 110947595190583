import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

const WeekUserData = () => {
  let access_token = localStorage.getItem("access_token");
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        setChartData(response.data);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchData();
  }, []);

  const currentDate = new Date();
  const last7Days = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() - i);
    return date;
  });

  const UsersAndCardCountByDay = last7Days.map((date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const usersJoined = chartData.filter(
      (item) =>
        item.date_joined.split("T")[0] === date.toISOString().split("T")[0]
    );

    const cardCounts = usersJoined.map((user) => user.cards.length);
    const totalCardCount = cardCounts.reduce(
      (acc, cardCount) => acc + cardCount,
      0
    );

    return {
      day: `${day}-${month}`,
      Users: usersJoined.length,
      cardCount: totalCardCount,
    };
  });

  return (
    <div className="-ml-7 md:p-5 h-[250px] sm:h-[350px]">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={UsersAndCardCountByDay}>
          <XAxis dataKey="day" reversed />
          <YAxis />
          <Tooltip />
          <Legend wrapperStyle={{ left: 30 }} />
          <Area
            type="monotone"
            dataKey="Users"
            stroke="#a0e030"
            fill="#a0e030"
            stackId="stack"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WeekUserData;
