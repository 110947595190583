import React, { useState ,useEffect,useRef} from 'react'
import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader, Autocomplete } from '@react-google-maps/api'
import { FaLocationArrow } from 'react-icons/fa'
import { Tooltip } from 'react-tooltip';

import Nav from '../Nav'
import Footer from '../Footer'
import { GetToken } from '../../services/localstorage'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import {ImCross} from 'react-icons/im'
import {FcPrevious} from 'react-icons/fc'
const CompanyDetails = () => {


    const [map, setMap] = useState(/** @type google.maps.Map */(null));
    const [markerPosition, setMarkerPosition] = useState(null);
    const [searchBox, setSearchBox] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [address, setAddress] = useState('')
    const textareaRef = useRef(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY,
    });

    const [laoding,setloading]=useState(false)

    const [detail,setDetail]=useState({
        person:true
    })
    const navigate=useNavigate()
    const {access_token} = GetToken()

    const [error,setError]=useState(false)

    const base_url =process.env.REACT_APP_PUBLIC_URL
   
    const c_url=base_url+'create-work-detail/'

    const handleCompanySubmit=async(e)=>{
       e.preventDefault();
       const data = new FormData(e.currentTarget);
       
       const actualdata={
        company_name :data.get('company_name'),
        business_title :data.get('business_title'),
        address: data.get("address"),

        about_us: data.get('about_us'),
        // job_position:data.get('jobpostion'),
        // link: data.get('link')
       } 

       console.log(actualdata)

     const config={
        headers: {
            "authorization" : `Bearer ${access_token}`,
     }
    }


    axios.post(c_url,actualdata,config).then(response=>{
        setloading(false)
        setDetail({'service':true})
        navigate('/service')
    }).catch(error=>{
        setError(error.response.data)
        setloading(false)
        console.log(error.response.data)
    })


    }

    const config = {
        headers: {
            "authorization" : `Bearer ${access_token}`,
          
        }
    }
    const delete_card = async (e)=>{
        try{
          await axios.delete(base_url+"delete-card/"+e,config)
          console.log(e)
          
        }
        catch(error){
         console.log("not delete")
        }
      }


    function Cancel()
    {
        delete_card(localStorage.getItem("created_id"))
        localStorage.removeItem("created_id")
        navigate('/user-card/')

    }


    function Box_change(view){
        if(view === "company"){
           setDetail({"company":true})
        }
        else if(view === "person"){
            delete_card(localStorage.getItem("created_id"))
            setDetail({"person":true})
            localStorage.removeItem("created_id")
         }
         else if(view === "service"){
            setDetail({"service":true})
         }
         else if(view === "social"){
            setDetail({"social":true})
         }
         
         window.scrollTo(0, 0)
    }



      // Function to handle map load
      const handleMapLoad = (map) => {
        setMap(map);
    };




    // Function to handle search box places change
    const handlePlacesChanged = () => {
        if (searchBox) {
            const places = searchBox.getPlaces();
            if (places?.length > 0) {
                const { geometry, formatted_address } = places[0];
                if (geometry && geometry.location) {
                    const position = {
                        lat: geometry.location.lat(),
                        lng: geometry.location.lng(),
                    };
                    setMarker(position);
                    setAddress(formatted_address)
                    console.log("--------------", formatted_address, position)
                }
            }
        }
    };



    const setMarker = (position) => {
        setMarkerPosition(position);
        if (map) {
            map.panTo(position);
        }

    };

    // Function to get current location
    const getCurrentLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;

                    const address = await reverseGeocode(latitude, longitude);
                    console.log(address)
                    const currentPosition = { lat: latitude, lng: longitude, address };
                    setCurrentLocation(currentPosition);
                    setMarker(currentPosition);

                    const geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode({ location: currentPosition }, (results, status) => {
                        if (status === 'OK' && results[0]) {
                            setAddress(results[0].formatted_address);
                        }
                    });

                },

                (error) => {
                    console.error(error.message);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };


    const reverseGeocode = async (latitude, longitude) => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GMAPS_API_KEY}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            const results = data.results;
            const address = results[0].formatted_address;
            return address;

        } catch (error) {
            console.error('Error reverse geocoding:', error);
            return '';
        }
    };

    const mapOptions = {
        disableDefaultUI: true, // Hide default controls
    };

    // useEffect(() => {
    //     getCurrentLocation();
    // }, []);


    return (

        <div>
            <Nav/>


            {/* <div class=" hidden md:flex justify-center container mt-7 mx-auto">

                <ol class="flex items-center  ">
                    <li class="relative w-full mb-6">
                        <div class="flex items-center w-[200px]">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-600 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                                <svg aria-hidden="true" class="w-4 h-4 text-blue-100 dark:text-blue-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                            </div>
                            <div class="flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                        </div>
                        <div class="mt-3">
                            <h3 class="font-medium text-gray-900 dark:text-gray-900">Personal Info</h3>
                        </div>
                    </li>
                    <li class="relative  mb-6">
                        <div class="flex items-center w-[200px]">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-gray-200 rounded-full ring-0 ring-white dark:bg-gray-700 sm:ring-8 dark:ring-gray-900 shrink-0">
                                <svg aria-hidden="true" class="w-3 h-3 text-gray-800 dark:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </div>
                            <div class="flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                        </div>
                        <div class="mt-3">
                            <h3 class="font-medium text-gray-900 dark:text-gray-900">Business Details</h3>
                        </div>
                    </li>
                    <li class="relative  mb-6">
                        <div class="flex items-center w-[200px]">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-gray-200 rounded-full ring-0 ring-white dark:bg-gray-700 sm:ring-8 dark:ring-gray-900 shrink-0">
                                <svg aria-hidden="true" class="w-3 h-3 text-gray-800 dark:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </div>
                            <div class="flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                        </div>
                        <div class="mt-3">
                            <h3 class="font-medium text-gray-900 dark:text-gray-900">Services/Update</h3>
                        </div>
                    </li>
                    <li class="relative w-full mb-6">
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-gray-200 rounded-full ring-0 ring-white dark:bg-gray-700 sm:ring-8 dark:ring-gray-900 shrink-0">
                                <svg aria-hidden="true" class="w-3 h-3 text-gray-800 dark:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </div>

                        </div>
                        <div class="mt-3">
                            <h3 class="font-medium text-gray-900 dark:text-gray-900">Social Links</h3>
                        </div>
                    </li>
                </ol>
            </div> */}


            <a class="block max-w-sm container mx-auto  h-auto mb-5   mt-10 md:max-w-[700px]  p-6 bg-white border border-gray-200 rounded-lg shadow ">

                <form onSubmit={(e)=>handleCompanySubmit(e)}>
<span class=" text-blue-700 font-medium text-md  mt-4">Business Details</span>

                    <div class="grid  mt-3 md:grid-cols-2 md:gap-6">
                        <div class="relative z-0 w-full mb-6 group">
                            <input type="text" name="company_name" id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none 
                             dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Company </label>
                        </div>


                        <div class="relative z-0 w-full mb-6 group">
                            <input type="text" name="business_title" id="floating_last_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="floating_last_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Business Title/Category</label>
                        </div>
                    </div>


                       <span class=" text-gray-500  text-sm">Your Address</span>
                    <div class="w-full container shadow-sm h-auto rounded-lg border ">

                        {
                            isLoaded ? (
                                <>

                                    <div style={{ height: '400%', width: '100%' }}>



                                        <StandaloneSearchBox onLoad={(ref) => {
                                            setSearchBox(ref);
                                            //   searchBoxRef.current = ref?.input;
                                        }} onPlacesChanged={handlePlacesChanged}>
                                            <Autocomplete>
                                                <input class="block text-center  py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer mb-5" type="text" placeholder="Search for a location" />
                                            </Autocomplete>

                                        </StandaloneSearchBox>

                                        <GoogleMap  class="border" onLoad={handleMapLoad} center={currentLocation} zoom={15}
                                            options={mapOptions}
                                            mapContainerStyle={{ width: '100%', height: '200px' }}>
                                            {markerPosition && <Marker position={markerPosition} />}

                                        </GoogleMap>

                                        <div onClick={getCurrentLocation} class="flex justify-center text-blue-700 gap-1 mt-3 mb-3">
                                            <span class="mt-1 text-md hover:cursor-pointer">Current Location </span>

                <div class="w-5 h-5 mt-2 mx-1 rounded-full bg-blue-700 ">
                    <FaLocationArrow  color="white"  data-tooltip-id="my-tooltip" size={12} data-tooltip-content="Current Location" class=" text-lg mx-auto mt-1 mb-1 text-blue-700">

                    </FaLocationArrow>

                                            </div>

                                            <Tooltip id="my-tooltip" class="rounded-xl " color="blue" />
                                        </div>

                                        <textarea id="message" ref={textareaRef} name="address" value={address} onChange={(e) => setAddress(e.target.value)} rows="3" class="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 " placeholder="Your Address..."></textarea>



                                    </div>
                                </>

                            ) :
                                <div>Loading...</div>

                        }
                    </div>

                    {/* <div class="grid md:grid-cols-2 md:gap-6">
                        <div class="relative z-0 w-full mb-6 group">
                            <input type="text" name="address" id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Business Location</label>
                        </div>


                        <div class="relative z-0 w-full mb-6 group">
                            <input type="text" name="link" id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Business  Website Link</label>
                        </div>
                    </div> */}

                    {/* <div class="grid md:grid-cols-2 md:gap-6">
                        <div class="relative z-0 w-full mb-6 group">
                            <input type="text" name="jobpostion" id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Designation</label>
                        </div>
                    </div> */}




                    <label for="message" class="block mb-2 text-md font-medium text-blue-700  mt-3 dark:text-gray-500">About Your Business</label>
                    <textarea id="message" rows="4" name="about_us" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none shadow-md
        " placeholder="Write your thoughts here..."></textarea>


                    <div class="mb-3 flex gap-3">
                    <Link to='/user-details' class="flex mt-5 justify-center md:justify-start"><FcPrevious size={30}  color="#1D4ED8"/></Link>

                        <button type="submit" class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Next</button>


                    

                      

                
{/* <button type="submit" class="text-white mx-4 mt-4 bg-red-600 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 shadow-md
                        "><Link to='/'>Cancel</Link></button> */}
                    </div>
                </form>

            </a>

        </div>


    )
}

export default CompanyDetails
