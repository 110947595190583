import React from "react";
import Cards from "./cards";

const Dashboard = () => {
  return (
    <div className="px-2 xl:px-10 h-auto">
      <div>
        <Cards />
      </div>
    </div>
  );
};

export default Dashboard;
