import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetToken } from "../../services/localstorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrFormClose } from "react-icons/gr";
import { HiOutlinePlay } from "react-icons/hi2";
import { MdOutlinePermMedia } from "react-icons/md";

const ProductUpdate = ({ product }) => {
  console.log("product", product);
  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_PUBLIC_URL;

  const { access_token } = GetToken();

  const config = {
    headers: {
      authorization: `Bearer ${access_token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const product_url = base_url + `product-detail/?id=${product.id}`;
  // Declare uploadedMedia state
  const [uploadedMedia, setUploadedMedia] = useState([]);

  console.log("uploadedMedia", uploadedMedia);

  // Define the handleMediaUpload function
  const handleMediaUpload = async (e) => {
    const mediaFiles = e.target.files;
    if (mediaFiles.length === 0) return;

    const formData = new FormData();

    const imageFiles = [];
    const videoFiles = [];

    for (let i = 0; i < mediaFiles.length; i++) {
      const file = mediaFiles[i];
      if (file.type.startsWith("image/")) {
        imageFiles.push(file);
      } else if (file.type.startsWith("video/")) {
        videoFiles.push(file);
      }
    }

    // Append image files
    for (let i = 0; i < imageFiles.length; i++) {
      formData.append("imageFiles", imageFiles[i]);
    }

    // Append video files
    for (let i = 0; i < videoFiles.length; i++) {
      formData.append("videoFiles", videoFiles[i]);
    }

    try {
      toast.warning("Uploading Media...");
      const response = await axios.put(
        `${base_url}product-detail/?id=${product.id}`,
        formData,
        config
      );

      if (response.status === 200) {
        setUploadedMedia((prevMedia) => [...prevMedia, ...mediaFiles]);
        toast.success("Media uploaded successfully!");
        e.target.value = null;
      } else {
        console.error("Failed to upload media. Response:", response);
        toast.error("Failed to upload media");
      }
    } catch (error) {
      console.error("Error uploading media", error);
      toast.error("Error uploading media");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedData = {
        id: product.id,
        title: e.target[`title`].value,
        description: e.target[`description`].value,
      };

      // Create FormData for media uploads
      const formData = new FormData();
      formData.append("id", updatedData.id);
      formData.append("title", updatedData.title);
      formData.append("description", updatedData.description);

      // // Append image and video files
      // for (let i = 0; i < uploadedMedia.length; i++) {
      //   if (uploadedMedia[i].type.startsWith("image/")) {
      //     formData.append("imageFiles", uploadedMedia[i]);
      //   } else if (uploadedMedia[i].type.startsWith("video/")) {
      //     formData.append("videoFiles", uploadedMedia[i]);
      //   }
      // }
      const updateResponse = await axios.put(product_url, formData, config);
      console.log(
        "Product details and media updated successfully!",
        updateResponse.data
      );
      toast.success("Product details and media updated successfully!");
      setTimeout(() => {
        navigate("/update");
      }, 6000);
    } catch (error) {
      console.error("Error updating Product details and media", error);
      toast.error("Error updating Product details and media");
    }
  };
  // delete single media file
  const [deletedMedia, setDeletedMedia] = useState([]);
  console.log("deletedMedia", deletedMedia);
  const handleDeleteMedia = async (mediaId) => {
    try {
      const response = await axios.delete(
        `${base_url}media/delete/?product_id=${product.id}&media_id=${mediaId}`
      );

      if (response.status === 200) {
        setDeletedMedia((prevMedia) => [...prevMedia, mediaId]);
        toast.success("Media deleted successfully!");
      } else {
        toast.error("Failed to delete media");
      }
    } catch (error) {
      console.error("Error deleting media", error);
      toast.error("Error deleting media");
    }
  };

  // delete the product
  const handleDeleteProduct = async () => {
    try {
      const response = await axios.delete(
        `${base_url}product-detail/?id=${product.id}`,
        config
      );

      if (response.status === 200) {
        toast.success("Product deleted successfully!");
        setTimeout(() => {
          navigate("/update");
        }, 4000);
      } else {
        toast.error("Failed to delete product");
      }
    } catch (error) {
      console.error("Error deleting product", error);
      toast.error("Error deleting product");
    }
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <div class="rounded-lg shadow-lg">
          <div class="mb-6 p-3">
            <label
              htmlFor="title"
              class="block mb-2 text-md se font-medium text-gray-600  "
            >
              Your Product Category
            </label>
            <input
              name="title"
              id="title"
              class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 focus:outline-none focus:ring-0 peer"
              placeholder="Title here..."
              defaultValue={product.title}
            />

            <div class="mb-6">
              <label
                htmlFor="description"
                class="block mb-2 mt-4 text-md  font-medium text-gray-600 dark:text-white"
              >
                Product Description
              </label>
              <textarea
                name="description"
                id="description"
                class="block p-2.5 h-28 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 outline-none"
                placeholder="Write something about your business/product here...."
                defaultValue={product.description}
              />
            </div>
            <div>
              <input
                type="file"
                name="media"
                id="media"
                accept="image/jpeg,image/jpg,image/png,video/mp4,video/mkv"
                className="hidden"
                multiple
                onChange={handleMediaUpload}
              />
              <label htmlFor="media" className="flex gap-3 items-center">
                <span className="cursor-pointer text-blue-500 hover:underline p-1 flex gap-1 items-center">
                  <span>Add Media</span>
                  <MdOutlinePermMedia />
                </span>
                <span className="text-sm text-gray-400">
                  ({uploadedMedia && uploadedMedia.length} New Files Added)
                </span>
              </label>
            </div>
            {/* image preview for users  */}
            {/* <div className="text-sm text-gray-400 p-1">Images Preview:</div> */}
            <span className="mx-3 text-sm text-gray-400">Images :</span>
            {/* Images */}
            <span className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-7 p-1">
              {product.gallery &&
                product.gallery.map((image, index) =>
                  // Check if the image ID is in the deletedMedia state, and if so, don't render it
                  !deletedMedia.includes(image.id) ? (
                    <span className="relative" key={index}>
                      <GrFormClose
                        onClick={() => handleDeleteMedia(image.id)}
                        className="h-5 w-5 bg-white rounded-full absolute right-1.5 -top-1 md:-right-1"
                      />
                      <Link to={image.image} target="_blank">
                        <img
                          key={image.id}
                          src={image.image}
                          alt={image.title}
                          className="h-20 w-20 border-2 m-1 rounded-lg"
                        />
                      </Link>
                    </span>
                  ) : null
                )}
            </span>

            <span className="mx-3 text-sm text-gray-400">Videos :</span>
            {/* Videos */}
            <span className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-7">
              {product.videos &&
                product.videos.map((videos, index) =>
                  // Check if the video ID is in the deletedMedia state, and if so, don't render it
                  !deletedMedia.includes(videos.id) ? (
                    <span className="relative" key={index}>
                      <GrFormClose
                        onClick={() => handleDeleteMedia(videos.id)}
                        className="h-5 w-5 bg-white rounded-full absolute right-1.5 -top-1 md:-right-1"
                      />
                      <Link to={videos.video} target="_blank">
                        <HiOutlinePlay className="top-[40%] left-[40%] absolute text-white" />
                        <img
                          key={videos.id}
                          src={videos.vid_thumbnail}
                          alt={videos.title}
                          className="h-20 w-20 border-2 m-1 rounded-lg"
                        />
                      </Link>
                    </span>
                  ) : null
                )}
            </span>
          </div>
        </div>
        <div class="mb-3 mx-2 sm:space-x-2">
          <button
            type="submit"
            class="text-white mt-4  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 shadow-md"
          >
            Update
          </button>
          <button
            type="button"
            onClick={handleDeleteProduct}
            className="text-white mt-4  bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 shadow-md"
          >
            Delete Product
          </button>

          <ToastContainer />
        </div>
      </form>
    </div>
  );
};

export default ProductUpdate;
