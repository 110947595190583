import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  Tooltip,
  PieChart,
  ResponsiveContainer,
  Pie,
  Cell,
  Legend,
} from "recharts";
import axios from "axios";
import WeekUserData from "./Charts/UserCharts/Last7Days";
import YesterdayUserData from "./Charts/UserCharts/YesterdayUserData";
import TodayUserData from "./Charts/UserCharts/TodayUserData";
import MonthUserData from "./Charts/UserCharts/Last28Days";
import QuarterUserData from "./Charts/UserCharts/Last90Days";
import Today from "./Charts/ViewCharts/Today";
import Yesterday from "./Charts/ViewCharts/Yesterday";
import Last7Days from "./Charts/ViewCharts/Last7Days";
import Last28Days from "./Charts/ViewCharts/Last28Days";
import Last90Days from "./Charts/ViewCharts/Last90Days";
import IpAddressTableToday from "./Charts/ViewCharts/IpAddressTable";

// CHART FOR TOTAL USERS (AREA CHART)        =======================================================================================

export function UserGain() {
  const [userGrowthData, setUserGrowthData] = useState([]);
  const access_token = localStorage.getItem("access_token");

  const fetchUserGrowthData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      const currentDate = new Date();
      const last7Days = Array.from({ length: 7 }, (_, i) => {
        const date = new Date(currentDate);
        date.setDate(currentDate.getDate() - i);
        return date.toDateString();
      }).reverse();

      const userCountsByDay = last7Days.map((date) => {
        const userCount = response.data.filter(
          (item) => new Date(item.date_joined).toDateString() === date
        ).length;
        return {
          day: date,
          userCount: userCount,
        };
      });

      setUserGrowthData(userCountsByDay);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchUserGrowthData(); // Initial data fetch

    // Fetch new data every 5 minutes (adjust interval as needed)
    const dataFetchInterval = setInterval(fetchUserGrowthData, 5 * 60 * 1000);

    // Cleanup: Clear interval when component unmounts
    return () => clearInterval(dataFetchInterval);
  }, []);

  return (
    <div>
      <ResponsiveContainer width="100%" height={100}>
        <AreaChart data={userGrowthData}>
          <Area
            type="monotone"
            dataKey="userCount"
            stroke="#55e0cb"
            fill="#55e0cb"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

// free user data function (PIE CHART)     ============================================================================================
export function FreeUsers() {
  const [users, setUsers] = useState([]);
  const [freeUser, setFreeUser] = useState(0);
  let access_token = localStorage.getItem("access_token");

  const COLORS = ["#0088FE", "#55e0cb"]; // Colors for the pie chart segments

  const pieChartData = [
    { name: "Free Users", value: freeUser },
    { name: "Paid Users", value: users - freeUser },
  ];

  const fetchUserCountData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}admin/dashboard/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setUsers(response.data.length);
      const TotalFalseValues = response.data.filter(
        (item) => item.is_superuser === false
      ).length;
      setFreeUser(TotalFalseValues);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchUserCountData();
  }, []);
  return (
    <div>
      <h2></h2>
      <PieChart width={200} height={100}>
        <Pie
          data={pieChartData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={45}
          fill="#55e0cb"
          label
        >
          {pieChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>

        <Tooltip />
      </PieChart>
    </div>
  );
}

// COMBINED/SELECTED FOR USERS CHARTS =========================================================================================================================

export function CombinedCharts() {
  const [selectedOption, setSelectedOption] = useState("chart1"); // Default selected option

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const renderChart = () => {
    switch (selectedOption) {
      case "chart1":
        return <TodayUserData />; //today
      case "chart2":
        return <YesterdayUserData />; //yesterday
      case "chart3":
        return <WeekUserData />; //last 7 days
      case "chart4":
        return <MonthUserData />; //last 28 days
      case "chart5":
        return <QuarterUserData />; //last 90 days

      default:
        return null;
    }
  };

  return (
    <div>
      <div>{renderChart()}</div>
      <select
        value={selectedOption}
        onChange={handleSelectChange}
        className="p-2 rounded shadow-md font-semibold text-sm"
      >
        <option value="chart1" className="">
          Today
        </option>
        <option value="chart2">Yesterday</option>
        <option value="chart3">Last 7 days</option>
        <option value="chart4">Last 28 days</option>
        <option value="chart5">Last 90 days</option>
      </select>
    </div>
  );
}

// VIEWS SECTION

// ========================================================================================================================================================================
// ========================================================================================================================================================================

// COMBINED CHART FOR VIEWS

export function CombinedViewCharts() {
  const [selectedOption, setSelectedOption] = useState("chart1"); // Default selected option

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const renderChart = () => {
    switch (selectedOption) {
      case "chart1":
        return <Today />; //today
      case "chart2":
        return <Yesterday />; //yesterday
      case "chart3":
        return <Last7Days />; //last 7 days
      case "chart4":
        return <Last28Days />; //last 28 days
      case "chart5":
        return <Last90Days />; //last 90 days

      default:
        return null;
    }
  };

  return (
    <div>
      <div className="text-center pb-2 font-semibold">Views on Cards</div>
      <div>{renderChart()}</div>
      <select
        value={selectedOption}
        onChange={handleSelectChange}
        className="p-2 rounded shadow-md font-semibold text-sm"
      >
        <option value="chart1" className="">
          Today
        </option>
        <option value="chart2">Yesterday</option>
        <option value="chart3">Last 7 days</option>
        <option value="chart4">Last 28 days</option>
        <option value="chart5">Last 90 days</option>
      </select>
    </div>
  );
}

// VISITORS ========================>>>>>>>>>>>>>>>>>>>>>>>>

export function Visitors() {
  const [visitorData, setVisitorData] = useState([]);
  let access_token = localStorage.getItem("access_token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_URL}analytics/device-data/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        // Calculate the date 7 days ago from today
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        // Filter the data for the last 7 days
        const filteredData = response.data.filter((entry) => {
          const entryDate = new Date(entry.timestamp);
          return entryDate >= sevenDaysAgo;
        });

        // Create an object to store visitor counts for each IP address
        const ipCounts = {};

        filteredData.forEach((entry) => {
          const ipAddress = entry.ip_address.ip_address;
          if (ipCounts[ipAddress]) {
            ipCounts[ipAddress]++;
          } else {
            ipCounts[ipAddress] = 1;
          }
        });

        // Convert the object to an array of objects for the chart
        const chartData = Object.entries(ipCounts).map(
          ([ipAddress, count]) => ({
            ipAddress,
            visitorCount: count,
          })
        );

        setVisitorData(chartData);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchData();
  }, [access_token]);

  return (
    <div>
      <ResponsiveContainer width="100%" height={100}>
        <AreaChart
          data={visitorData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <Area
            type="monotone"
            dataKey="visitorCount"
            name="Visitors"
            stroke="#f39c12"
            fill="#f39c12"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
// VIEWS
export function Views() {
  const [visitorData, setVisitorData] = useState([]);
  const access_token = localStorage.getItem("access_token");

  const fetchVisitorData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}analytics/device-data/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      const visitorCountsByDay = response.data.reduce((accumulator, entry) => {
        const entryDate = new Date(entry.timestamp).toDateString();
        const existingEntry = accumulator.find(
          (item) => item.day === entryDate
        );

        if (existingEntry) {
          existingEntry.totalViews += 1;
        } else {
          accumulator.push({
            day: entryDate,
            totalViews: 1,
            entryTime: entry.timestamp,
          });
        }

        return accumulator;
      }, []);

      setVisitorData(visitorCountsByDay);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchVisitorData();
    const dataFetchInterval = setInterval(fetchVisitorData, 5 * 60 * 1000);
    return () => clearInterval(dataFetchInterval);
  }, []);

  return (
    <div>
      <ResponsiveContainer width="100%" height={100}>
        <AreaChart data={visitorData}>
          <Area
            type="monotone"
            dataKey="totalViews"
            stroke="#e046e6"
            fill="#e046e6"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

// BROWSER COMPARISION

export function BrowserPieChart() {
  const COLORS = ["#0d9db7", "#ff7834", "#0041c4", "#00c5a1", "#f331b2"]; // Add more colors if needed
  let access_token = localStorage.getItem("access_token");
  const [dynamicData, setDynamicData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}analytics/device-data/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDynamicData(response.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Process data to calculate browser counts
  const browserCounts = dynamicData.reduce((counts, item) => {
    const browser = item.browser; // Replace with the actual property name
    counts[browser] = (counts[browser] || 0) + 1;
    return counts;
  }, {});

  // Generate pie chart data
  const pieChartData = Object.keys(browserCounts).map((browser) => ({
    name: browser,
    count: browserCounts[browser],
  }));

  return (
    <>
      <div className="sm:hidden">
        <ResponsiveContainer width="90%" height={350}>
          <PieChart>
            <Pie
              dataKey="count"
              isAnimationActive={true}
              data={pieChartData}
              cx={170}
              cy={150}
              outerRadius={95}
              fill="#8884d8"
              label={(entry) =>
                entry.name
                  .split(" ")
                  .map((part) => part[0])
                  .join("")
              }
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend wrapperStyle={{ left: 32 }} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="w-full hidden sm:block lg:hidden">
        <ResponsiveContainer width="90%" height={250}>
          <PieChart>
            <Pie
              dataKey="count"
              isAnimationActive={true}
              data={pieChartData}
              cx={125}
              cy={100}
              outerRadius={70}
              fill="#8884d8"
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend wrapperStyle={{ left: 30 }} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="w-full hidden lg:block ">
        <ResponsiveContainer width="90%" height={300}>
          <PieChart>
            <Pie
              dataKey="count"
              isAnimationActive={true}
              data={pieChartData}
              cx={150}
              cy={120}
              outerRadius={90}
              fill="#8884d8"
              label={(entry) =>
                entry.name
                  .split(" ")
                  .map((part) => part[0])
                  .join("")
              }
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend wrapperStyle={{ left: 30 }} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

// PC or Mobile Check

export function DeviceFamilyPieChart() {
  const COLORS = ["#00c5a1", "#ff7834", "#0041c4", "#3ef62d", "#f331b2"]; // Add more colors if needed
  let access_token = localStorage.getItem("access_token");
  const [dynamicData, setDynamicData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}analytics/device-data/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDynamicData(response.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Process data to calculate device family counts
  const deviceFamilyCounts = dynamicData.reduce((counts, item) => {
    const deviceFamilies =
      item.device_family === "Other" ? ["PC"] : [item.device_family];
    deviceFamilies.forEach((family) => {
      counts[family] = (counts[family] || 0) + 1;
    });
    return counts;
  }, {});

  // Generate pie chart data
  const pieChartData = Object.keys(deviceFamilyCounts).map((deviceFamily) => ({
    name: deviceFamily,
    count: deviceFamilyCounts[deviceFamily],
  }));

  return (
    <>
      <div className="-ml-0 sm:-ml-0 sm:hidden">
        <ResponsiveContainer width="100%" height={350}>
          <PieChart>
            <Pie
              dataKey="count"
              isAnimationActive={true}
              data={pieChartData}
              cx={180}
              cy={130}
              outerRadius={95}
              innerRadius={50}
              fill="#8884d8"
              label={(entry) =>
                entry.name
                  .split(" ")
                  .map((part) => part[0])
                  .join("")
              }
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="hidden sm:block lg:hidden">
        <ResponsiveContainer width="100%" height={350}>
          <PieChart>
            <Pie
              dataKey="count"
              isAnimationActive={true}
              data={pieChartData}
              cx={100}
              cy={130}
              outerRadius={80}
              innerRadius={40}
              fill="#8884d8"
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend wrapperStyle={{ left: 30 }} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="hidden lg:block">
        <ResponsiveContainer width="100%" height={350}>
          <PieChart>
            <Pie
              dataKey="count"
              isAnimationActive={true}
              data={pieChartData}
              cx={150}
              cy={130}
              outerRadius={95}
              innerRadius={50}
              fill="#8884d8"
              label={(entry) =>
                entry.name
                  .split(" ")
                  .map((part) => part[0])
                  .join("")
              }
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend wrapperStyle={{ left: 30 }} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

// OPRATING SYSYTEM

export function OperatingSystemPieChart() {
  const COLORS = ["#f331b2", "#ff7834", "#0041c4", "#3ef62d"]; // Add more colors if needed
  let access_token = localStorage.getItem("access_token");
  const [dynamicData, setDynamicData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}analytics/device-data/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDynamicData(response.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Process data to calculate operating system counts
  const operatingSystemCounts = dynamicData.reduce((counts, item) => {
    const operatingSystem =
      item.operating_system === "Other" ? "Other" : item.operating_system;
    counts[operatingSystem] = (counts[operatingSystem] || 0) + 1;
    return counts;
  }, {});

  // Generate pie chart data
  const pieChartData = Object.keys(operatingSystemCounts).map(
    (operatingSystem) => ({
      name: operatingSystem,
      count: operatingSystemCounts[operatingSystem],
    })
  );

  return (
    <>
      <div className="sm:hidden">
        <ResponsiveContainer width="100%" height={350}>
          <PieChart>
            <Pie
              dataKey="count"
              isAnimationActive={true}
              data={pieChartData}
              cx={170}
              cy={130}
              outerRadius={95}
              innerRadius={50}
              fill="#8884d8"
              label={(entry) =>
                entry.name
                  .split(" ")
                  .map((part) => part[0])
                  .join("")
              }
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend wrapperStyle={{ left: 30 }} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="hidden sm:block lg:hidden">
        <ResponsiveContainer width="100%" height={350}>
          <PieChart>
            <Pie
              dataKey="count"
              isAnimationActive={true}
              data={pieChartData}
              cx={100}
              cy={130}
              outerRadius={80}
              innerRadius={40}
              fill="#8884d8"
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend wrapperStyle={{ left: 30 }} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="hidden lg:block  mx-auto">
        <ResponsiveContainer width="100%" height={350}>
          <PieChart>
            <Pie
              dataKey="count"
              isAnimationActive={true}
              data={pieChartData}
              cx={150}
              cy={130}
              outerRadius={95}
              innerRadius={50}
              fill="#8884d8"
              label={(entry) =>
                entry.name
                  .split(" ")
                  .map((part) => part[0])
                  .join("")
              }
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend wrapperStyle={{ left: 30 }} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

// IP ADDRESS TABLE

export function CombinedIpAddress() {
  const [selectedOption, setSelectedOption] = useState("chart1"); // Default selected option

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const renderChart = () => {
    switch (selectedOption) {
      case "chart1":
        return (
          <>
            <IpAddressTableToday />
          </>
        ); //today
      case "chart2":
        return <></>; //yesterday
      case "chart3":
        return <></>; //last 7 days

      default:
        return null;
    }
  };

  return (
    <div>
      <div className="text-center pb-2 font-semibold">Views on Cards</div>
      <div>{renderChart()}</div>
      <select
        value={selectedOption}
        onChange={handleSelectChange}
        className="p-2 rounded shadow-md font-semibold text-sm"
      >
        <option value="chart1" className="">
          Today
        </option>
        <option value="chart2">Yesterday</option>
        <option value="chart3">Last 7 days</option>
      </select>
    </div>
  );
}
