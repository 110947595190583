import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { ToastContainer, toast } from "react-toastify";

const Otp = () => {
  const [otpsend, setotpsend] = useState(false);
  const [error, seterror] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const url = process.env.REACT_APP_PUBLIC_URL;

  const handleotp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const fromdata = new FormData(e.currentTarget);
    const data = {
      otp: fromdata.get("otp"),
      user_key: window.sessionStorage.getItem("user_key"),
    };
    await axios
      .post(url + "verify/", data)
      .then((response) => {
        setLoading(false);
        toast.success("OTP Verified", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigate("/create-password");
        }, 3000);
        // navigate('/create-password')
      })
      .catch((error) => {
        seterror(error.response.data);
        setLoading(false);
      });
  };

  const resend = () => {
    setLoading(true);
    axios
      .get(url + "resend/" + window.sessionStorage.getItem("user_key"))
      .then((response) => {
        setotpsend(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loading class="flex justify-center" /> : ""}

      <div className="container mx-auto h-[320px] justify-center  flex mt-10">
        <a
          href="#"
          className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-lg  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
        >
          <h5 className="mb-2 text-2xl text-center font-bold tracking-tight text-blue-600 dark:text-white">
            Please check your mail inbox for verification code.
          </h5>
          <p className="font-normal text-center text-lg text-gray-500 dark:text-gray-400">
            Valid for 10 minutes
          </p>
          {otpsend && <span>Otp sent successfully!</span>}

          <form onSubmit={(e) => handleotp(e)}>
            <input
              type="number"
              id="user_key"
              name="otp"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              required
            />

            {error.status ? <p>{error.status}</p> : ""}

            <div className="flex ">
              <button
                type="submit"
                className="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Next
              </button>

              <button
                type="button"
                onClick={resend}
                className="text-white mx-3 mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800  "
              >
                Resend otp
              </button>
            </div>
            <div className="mt-5 text-blue-700 mx-1">
              <Link to="/login" className="">
                Already Registered? Sign in
              </Link>
            </div>
          </form>
        </a>
        <ToastContainer />
      </div>
    </>
  );
};

export default Otp;
