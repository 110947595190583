import React from "react";
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Authenticate/Signup";
import PersonallDetails from "./components/Card/PersonallDetails";
import CompanyDetails from "./components/Card/CompanyDetails";
import Service from "./components/Card/Service";
import Social from "./components/Card/Social";
import Card from "./components/Card/Card";
import PersonalUpdate from "./components/Card/PersonalUpdate";
import CompanyUpdate from "./components/Card/CompanyUpdate";
import { useSelector } from "react-redux";
import ServiceUpdate from "./components/Card/ServiceUpdate";
import SocialUpdate from "./components/Card/SocialUpdate";
import CardShare from "./components/Card/CardShare";
// import Demo from "./components/Demo";
import ForgetPassword from "./components/Authenticate/ForgetPassword";
import Otp from "./components/Authenticate/Otp";
import CreatePassword from "./components/Authenticate/CreatePassword";
import ChooseTemplates from "./components/ChooseTemplates";
// import Footer from "./components/Footer";

import Otpverify from "./components/Authenticate/Otpverify";
import Design from "./components/Card/Design";
// admin imports
import Admin from "./components/admin/admin";
import UserManagement from "./components/admin/sidebar/userManagement/UserManagement";
import AccountSetting from "./components/admin/sidebar/accountSetting/AccountSetting";
import Analytics from "./components/admin/sidebar/analytics/Analytics";
import ViewUser from "./components/admin/sidebar/userManagement/ViewUser";
import EditUser from "./components/admin/sidebar/userManagement/EditUser";
import EditProfile from "./components/EditProfile";

function App() {
  const { access_token } = useSelector((state) => state.auth);
  // const {isAuthenticated}=useAuth0()
  //    console.log(isAuthenticated)
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/login"
            element={access_token ? <Home /> : <Login />}
          />
          <Route
            path="/signup"
            element={access_token ? <Home /> : <Signup />}
          />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route
            path="/choose"
            element={access_token ? <ChooseTemplates /> : <Login />}
          >
            <Route path=":id" element={<ChooseTemplates />} />
          </Route>
          {/* update template  */}
          <Route
            path="/template-update"
            element={access_token ? <ChooseTemplates /> : <Login />}
          >
            <Route path=":id" element={<ChooseTemplates />} />
          </Route>
          <Route
            path="/user-details"
            element={access_token ? <PersonallDetails /> : <Login />}
          />
          <Route
            path="/company-details"
            element={access_token ? <CompanyDetails /> : <Login />}
          />
          <Route
            path="/update-work"
            element={access_token ? <CompanyUpdate /> : <Login />}
          />
          <Route
            path="/product"
            element={access_token ? <Service /> : <Login />}
          >
            <Route path=":id" element={<Service />} />
          </Route>
          <Route
            path="/product-update"
            element={access_token ? <ServiceUpdate /> : <Login />}
          >
            <Route path=":id" element={<ServiceUpdate />} />
          </Route>
          <Route
            path="/social"
            element={access_token ? <Social /> : <Login />}
          />
          <Route
            path="/social-update"
            element={access_token ? <SocialUpdate /> : <Login />}
          />
          {/* <Route path="/card" element={access_token ? <Card /> : <Login />} /> */}

          <Route path="/verify" element={<Otpverify />} />

          <Route path="/update" element={<PersonalUpdate />}>
            <Route path=":id" element={<PersonalUpdate />} />
          </Route>
          <Route path="/shared-card" element={<CardShare />}>
            <Route path=":id" element={<CardShare />} />
          </Route>

          <Route path="/design" element={<Design />}>
            <Route path=":id/:link" element={<Design />} />
          </Route>
          <Route path="/reset-password" element={<ForgetPassword />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/create-password" element={<CreatePassword />} />

          {/* admin routes  */}

          <Route path="/dashboard" element={<Admin />} />
          <Route
            path="/dashboard/user-management"
            element={<UserManagement />}
          />
          <Route
            path="/dashboard/account-setting"
            element={<AccountSetting />}
          />
          <Route path="/dashboard/analytics" element={<Analytics />} />
          <Route path="/dashboard/:id" element={<ViewUser />} />
          <Route path="/dashboard/edit-user/:id" element={<EditUser />} />
        </Routes>
      </BrowserRouter>
      {/* <Footer/> */}
    </>
  );
}

export default App;
