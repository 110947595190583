import React from "react";
import Dropdown from "./dropdown";
import { Link } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import logo from "../../images/img.png";

const Navbar = () => {
  return (
    <nav className="border-b flex items-center justify-between w-auto flex-wrap bg-white p-3 md:py-6 md:px-4 sticky top-0 ">
      <div className="">
        <Link to={"/dashboard"}>
          <img src={logo} className="h-6 md:h-8" alt="logo" />
        </Link>
      </div>
      <div className="lg:hidden ">
        <span>
          <Dropdown />
        </span>
      </div>
      <div className="gap-1 items-center hidden lg:flex cursor-pointer ">
        <ProfileDropdown />
      </div>
    </nav>
  );
};

export default Navbar;
