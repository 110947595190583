import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "../../../userManagement/UserTable.css";
import axios from "axios";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

const IpAddressTableAllTime = () => {
  const [loading, setLoading] = useState(true);
  const [aggregatedData, setAggregatedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [sortOrder, setSortOrder] = useState("desc"); // Added sorting order state
  let access_token = localStorage.getItem("access_token");

  // Fetch all-time data
  const fetchAllTimeUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}analytics/device-data/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      // Aggregate data by ip_address
      const dataByIpAddress = {};
      response.data.forEach((item) => {
        const ipAddress = item.ip_address.ip_address;
        if (dataByIpAddress[ipAddress]) {
          dataByIpAddress[ipAddress].hit_count += item.ip_address.hit_count;
        } else {
          dataByIpAddress[ipAddress] = {
            ipAddress,
            hit_count: item.ip_address.hit_count,
          };
        }
      });

      // Convert the aggregated data into an array
      const aggregatedDataArray = Object.values(dataByIpAddress);

      // Sort the data by hit_count based on sortOrder
      const sortedData =
        sortOrder === "asc"
          ? [...aggregatedDataArray].sort((a, b) => a.hit_count - b.hit_count)
          : [...aggregatedDataArray].sort((a, b) => b.hit_count - a.hit_count);

      setAggregatedData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllTimeUserData();
  }, [sortOrder]); // Listen to sortOrder changes

  const lastIndex = currentPage * usersPerPage;
  const firstIndex = lastIndex - usersPerPage;
  const usersToDisplay = aggregatedData.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(aggregatedData.length / usersPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  // Function to toggle sorting order
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <div className="">
      {loading ? (
        <p className="text-center">Please Be Patient...</p>
      ) : (
        <div className="w-full">
          <div className="overflow-x-auto">
            <div className="inline-block rounded min-w-full overflow-hidden">
              <table className="rounded w-full text-sm text-center text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                  <tr>
                    <th scope="col" className="px-4 py-3">
                      Sr.No.
                    </th>
                    <th scope="col" className="px-4 py-3">
                      IP Address
                    </th>
                    <th scope="col" className="px-4 py-3">
                      <button onClick={toggleSortOrder}>
                        Event Count
                        {sortOrder === "asc" ? " ↑" : " ↓"}
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {usersToDisplay.map((item, index) => (
                    <tr
                      className={`${
                        item.is_superuser ? "font-semibold" : ""
                      } border-b bg-white hover:bg-gray-100`}
                      key={index}
                    >
                      <td scope="row" className="px-2 py-2 whitespace-nowrap">
                        {firstIndex + index + 1}
                      </td>
                      <td scope="row" className="px-2 py-2">
                        {item.ipAddress}
                      </td>
                      <td scope="row" className="px-2 py-2 whitespace-nowrap">
                        {item.hit_count}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <ReactPaginate
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination flex"}
              activeClassName={"active"}
              previousLabel={<MdNavigateBefore />}
              nextLabel={<MdNavigateNext />}
              breakLabel={"..."}
              previousClassName={"previous"}
              nextClassName={"next"}
              pageLinkClassName={"page-link"}
              activeLinkClassName={"active"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default IpAddressTableAllTime;
