import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-modal";
import { HiOutlinePlay } from "react-icons/hi";
import { GrFormClose } from "react-icons/gr";
import { Link } from "react-router-dom";

const Carousel = ({ cardProducts, primary, secondary }) => {
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} rounded-full`}
        style={{ ...style, display: "block", background: "gray" }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} rounded-full`}
        style={{ ...style, display: "block", background: "gray" }}
        onClick={onClick}
      />
    );
  }
  const outerSliderSettings = {
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);

  function openModal(index) {
    setSelectedMediaIndex(index);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  // State to track the current slide index
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  const modalSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: selectedMediaIndex,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <div className="w-full mb-10 h-auto">
        <div className="flex gap-1">
          {cardProducts &&
            cardProducts.map((item, index) => (
              <div
                key={index}
                className={`h-1 w-full ${
                  currentSlide === index ? `bg-gray-200` : "bg-gray-400"
                }`}
              ></div>
            ))}
        </div>
        <Slider {...outerSliderSettings} afterChange={handleSlideChange}>
          {cardProducts.map((item, index) => (
            <div key={index} className="h-auto my-5">
              <div className="text-justify  break-words rounded">
                <div
                  className="font-medium mx-1 p-2 text-xl mt-2 text-center capitalize rounded"
                  style={{ backgroundColor: secondary }}
                >
                  {cardProducts[index].title}
                  <div className="h-0.5 w-20  mx-auto bg-white"></div>
                </div>
                <p
                  className="text-justify p-1 py-2 text-sm  mb-2 mt-3 mx-2  capitalize rounded"
                  style={{ color: secondary }}
                >
                  {cardProducts[index].description}
                </p>
              </div>
              {/* Gallery */}
              <div className="my-10 p-4 w-full h-screen overflow-y-auto ">
                <div
                  className="rounded p-0"
                  style={{ backgroundColor: secondary }}
                >
                  <h1 className="text-center font-semibold my-1 ">Gallary</h1>

                  <div
                    className="rounded grid grid-cols-3 "
                    style={{ backgroundColor: primary }}
                  >
                    {item &&
                      item.gallery.map((elem, i) => (
                        <div key={i} className="">
                          <img
                            src={elem.image}
                            alt="img"
                            className=" object-cover h-[100px] w-full rounded-lg p-0.5"
                            onClick={() => openModal(i)}
                          />
                        </div>
                      ))}
                    {item &&
                      item.videos.map((video, i) => (
                        <div key={i} className="">
                          <div
                            className="relative"
                            onClick={() => openModal(i + item.gallery.length)}
                          >
                            <span className="absolute left-9 top-9 text-2xl">
                              <HiOutlinePlay
                                onClick={() =>
                                  openModal(i + item.gallery.length)
                                }
                              />
                            </span>
                            {video && video.video ? (
                              <img
                                alt="img"
                                className="object-cover h-[100px] w-full rounded-lg p-0.5"
                                src="https://png.pngtree.com/thumb_back/fh260/background/20210207/pngtree-black-background-light-simple-image_556933.jpg"
                              />
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-slate-200 overflow-hidden rounded"
        >
          <div className="flex justify-end text-2xl p-2 ">
            <GrFormClose className="cursor-pointer" onClick={closeModal} />
          </div>
          <div className="w-80 mx-auto">
            <Slider {...modalSliderSettings} className="">
              {cardProducts[currentSlide] &&
                cardProducts[currentSlide].gallery.map((elem, i) => (
                  <div key={i} className="mt-2 mb-6 p-1">
                    <Link
                      to={elem.image}
                      className="cursor-pointer flex justify-center items-center h-[300px]"
                    >
                      <img
                        src={elem.image}
                        alt="img"
                        className="max-w-full max-h-[300px]"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                ))}
              {cardProducts[currentSlide] &&
                cardProducts[currentSlide].videos.map((video, i) => (
                  <div key={i} className="mt-2 p-1 ">
                    {video && video.video ? (
                      <div className="flex justify-center items-center h-[300px]">
                        <video
                          src={video.video}
                          controls
                          controlsList="nodownload"
                          className="max-w-full max-h-[300px]"
                          onError={() => console.log("Video failed to load")}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
            </Slider>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Carousel;
