import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleOAuthProvider>

  // </Auth0Provider>
);

// serviceWorkerRegistration.registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// const isStandalone = () =>
//   window.matchMedia("(display-mode: standalone)").matches ||
//   window.navigator.standalone ||
//   document.referrer.includes("android-app://");

// const isMobile =
//   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//     navigator.userAgent
//   );

// if (!isStandalone() && isMobile) {
//   const InstallButton = () => {
//     const handleInstallClick = () => {
//       alert('Please use the browser menu to "Add to Home Screen"');
//     };

//     return (
//       <button onClick={handleInstallClick} className="install-button">
//         Add to Home Screen
//       </button>
//     );
//   };

//   ReactDOM.render(
//     <Router>
//       {/* <InstallButton /> */}
//     </Router>,
//     document.getElementById("install-root")
//   );
// }

reportWebVitals();
