import React, { useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../Loading';

const ForgetPassword = () => {

    const [error, seterror] = useState({})
    const inputRef = useRef(null)
    const otpRef = useRef(null)
    const passwordRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const url = process.env.REACT_APP_PUBLIC_URL

    const handleForget = async (e) => {
        e.preventDefault();
        setLoading(true)
        const fromdata = new FormData(e.currentTarget);
        const data = {
            email: fromdata.get('username'),
        }
        await axios.post(url + "reset-password/", data).then(response => {
            setLoading(true)
            window.sessionStorage.setItem("user_key", response.data["user_key"]);
            
            setLoading(false)
            toast.warning('OTP Sent Successfully', {
                position: toast.POSITION.TOP_RIGHT
              });
              setTimeout(() => {
                navigate('/otp')
              }, 3000)

            // navigate('/otp')

        })
            .catch(error => {

                seterror(error.response.data)
                setLoading(false)
            })
    }


    // const handleotp = async (e) => {
    //     e.preventDefault();
    //     setLoading(true)
    //     const fromdata = new FormData(e.currentTarget);
    //     const data = {
    //         otp: fromdata.get('otp'),
    //         email: window.sessionStorage.getItem("email")
    //     }
    //     await axios.post(url + "verify/", data).then(response => {

    //         otpRef.current.className = "d-none"
    //         passwordRef.current.className = "d-block"
    //         setLoading(false)

    //     })
    //         .catch(error => {
    //             seterror(error.response.data)
    //             setLoading(false)


    //         })
    // }


    // const createpassword = async (e) => {
    //     e.preventDefault();
    //     setLoading(true)
    //     const fromdata = new FormData(e.currentTarget);
    //     const data = {
    //         password: fromdata.get('password'),
    //         cpassword: fromdata.get('cpassword'),
    //         username: window.sessionStorage.getItem("email")
    //     }
    //     await axios.post(url + "create-password/", data).then(response => {

    //         setLoading(false)
    //         navigate("/login")

    //     })
    //         .catch(error => {
    //             seterror(error.response.data)
    //             setLoading(false)
    //             console.log(error.response.data)

    //         })
    // }

    // const [otpsend, setotpsend] = useState(false)

    // const resend = () => {
    //     setLoading(true)
    //     axios.get(url + "resend/" + window.sessionStorage.getItem("email")).then(response => {
    //         setotpsend(true)
    //         setLoading(false)
    //     })
    //         .catch(error => {
    //             console.log(error)
    //             setLoading(false)
    //         })
    // }

    return (
        <>
            {loading? <Loading class="flex justify-center"/>:""}
            <div class="container mx-auto h-[320px] justify-center flex mt-10 ">

                <a href="#" class="block  max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow   ">
                    <h5 class="mb-2  text-blue-600 text-4xl font-bold tracking-tight text-center ">Digital Card</h5>

                      <div>

                       <div >

                        <p class="font-normal mt-3  dark:text-gray-400 text-center text-gray-800">Find your account</p>
                      


                        <form onSubmit={(e) => handleForget(e)}>
                            <div class="mb-6">


                                <input type="email" id="email" name="username" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="" required ref={inputRef}/>

                                {error.error ? <p>{error.error}</p> : ""}


                                <p class="text-gray-500 mt-2 text-center">Enter your Email</p>
                                
                        <button type="submit" class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        
                        Next
                        
                       

                        </button>

                            </div>
                        </form>
                        <div  class="text-gray-700 text-sm justify-center">Already registered? 

                            <Link to='/login' class="text-blue-700 text-sm justify-center"><span> Login here</span></Link>
                        </div>
                        <ToastContainer/>
                    </div>


{/* --------------------------------------------------- */}
                        {/* <div ref={otpRef} className='d-none'>
                            <p>Please check your email and enter the otp</p>
                            <span>Vaild for 10 minutes.</span>

                            {otpsend && <span>
                                   Otp resend successfull 
                                </span>}


                                <form onSubmit={(e)=>handleotp(e)}>
                                <input type="number" id="email" name="otp" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="" required />

                                {error.status?<p>
                                    {error.status}
                                </p>:""}

                                <button type="submit" class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                          
                                         Next</button>
                                    </form>
  
                                    <button type="button" onClick={resend} class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                           
                                      Resend otp</button>
                          <Link to='/login'>Go to Signin Page</   Link>
                                    
                        </div> */}

                   {/* ----------------- */}

                         {/* <div ref={passwordRef} class="d-none">
                           <span>Otp verified</span>
                           <p>Create your new password</p>


                           <form onSubmit={(e)=>createpassword(e)}>
                           <input  id="email" name="password" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="" required />

                           <input  id="email" name="cpassword" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="" required />

                           {error.error?<p>
                            {error.error}
                           </p>:""}

                           <button type="submit" onClick={resend} class="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                           
                           Submit</button>
                       
                           
                           </form>
                           <Link to='/login'>Go to Signin Page</   Link>
                         </div> */}


                   </div>
                </a>

            </div>

        </>
    )
}

export default ForgetPassword
