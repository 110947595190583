import React, { useState, useEffect } from "react";
import logo from "./images/img.png";
import { Link, useNavigate } from "react-router-dom";
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiFillInfoCircle,
  AiFillDashboard,
} from "react-icons/ai";
import { ImPriceTag } from "react-icons/im";
import { useDispatch } from "react-redux";
import { unsetUserAuth } from "../features/authSlice";
import { useGetUserProfileQuery } from "../services/UserApi";
import { FaUserCircle } from "react-icons/fa";
import {
  BsFillKeyFill,
  BsPatchExclamation,
  BsPower,
  BsSpeedometer2,
} from "react-icons/bs";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { setUserInfo } from "../features/userSlice";
import { useSelector } from "react-redux";
import { GetToken, RemoveToken } from "../services/localstorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdAttachMoney } from "react-icons/md";
import { FiEdit, FiPhoneCall } from "react-icons/fi";
import { PiCurrencyCircleDollar } from "react-icons/pi";
// import { useAuth0 } from "@auth0/auth0-react";

const Navbar = () => {
  const navigate = useNavigate();
  const { access_token } = GetToken();
  // const { isAuthenticated, user } = useAuth0();
  const { data, isSuccess } = useGetUserProfileQuery(access_token);
  // const navbarRef = useRef(null)
  const dispatch = useDispatch();

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(
        setUserInfo({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          mobile_number: data.mobile_number,
        })
      );
    }
  }, [data, isSuccess, dispatch]);

  const userData = useSelector((state) => state.user);

  const logout = () => {
    dispatch(unsetUserAuth({ access_token: null }));
    RemoveToken();
    toast.warning("Logged Out Successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  };

  // const [isOpen, setIsOpen] = useState(false);
  const [isUser, setIsUser] = useState(false);
  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  const toggleUser = () => {
    setIsUser(!isUser);
  };

  const [menu, setMenu] = useState(false);
  // Admin Badge Code
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    // Function to fetch user data using the JWT token
    const fetchUserData = async () => {
      const token = GetToken();

      if (token["access_token"] !== null) {
        try {
          const response = await fetch(baseUrl + "get-user-profile/", {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token["access_token"]}`,
            },
          });
          const userData = await response.json();
          setUserType(userData);
        } catch (error) {
          console.log("Error", error);
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <nav className=" mx-auto md:max-w-7xl   justify-center   container rounded-sm ">
        <div className="flex   justify-around  pt-3 py-3  ">
          <div className="flex  ">
            <Link to="/">
              <img className="pt-2 py-2  " src={logo} alt="Logo" />
            </Link>
          </div>

          <div className=" flex gap-3">
            {/* laptop view  */}
            {access_token ? (
              <>
                <div className="relative  hidden md:flex  ">
                  <Link className=" text-gray-700  hover:text-blue-600  px-2 lg:px-3 mt-6 rounded-md text-sm md:text-base lg:text-xl font-medium ">
                    <span className="flex items-center">
                      <PiCurrencyCircleDollar className="h-6 w-6" />
                      <span>Pricing</span>
                    </span>
                  </Link>
                  <Link
                    to={"/dashboard"}
                    className="text-gray-700  hover:text-blue-600  px-2 lg:px-3 mt-6 rounded-md text-sm md:text-base lg:text-xl font-medium"
                  >
                    {userType?.is_superuser &&
                      userType?.is_staff &&
                      userType?.is_active && (
                        <span className="flex items-center gap-1">
                          <BsSpeedometer2 />
                          <span>Dashboard</span>
                        </span>
                      )}
                  </Link>
                  <Link
                    to="https://visiontrek.in/about-us/"
                    className="text-gray-700  hover:text-blue-600  px-2 lg:px-3 mt-6 rounded-md text-sm md:text-base lg:text-xl font-medium"
                  >
                    <span className="flex items-center gap-1 ">
                      <BsPatchExclamation className="" />
                      <span>About</span>
                    </span>
                  </Link>
                </div>
              </>
            ) : (
              <div className=" hidden    md:flex pt-4 ">
                <Link
                  href="#"
                  className=" text-gray-700  hover:text-blue-600  px-4 py-2 rounded-md text-sm md:text-xl font-medium "
                >
                  <span className="flex items-center">
                    <PiCurrencyCircleDollar className="h-6 w-6" />
                    <span>Pricing</span>
                  </span>
                </Link>

                <Link
                  to="https://visiontrek.in/about-us/"
                  className=" text-gray-700    hover:text-blue-600   px-4 py-2 rounded-md text-sm md:text-xl font-medium"
                >
                  <span className="flex items-center gap-1">
                    <BsPatchExclamation />
                    <span>About</span>
                  </span>
                </Link>
                <Link
                  to="https://visiontrek.in/about-us/"
                  className=" text-gray-700    hover:text-blue-600   px-4 py-2 rounded-md text-sm md:text-xl font-medium"
                >
                  <span className="flex items-center gap-1">
                    <FiPhoneCall />
                    <span>Contact</span>
                  </span>
                </Link>
              </div>
            )}

            {/* mobile view  */}
            {access_token ? (
              <>
                <div className="relative hidden md:flex ">
                  <Link
                    className="inline-flex items-center justify-center mt-3  text-sm font-medium text-gray-700 bg-white focus:outline-none hover:text-blue-600  px-3 py-2 md:text-xl "
                    onClick={toggleUser}
                  >
                    {userType?.profile_img_url || userType?.profile_img ? (
                      <div>
                        <img
                          src={
                            userType?.profile_img_url || userType?.profile_img
                          }
                          className="w-8 h-8 rounded-full"
                          alt="profile pic"
                        />
                      </div>
                    ) : (
                      <FaUserCircle size={30} color="blue" />
                    )}

                    <svg
                      className={`w-5 h-5 ml-2 transition-transform ${
                        isUser ? "transform rotate-180" : ""
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6.293 7.293a1 1 0 0 1 1.414 0L10 9.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>

                  {isUser && (
                    <div
                      className="origin-top-right items-center absolute right-0 mt-[60px] w-56 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5"
                      onMouseLeave={() => setIsUser(false)}
                    >
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <span
                          className=" block px-4 py-2 text-sm text-gray-700 capitalize "
                          role="menuitem"
                        >
                          <p className="flex items-center justify-between">
                            <p className="hover:text-blue-500">
                              {userData.first_name + " " + userData.last_name}
                            </p>
                            <abbr title="Edit Profile">
                              <Link to={"/edit-profile"}>
                                <FiEdit className="w-4 h-4 hover:text-blue-500 cursor-pointer" />
                              </Link>
                            </abbr>
                          </p>
                        </span>
                        {/* <Link
                          to="/card"
                          className="block px-4 py-2 text-sm text-gray-700  hover:text-blue-600"
                          role="menuitem"
                        >
                          My Card
                        </Link> */}
                        <Link
                          to="/reset-password"
                          className="block px-4 py-2 text-sm text-gray-700  hover:text-blue-600"
                          role="menuitem"
                        >
                          Change Password
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          {access_token ? (
            <button
              className=" text-white   bg-blue-500 h-10 mt-5 px-3 hover:bg-blue-600 hidden md:block shadow-lg rounded text-md font-medium"
              onClick={logout}
            >
              <span className="flex items-center gap-1">
                <BsPower className="w-5 h-5 " /> Logout
              </span>
            </button>
          ) : (
            <button className=" text-white bg-blue-500 h-10 mt-5 px-3 hover:bg-blue-600 hidden md:block shadow-lg rounded text-md font-medium">
              {" "}
              <Link to="/login">Sign In</Link>
            </button>
          )}

          <ToastContainer />

          <div className="-mr-2 flex md:hidden mt-5">
            <div
              onClick={() => setMenu(!menu)}
              className=" md:hidden cursor-pointer mx-0"
            >
              <AiOutlineMenu size={30} color="blue" />
            </div>

            {menu ? (
              <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"></div>
            ) : (
              ""
            )}

            <div
              className={
                menu
                  ? "fixed top-0 left-0 w-[300px] h-screen z-10 duration-300 bg-white"
                  : "fixed top-0 left-[-100%] w-[300px] h-screen z-10 duration-300 bg-white"
              }
            >
              <AiOutlineClose
                color="blue"
                onClick={() => setMenu(!menu)}
                size={30}
                className="absolute right-4 top-4  cursor-pointer"
              />

              {access_token ? (
                <nav className="flex flex-col px-4 ">
                  <ul>
                    <li className="text-[#0E1127] md:text-lg mt-10 capitalize font-bold hover:text-blue-600 mx-4 py-2 flex">
                      <Link className="inline-flex" to={"/edit-profile"}>
                        <FaUserCircle size={25} className="mr-5" />{" "}
                        {userData.first_name + " " + userData.last_name}
                      </Link>
                    </li>
                    {/* <li className='text-[#0E1127] md:text-lg mt-10 capitalize font-bold hover:text-blue-600 mx-4 py-2 flex'><FaUserCircle size={25} className="mr-5" /> {user} 
                  </li> */}

                    <li className="text-[#0E1127] md:text-lg mt-10 font-bold hover:text-blue-600 mx-4 py-2 flex">
                      <AiFillInfoCircle size={25} className="mr-5" />
                      <Link to="/user-details">New Card</Link>
                    </li>
                    {/* <li
                      className="text-[#0E1127] md:text-lg mt-10 font-bold hover:text-blue-600 mx-4 py-2 flex"
                    >
                      <AiFillIdcard size={25} className="mr-5" />
                      <Link to="/card"> My Card</Link>
                    </li> */}
                    {userType?.is_superuser &&
                      userType?.is_staff &&
                      userType?.is_active && (
                        <>
                          <li className="text-[#0E1127] md:text-lg mt-10 font-bold hover:text-blue-600 mx-4 py-2 flex">
                            <Link to={"/dashboard"} className="flex">
                              <AiFillDashboard size={25} className="mr-5" />
                              <span>Dashboard</span>
                            </Link>
                          </li>
                        </>
                      )}

                    <li className="text-[#0E1127] md:text-lg mt-10 font-bold hover:text-blue-600 mx-4 py-2 flex">
                      <BsFillKeyFill size={25} className="mr-5" />
                      <Link to="/reset-password">Change Password</Link>
                    </li>

                    <li
                      className="text-[#0E1127] md:text-lg mt-10 font-bold hover:text-blue-600 mx-4 py-2 flex hover:cursor-pointer"
                      onClick={logout}
                    >
                      <RiLogoutCircleRFill size={25} className="mr-5" />
                      Log out
                    </li>
                  </ul>
                </nav>
              ) : (
                <nav className="flex flex-col px-4 ">
                  <ul>
                    <li className="text-[#0E1127] md:text-lg mt-10 hover:text-blue-600 font-bold mx-4 py-2 flex">
                      <ImPriceTag size={25} className="mr-5" />
                      Pricing
                    </li>
                    <li className="text-[#0E1127] md:text-lg mt-10 font-bold hover:text-blue-600 mx-4 py-2 flex">
                      <AiFillInfoCircle size={25} className="mr-5" />
                      <Link
                        to="https://visiontrek.in/about-us/"
                        target="_blank"
                      >
                        About
                      </Link>
                    </li>
                    <li className="text-[#0E1127] md:text-lg mt-10 font-bold hover:text-blue-600 mx-4 py-2 flex">
                      <RiLogoutCircleRFill size={25} className="mr-5" />
                      <Link to="/login">Sign In</Link>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
