import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import { FaLocationArrow } from "react-icons/fa";
import Nav from "../Nav";
import { GetToken } from "../../services/localstorage";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiFillTwitterCircle, AiFillPlusCircle } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { TbRotateClockwise } from "react-icons/tb";
import { ImCross } from "react-icons/im";
import Modal from "react-modal";
import "./Modal.css";
import {
  BsFacebook,
  BsTwitter,
  BsInstagram,
  BsLinkedin,
  BsTelegram,
} from "react-icons/bs";
import AvatarEditor from "react-avatar-editor";
Modal.setAppElement("#root");

const PersonallDetails = () => {
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [markerPosition, setMarkerPosition] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [address, setAddress] = useState("");
  const textareaRef = useRef(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY,
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleMapOpen = () => {
    setIsOpen(true);
  };

  const handleMapClose = () => {
    setIsOpen(false);
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log({ social_media: { ...socialMediaData } });
  // };

  const [socialMediaData, setSocialMediaData] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    telegram: "",
    others: "",
  });

  const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);
  const [openInputTabs, setOpenInputTabs] = useState({});

  const handleSocialMediaClick = (socialMediaKey) => {
    if (!openInputTabs[socialMediaKey]) {
      setSelectedSocialMedia((prevSelected) => [
        ...prevSelected,
        socialMediaKey,
      ]);
      // Set the input tab as open
      setOpenInputTabs((prevOpenInputTabs) => ({
        ...prevOpenInputTabs,
        [socialMediaKey]: true,
      }));
    }
  };

  const [formErrors, setFormErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    // Add more fields here
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSocialMediaData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate the input and update formErrors
    const errors = { ...formErrors };

    if (name === "first_name") {
      if (!value) {
        errors.first_name = "*First name is required";
      } else {
        errors.first_name = "";
      }
    }
    if (name === "last_name") {
      if (!value) {
        errors.last_name = "*Last name is required";
      } else {
        errors.last_name = "";
      }
    }
    if (name === "email") {
      if (!value) {
        errors.email = "*Email is required";
      } else {
        errors.email = "";
      }
    }
    if (name === "mobile") {
      if (!value) {
        errors.mobile = "*Mobile number is required";
      } else {
        errors.mobile = "";
      }
    }
    if (name === "profession") {
      if (!value) {
        errors.profession = "*Profession is required";
      } else {
        errors.profession = "";
      }
    }
    if (name === "company_name") {
      if (!value) {
        errors.company_name = "*Please fill Your Company name";
      } else {
        errors.company_name = "";
      }
    }
    if (name === "business_title") {
      if (!value) {
        errors.business_title = "*Please fill the category";
      } else {
        errors.business_title = "";
      }
    }
    if (name === "website_link") {
      if (!value) {
        errors.website_link = "*Please provide website link (http:// or https://)";
      } else {
        errors.website_link = "";
      }
    }

    // Add similar validation for other fields

    setFormErrors(errors);
  };

  const handleRemoveClick = (socialMediaKey) => {
    setSelectedSocialMedia((prevSelected) =>
      prevSelected.filter((key) => key !== socialMediaKey)
    );

    setOpenInputTabs((prevOpenInputTabs) => ({
      ...prevOpenInputTabs,
      [socialMediaKey]: false,
    }));

    setSocialMediaData((prevData) => ({
      ...prevData,
      [socialMediaKey]: "",
    }));
  };

  const [servererror, seterror] = useState({});
  const { access_token } = GetToken();

  const [detail, setDetail] = useState([]);

  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const [isloading, setloading] = useState(false);

  // Function to handle map load
  const handleMapLoad = (map) => {
    setMap(map);
  };

  // Function to handle search box places change
  const handlePlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (places?.length > 0) {
        const { geometry, formatted_address } = places[0];
        if (geometry && geometry.location) {
          const position = {
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
          };
          setMarker(position);
          setAddress(formatted_address);
        }
      }
    }
  };

  const setMarker = (position) => {
    setMarkerPosition(position);
    if (map) {
      map.panTo(position);
    }
  };

  // Function to get current location
  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          const address = await reverseGeocode(latitude, longitude);

          const currentPosition = { lat: latitude, lng: longitude, address };
          setCurrentLocation(currentPosition);
          setMarker(currentPosition);

          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: currentPosition }, (results, status) => {
            if (status === "OK" && results[0]) {
              setAddress(results[0].formatted_address);
            }
          });
        },

        (error) => {
          console.error(error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GMAPS_API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      const results = data.results;

      const address = results[0].formatted_address;
      return address;
    } catch (error) {
      console.error("Error reverse geocoding:", error);
      return "";
    }
  };

  const mapOptions = {
    disableDefaultUI: true, // Hide default controls
  };

  const base_url = process.env.REACT_APP_PUBLIC_URL;
  // const base_url ="http://127.0.0.1:8000/"

  const [invalidImage, setInvalidImage] = useState(null);
  const [userInfo, setUserInfo] = useState({
    file: [],
    filepreview: null,
  });

  const [scale, setScale] = useState(1);
  const [editor, setEditor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProfileChange = (event) => {
    const imageFile = event.target.files[0];
    const imageFilename = event.target.files.name;

    if (!imageFile) {
      toast.warning("Please select an image first.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif|jfif|webp)$/)) {
      setInvalidImage("Please select a valid image (JPG, JPEG, PNG).");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      setUserInfo({
        ...userInfo,
        filepreview: e.target.result,
      });

      setInvalidImage(null);
    };

    reader.readAsDataURL(imageFile);
  };
  const [rotate, setRotate] = useState(0); // Initialize rotation angle

  // Function to handle rotation
  const handleRotate = () => {
    // Increment the rotation angle by 90 degrees (you can adjust this value)
    setRotate((prevRotate) => prevRotate + 90);
  };

  const handleCrop = () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      canvas.toBlob(
        (blob) => {
          const file = new File([blob], "profile.jpg", {
            type: "image/jpeg",
            lastModified: Date.now(),
          });
          setUserInfo({
            ...userInfo,
            file: file,
          });
        },
        "image/jpeg",
        1
      );
    }
    setIsModalOpen(false); // Close the modal after cropping
  };

  const url = base_url + "personal-detail/";

  const handlePersonalSubmit = async (e) => {
    e.preventDefault();

    setloading(true);
    setDetail({ person: false });
    const data = new FormData(e.currentTarget);
    console.log(data);

    // this will check the website link have http wali condition or not if not this will apply
    const websiteLink = data.get("website_link");
    const isValidWebsiteLink =
      websiteLink.startsWith("http://") || websiteLink.startsWith("https://");
    const correctedWebsiteLink = isValidWebsiteLink
      ? websiteLink
      : `https://${websiteLink}`;

    const actualdata = {
      first_name: data.get("first_name"),
      last_name: data.get("last_name"),
      email: data.get("email"),
      mobile_number: data.get("mobile"),
      profession: data.get("profession"),
      profile_img: userInfo.file,
      business_name: data.get("company_name"),
      business_category: data.get("business_title"),
      about_us: data.get("about_us"),

      facebook: socialMediaData.facebook,
      twitter: socialMediaData.twitter,
      instagram: socialMediaData.instagram,
      linkedin: socialMediaData.linkedin,
      telegram: socialMediaData.telegram,
      others: socialMediaData.others,
      // Add more social media platforms as needed

      address: data.get("address"),
      website_link: correctedWebsiteLink,
    };

    console.log("actualdata", actualdata);

    const config = {
      headers: {
        authorization: `Bearer ${access_token}`,
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(url, actualdata, config)
      .then((response) => {
        setloading(false);

        localStorage.setItem("created_id", response.data.id);
        setDetail({ company: true });
        navigate("/product/" + response.data.card_link);
      })
      .catch((error) => {
        console.log(error.response.data);
        seterror(error.response.data);
        setDetail({ person: true });
        setloading(false);
      });
  };

  return (
    <>
      <Nav />

      <a
        href
        class="block max-w-sm container mx-auto mt-5 h-auto  mb-5  md:max-w-[700px]  p-6 bg-white border border-gray-200 rounded-lg shadow "
      >
        <form onSubmit={(e) => handlePersonalSubmit(e)}>
          <span class=" text-blue-700 font-medium  text-md">
            Personal Details
          </span>

          <div class="grid md:grid-cols-2 mt-3 md:gap-6">
            <div class="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="first_name"
                id="floating_first_name"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => handleInputChange(e)}
                defaultValue={userData.first_name}
              />
              {formErrors.first_name && (
                <div className="text-red-500 text-xs">
                  {formErrors.first_name}
                </div>
              )}

              <label
                for="floating_first_name"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                First name
              </label>
            </div>

            <div class="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="last_name"
                id="floating_last_name"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => handleInputChange(e)}
                defaultValue={userData.last_name}
              />
              {formErrors.last_name && (
                <div className="text-red-500 text-xs">
                  {formErrors.last_name}
                </div>
              )}

              <label
                for="floating_last_name"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Last name
              </label>
            </div>
          </div>

          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-6 group">
              <input
                type="email"
                name="email"
                id="floating_email"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => handleInputChange(e)}
                defaultValue={userData.email}
              />
              {formErrors.email && (
                <div className="text-red-500 text-xs">{formErrors.email}</div>
              )}

              <label
                for="floating_email"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Email address
              </label>
            </div>
            <div class="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="mobile"
                id="floating_phone"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                pattern=".{10,}"
                onChange={(e) => handleInputChange(e)}
                defaultValue={userData.mobile_number}
              />
              {formErrors.mobile && (
                <div className="text-red-500 text-xs">{formErrors.mobile}</div>
              )}
              <label
                for="floating_phone"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Mobile Number{" "}
              </label>
            </div>
          </div>

          <div class="grid md:grid-cols-2  md:gap-6">
            <div class="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="profession"
                id="floating_first_name"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => handleInputChange(e)}
              />
              {formErrors.profession && (
                <div className="text-red-500 text-xs">
                  {formErrors.profession}
                </div>
              )}
              <label
                for="floating_first_name"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Profession
              </label>
            </div>
            {/* IMAGE UPLOAD FUNCTIONALLITY  */}
            <div>
              <div className="relative z-0 w-full group">
                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75  origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 md:text-lg">
                  Upload Profile Image
                </label>

                <input
                  name="profile"
                  className="block w-full mt-5 px-2 pt-1 pb-1 text-xs text-gray-900 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                  id="small_size"
                  type="file"
                  required
                  accept="image/png, image/jpeg"
                  onChange={handleProfileChange}
                  onClick={() => setIsModalOpen(true)}
                />
              </div>

              {invalidImage && (
                <div className="text-red-500">{invalidImage}</div>
              )}

              <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Image Editor"
                className="fixed inset-0 flex items-center justify-center"
              >
                <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full mx-2">
                  <AvatarEditor
                    ref={(editor) => setEditor(editor)}
                    image={userInfo.filepreview}
                    width={250}
                    height={250}
                    border={50}
                    borderRadius={1000}
                    color={[1, 1, 1, 0.6]}
                    scale={scale}
                    rotate={rotate}
                    className="mx-auto"
                  />

                  <div className="mt-4">
                    <input
                      type="range"
                      min="1"
                      max="2"
                      step="0.01"
                      value={scale}
                      onChange={(e) => setScale(parseFloat(e.target.value))}
                      className="w-full"
                    />
                  </div>

                  <div className="mt-4 flex gap-3 justify-center">
                    <BsFillCheckCircleFill
                      color="green"
                      onClick={handleCrop}
                      size={27}
                    />
                    <TbRotateClockwise
                      color="white"
                      size={27}
                      onClick={handleRotate}
                      className="bg-gray-500 p-0.5 rounded-full"
                    />

                    <RxCrossCircled
                      color="white"
                      size={27}
                      className="bg-red-600 p-0.5 rounded-full"
                      onClick={() => setIsModalOpen(false)}
                    />
                  </div>
                </div>
              </Modal>
            </div>
          </div>

          <span class=" text-blue-700 font-medium text-md  mt-4">
            Business Details
          </span>
          <div class="grid  mt-3 md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="company_name"
                id="floating_first_name"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => handleInputChange(e)}
              />
              {formErrors.company_name && (
                <div className="text-red-500 text-xs">
                  {formErrors.company_name}
                </div>
              )}
              <label
                for="floating_first_name"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Business Name{" "}
              </label>
            </div>

            <div class="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="business_title"
                id="floating_last_name"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e) => handleInputChange(e)}
              />
              {formErrors.business_title && (
                <div className="text-red-500 text-xs">
                  {formErrors.business_title}
                </div>
              )}
              <label
                for="floating_last_name"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Business Title/Category
              </label>
            </div>
          </div>

          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="website_link"
                id="floating_first_name"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=""
                required
                onChange={(e) => handleInputChange(e)}
              />
              {formErrors.website_link && (
                <div className="text-red-500 text-xs">
                  {formErrors.website_link}
                </div>
              )}
              <label
                for="floating_first_name"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Website Link {"(without 'https://')"}
              </label>
            </div>
          </div>

          <div class="flex">
            <span class=" text-gray-500 text-sm"> Business Location </span>
            <button
              class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-3    py-1 text-center  mx-3 dark:bg-blue-600 dark:hover:bg-blue-700 "
              onClick={handleMapOpen}
            >
              Choose from map
            </button>
          </div>

          <div class="w-full container h-auto rounded-lg ">
            <input
              type="text"
              name="address"
              defaultValue={address}
              id="floating_last_name"
              class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            />
            {formErrors.address && (
              <div className="text-red-500 text-xs">{formErrors.address}</div>
            )}
          </div>

          <label
            for="message"
            class="block mb-2 text-md font-medium text-blue-700  mt-3 dark:text-gray-500"
          >
            About Your Business
          </label>
          <textarea
            id="message"
            rows="4"
            name="about_us"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none shadow-md"
            placeholder="Write your thoughts here..."
          ></textarea>

          <p class=" font-medium text-sm mt-5 text-blue-700">Social Accounts</p>
          <div className="bg-white border border-none shadow-md rounded-md p-2">
            <div class=" grid gap-0 grid-cols-4  md:grid-cols-6 ">
              <div
                class="relative mx-auto p-3 shadow-md  mt-3 border-none   border rounded-full z-0  cursor-pointer mb-6 group"
                onClick={() => handleSocialMediaClick("facebook")}
              >
                <BsFacebook color="#1674EA" size={30} />
              </div>
              <div
                class="relative mx-auto p-3 shadow-md border-none mt-3 rounded-full border  z-0  cursor-pointer  mb-6 group"
                onClick={() => handleSocialMediaClick("twitter")}
              >
                <AiFillTwitterCircle color="#20C6EA" size={30} />
              </div>
              <div
                class="relative mx-auto p-3 shadow-md border-none mt-3.5 rounded-full border  z-0  cursor-pointer mb-6 group"
                onClick={() => handleSocialMediaClick("instagram")}
              >
                {/* <img src={insta} class=" mt-2  rounded-full h-[30px]" alt=""/> */}
                <BsInstagram color="#CC307B" size={25} />
              </div>

              <div
                class="relative mx-auto p-3 shadow-md border-none mt-3 rounded-full border  z-0 cursor-pointer mb-6 group"
                onClick={() => handleSocialMediaClick("linkedin")}
              >
                <BsLinkedin color="#0073AF" size={25} />
              </div>
              <div
                class="relative mx-auto p-3 shadow-md border-none mt-3 rounded-full border  z-0 cursor-pointer mb-6 group"
                onClick={() => handleSocialMediaClick("telegram")}
              >
                <BsTelegram color="#28A4E4" size={30} />
              </div>
              <div
                class="relative mx-auto p-3 shadow-md border-none mt-3 rounded-full border  z-0 cursor-pointer mb-6 group"
                onClick={() => handleSocialMediaClick("others")}
              >
                <AiFillPlusCircle color="gray" size={30} />
              </div>
            </div>
            <div class="flex-col ">
              {selectedSocialMedia.map((socialMediaKey) => (
                <div
                  class=" mx-5 mb-5 lg:w-2/5 flex gap-3"
                  key={socialMediaKey}
                >
                  {socialMediaKey === "facebook" && (
                    <label
                      class=" mb-2 block text-md font-medium text-blue-700 mx-2 mt-3 dark:text-gray-500"
                      htmlFor={socialMediaKey}
                    >
                      <BsFacebook color="#1674EA" size={20} />
                    </label>
                  )}

                  {socialMediaKey === "instagram" && (
                    <label
                      class=" mb-2 block text-md font-medium text-blue-700  mt-3 mx-2 dark:text-gray-500"
                      htmlFor={socialMediaKey}
                    >
                      <BsInstagram color="#CC307B" size={20} />
                    </label>
                  )}

                  {socialMediaKey === "twitter" && (
                    <label
                      class=" mb-2 block text-md font-medium text-blue-700  mt-3 mx-2 dark:text-gray-500"
                      htmlFor={socialMediaKey}
                    >
                      <BsTwitter color="#20C6EA" size={20} />
                    </label>
                  )}

                  {socialMediaKey === "linkedin" && (
                    <label
                      class=" mb-2 block text-md font-medium text-blue-700  mt-3 mx-2 dark:text-gray-500"
                      htmlFor={socialMediaKey}
                    >
                      <BsLinkedin color="#0073AF" size={20} />
                    </label>
                  )}

                  {socialMediaKey === "others" && (
                    <label
                      class=" mb-2 block text-md font-medium text-blue-700  mt-3 mx-2 dark:text-gray-500"
                      htmlFor={socialMediaKey}
                    >
                      <AiFillPlusCircle color="gray" size={20} />
                    </label>
                  )}

                  {socialMediaKey === "telegram" && (
                    <label
                      class=" mb-2 block text-md font-medium text-blue-700  mt-3 mx-2 dark:text-gray-500"
                      htmlFor={socialMediaKey}
                    >
                      <BsTelegram color="#28A4E4" size={20} />
                    </label>
                  )}

                  <input
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    type="text"
                    name={socialMediaKey}
                    value={socialMediaData[socialMediaKey]} // Bind input value to corresponding state
                    onChange={handleInputChange}
                    placeholder={`Your ${socialMediaKey} Username`}
                  />

                  {selectedSocialMedia.includes(socialMediaKey) && (
                    <ImCross
                      type="button"
                      onClick={() => handleRemoveClick(socialMediaKey)}
                      color="red"
                      size={18}
                      className="mt-3 cursor-pointer"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* <input
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            type="text"
            name="facebook" // Use the name of the social media platform
            value={socialMediaData.facebook} // Bind input value to the state
            onChange={handleInputChange} // Call the handleInputChange function on input change
            placeholder="Your Facebook Username"
          />

          <input
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            type="text"
            name="twitter" // Use the name of the social media platform
            value={socialMediaData.twitter} // Bind input value to the state
            onChange={handleInputChange} // Call the handleInputChange function on input change
            placeholder="Your Twitter Username"
          />

          <input
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            type="text"
            name="instagram" // Use the name of the social media platform
            value={socialMediaData.instagram} // Bind input value to the state
            onChange={handleInputChange} // Call the handleInputChange function on input change
            placeholder="Your Instagram Username"
          /> */}

          <div class="my-3">
            <button
              type="submit"
              class="text-white mt-0 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Next
            </button>
          </div>
        </form>
      </a>

      <Modal
        isOpen={isOpen}
        onRequestClose={handleMapClose}
        className="modal top-60"
        overlayclassName="overlay"
      >
        {isLoaded ? (
          <>
            <div style={{ height: "400%", width: "100%" }}>
              <StandaloneSearchBox
                onLoad={(ref) => {
                  setSearchBox(ref);
                  //   searchBoxRef.current = ref?.input;
                }}
                onPlacesChanged={handlePlacesChanged}
              >
                <Autocomplete>
                  <input
                    class="block text-center  py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer mb-5"
                    type="text"
                    placeholder="Search for a location"
                  />
                </Autocomplete>
              </StandaloneSearchBox>

              <GoogleMap
                onLoad={handleMapLoad}
                center={currentLocation}
                zoom={15}
                options={mapOptions}
                mapContainerStyle={{ width: "100%", height: "150px" }}
              >
                {markerPosition && <Marker position={markerPosition} />}
              </GoogleMap>

              <div
                onClick={getCurrentLocation}
                class="flex justify-center text-blue-700 gap-1 mt-3 mb-3"
              >
                <span class="mt-1 text-md hover:cursor-pointer">
                  Current Location{" "}
                </span>

                <div class="w-5 h-5 mt-2 mx-1 rounded-full bg-blue-700 ">
                  <FaLocationArrow
                    color="white"
                    data-tooltip-id="my-tooltip"
                    size={12}
                    data-tooltip-content="Current Location"
                    class=" text-lg mx-auto mt-1 mb-1 text-blue-700"
                  ></FaLocationArrow>
                </div>

                <Tooltip id="my-tooltip" class="rounded-xl " color="blue" />
              </div>

              <textarea
                id="message"
                ref={textareaRef}
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                rows="3"
                class="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 "
                placeholder="Your Address..."
              ></textarea>

              <button
                onClick={handleMapClose}
                type="submit"
                class="text-white mt-4 mx-auto bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Save
              </button>
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </Modal>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default PersonallDetails;
